/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/TopCompanyEntitySlice';
import { actions as companiesAction } from 'Slice/CompaniesSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import { TopCompanyEntityRequest, TopEntitiesRequest } from 'Types/RequestBodies';
import { TopEntitiesResponse } from 'Types/TopEntitiesTypes';
import { getCurrentTime } from 'Utils/UtilityFunctions';
import _ from 'lodash';

export function* getTopEntities(dispatched: PayloadAction<TopEntitiesRequest>) {
  try {
    const payload = dispatched?.payload;
    const response: TopEntitiesResponse = yield call(
      axios.post,
      API.GET_TOP_ENTITIES,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setTopEntities(response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getTopCompanyEntities(dispatched: PayloadAction<TopCompanyEntityRequest>) {
  const startTime = getCurrentTime();
  try {
    const payload = dispatched?.payload;
    const response: TopEntitiesResponse = yield call(
      axios.post,
      API.COMPANY_TOP_ENTITIES,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setTopCompanyEntities(response));
      yield put(companiesAction.setCompetitorsFlurry({
        ...dispatched?.payload,
        company_name: response.response?.company?.companyName,
        loading_time: _.round((getCurrentTime() - startTime), 2),
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* topEntitiesSaga() {
  yield takeLatest(actions.getTopEntities.type, getTopEntities);
  yield takeLatest(actions.getTopCompanyEntities.type, getTopCompanyEntities);
}

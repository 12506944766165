import { Col, Modal, Row } from 'antd';
import classNames from 'classnames';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  backgroundColorParsing, getLangCookie, getLocalStorageValue, valueToObject,
} from 'Utils/UtilityFunctions';
import {
  extendedSearchLoader, maxMobile, primaryColor, primaryHeadingColor, secondaryFont, wMedium,
} from 'Utils/utilsStyle';
import {
  selectConfirming,
  selectConfirmSuccess,
  selectLinkedinPeopleLoading,
  selectLinkedinSearchEnded,
  selectMappedId,
  selectPage, selectPeopleLoading, selectPopupVisible, selectSearchedPeople,
  selectSearchEnded,
} from 'Selector/ProfileConfirmationSelector';
import { useNavigate } from 'react-router-dom';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { FEED_ROUTE } from 'Utils/Constants';
import NoDataFound from '../components/NoDataFound';
import PeopleCardLarge from '../components/PeopleCardLarge';
import SearchPersonSkeletonBlock from '../skeleton/SearchPersonSkeletonBlock';
import { actions } from '../../../Slice/ProfileConfirmationSlice';
import CrossCircle from '../assets/svgIcons/CrossCircle';

const Footer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
padding: 0 20px;
@media all and (max-width: ${maxMobile}) {
  flex-direction: column;
}
`;

const SearchWrapper = styled.div`
 width: 100%;
 margin-bottom: 0px;
 display: flex;
 flex-direction: column;
 .ant-input-affix-wrapper {
  height: 35px;
  margin-bottom: 15px;
  .ant-input-prefix {
    .anticon-search {
      font-size: 17px;
    }
  }
 }
 .search-wrapper {
  .ant-input-affix-wrapper {
    &.active {
      border-color: ${primaryColor};
    }
  }
   svg {  
     top: 7px;
     right: 8px;
     cursor: pointer;
    }
  }
`;
const LoaderWrapper = styled.div`
  .main{
    background:white;
    border:1px solid ${primaryColor};
    padding: 60px 30px;
    text-align: center;
    border-radius:16px;
    margin:0 18px;
    .loader-style{
      height:90px;
      width:90px;
    }
    .text{
      font-size:20px;
      color:${primaryHeadingColor};
      font-weight: ${wMedium};
      font-family: ${secondaryFont};
      letter-spacing: -0.45px;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 5px;
      width:160px;
    }
  }
`;

const ProfileConfirmationPopup = function ProfileConfirmationPopup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [disable, setDisable] = useState<boolean>(true);
  const isVisible = useSelector(selectPopupVisible);
  const peopleLoading = useSelector(selectPeopleLoading);
  const linkedinPeopleLoading = useSelector(selectLinkedinPeopleLoading);
  const searchedPeople = useSelector(selectSearchedPeople);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchPage = useSelector(selectPage);
  const searchEnded = useSelector(selectSearchEnded);
  const linkedinSearchEnded = useSelector(selectLinkedinSearchEnded);
  const confirmSuccess = useSelector(selectConfirmSuccess);
  const confirming = useSelector(selectConfirming);
  const mappedId = useSelector(selectMappedId);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const personCompany = getLocalStorageValue('personCompany');
  const personName = getLocalStorageValue('fullname');
  const isLoading = peopleLoading;
  const linkedinLoading = linkedinPeopleLoading;

  const hasNoResults = !searchedPeople.length;

  const refineQuery = (line: string) => line.trim().replace(/\s+/g, ' ');

  const formatQuery = (line: string) => {
    let query = line;
    if (!query.startsWith('person:')) {
      query = `person: ${query}`;
    }
    return query;
  };

  const fetchMore = () => {
    dispatch(actions.getSearchPeople({
      query: formatQuery(refineQuery(searchQuery)),
      page: searchPage,
      screenName: 'profileConfirmation',
      defaultLanguage: getLangCookie(),
    }));

    dispatch(flurryActions.callFlurryEvent(
      valueToObject('profile_mapping_search', 0, { searchQuery, page: searchPage }),
    ));
  };

  const onSearching = (searchTerm: string) => {
    if (searchTerm.trim().length) {
      scrollDivRef.current?.scrollTo({ top: 0 });
      setSearchQuery(refineQuery(searchTerm));
      dispatch(actions.getSearchPeople({
        query: formatQuery(refineQuery(searchTerm)),
        page: 1,
        screenName: 'profileConfirmation',
        defaultLanguage: getLangCookie(),
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('profile_mapping_search', 0, { searchTerm, page: 1 }),
      ));
      setActiveIndex(-1);
    }
  };

  useEffect(() => {
    if (isVisible && !peopleLoading && !searchedPeople.length) {
      let query = personName?.length ? personName : '';
      query = query.length && personCompany?.length ? `${query}, ${personCompany}` : `${query}`;
      setSearchQuery(refineQuery(query));
      dispatch(actions.getSearchPeople({
        query: formatQuery(refineQuery(query)),
        screenName: 'profileConfirmation',
        page: 1,
        defaultLanguage: getLangCookie(),
      }));
    }
  }, [isVisible]);

  const hidePopup = () => {
    setActiveIndex(-1);
    setSearchQuery('');
    dispatch(actions.setPopupVisible(false));
    dispatch(actions.resetSearchResults());
  };

  const handleClick = (index: number, id: number) => {
    setActiveIndex(index);
    if (id === mappedId) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const confirmAction = () => {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('profile_mapping_complete', 0, { id: searchedPeople[activeIndex] }),
    ));
    dispatch(actions.setConfirmedPerson(searchedPeople[activeIndex]));
  };

  useEffect(() => {
    if (confirmSuccess) {
      dispatch(actions.resetSearchResults());
      hidePopup();
      navigate(FEED_ROUTE);
    }
  }, [confirmSuccess]);

  useEffect(() => {
    const index = searchedPeople.findIndex((person) => person.personId === mappedId);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [searchedPeople]);

  return (
    <Modal
      open={isVisible}
      centered
      footer={null}
      width={910}
      closable
      closeIcon={<div className="cross-icon-x"><CrossCircle /></div>}
      onCancel={hidePopup}
      wrapClassName="profile-comparison-popup"
      getContainer=""
      maskStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', backdropFilter: 'blur(10px)' }}
    >
      <span className="popup-heading">Please confirm your profile?</span>
      <SearchWrapper>
        <AlertsSearch
          onSearching={onSearching}
          placeholderText="Search your profile..."
          margin="0px"
          query={searchQuery}
          customClass={classNames({ active: activeIndex > -1 })}
        />
      </SearchWrapper>

      <div id="person-comparison-row" ref={scrollDivRef} className="w-100 h-100">
        <InfiniteScroll
          hasMore={!searchEnded || !linkedinSearchEnded}
          dataLength={searchedPeople.length}
          next={fetchMore}
          className="o-x-hidden infinite-scroll-div mr-1 person-comparison-row"
          loader={false}
          height="auto"
        >
          <Row gutter={[16, 16]} className="comparison-grid">
            {searchedPeople.length ? searchedPeople.map((person, key) => (
              <Col md={{ span: 6 }} className="person-comparison-col">
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames('card', { activeCard: key === activeIndex })}
                  onClick={() => handleClick(key, person.personId)}
                  onKeyPress={() => handleClick(key, person.personId)}
                >
                  <PeopleCardLarge
                    diameter="130px"
                    diameterXxsm="125px"
                    imageDiameter="130px"
                    holoDiameterWidth="125px"
                    holoDiameterHeight="115px"
                    holoDiameterXxsmHeight="110px"
                    holoDiameterXxsmWidth="120px"
                    key={person?.personId}
                    persona={person?.archeType?.color}
                    logo={person?.personImage}
                    personName={person?.personName}
                    tag={person?.personDesignation}
                    personaType={person?.archeType?.name}
                    wrapperColor={backgroundColorParsing(person?.personalityTypes)}
                    personalityTypesColors={person?.personalityTypes}
                    hidePersonality={false}
                    noFollow
                    personId={person?.personId}
                    company={person?.companyName}
                    disableNavigation
                    profileConfirmation
                  />
                </div>
              </Col>
            )) : null}
            {isLoading && (
              <div className="person-comparison-skeleton-col profile-confirmation">
                <SearchPersonSkeletonBlock />
              </div>
            )}
            {linkedinLoading && (
              <Col md={{ span: 6 }} className="person-comparison-col outer-search">
                <LoaderWrapper>
                  <div className="main">
                    <img className="loader-style" src={extendedSearchLoader} alt="extended-loader" />
                    <p className="text">
                      {
                        'Uncovering more People using xiQ\'s extended Search.'
                      }

                    </p>
                  </div>
                </LoaderWrapper>
              </Col>
            )}
            {!isLoading && !linkedinLoading && hasNoResults ? <NoDataFound title="No Record Found" /> : null}
          </Row>
        </InfiniteScroll>
      </div>

      <Footer>
        <span
          tabIndex={0}
          role="button"
          className={classNames('comparison-primary-button', { disabled: activeIndex < 0 || confirming || disable })}
          onClick={confirmAction}
          onKeyPress={confirmAction}
        >
          Confirm, take me to The Feed
        </span>
        <span
          tabIndex={0}
          role="button"
          className="comparison-primary-button secondary-button"
          onClick={hidePopup}
          onKeyPress={hidePopup}
        >
          I’II do it later
        </span>
      </Footer>
    </Modal>
  );
};
export default ProfileConfirmationPopup;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Company, FollowCompanyResponse } from 'Types/CompaniesTypes';
import { AddToFavoriteRequest, FollowCompanyRequest } from 'Types/RequestBodies';
import { Industry } from 'Types/IndustryTypes';
/* eslint-disable @typescript-eslint/no-unused-vars */

import { DeleteGroupResult } from 'Types/GroupsTypes';
import { isSalesforce } from 'Utils/UtilityFunctions';
import { count } from 'console';
import {
  GlobalSearchResultsState,
  SearchResultsRequest,
  SearchResultSection,
  PersonLinkedinSearch,
  CompanyLinkedinSearch,
} from '../Types/GlobalSearchResultsTypes';

export const SectionTypes: Record<string, string> = {
  Executives: 'Executives',
  Companies: 'Companies',
  BuyerGroups: 'Buyer Groups',
  News: 'News',
  Industries: 'Industries',
  Documents: 'Documents',
};

export const initialState: GlobalSearchResultsState = {
  searchText: '',
  searchResultspage: 0,
  peopleSection: {
    title: 'Matching People',
    type: SectionTypes.Executives,
    count: 0,
    linkedInCount: 0,
    persons: [],
    followedPersons: [],
    firstCount: 0,
  },
  companiesSection: {
    title: 'Companies',
    type: SectionTypes.Companies,
    count: 0,
    linkedInCount: 0,
    companies: [],
    followedCompanies: [],
    firstCount: 0,
  },
  buyerGroupsSection: {
    title: 'BRM',
    type: SectionTypes.BuyerGroups,
    groups: [],
    count: 0,
    firstCount: 0,
  },
  articlesSection: {
    title: 'Articles',
    type: SectionTypes.News,
    count: 0,
    articles: [],
    firstCount: 0,
  },
  industriesSection: {
    title: 'Industries',
    type: SectionTypes.Industries,
    count: 0,
    industries: [],
    firstCount: 0,
  },
  documentSection: {
    title: 'Document Hub',
    type: SectionTypes.Documents,
    documents: [],
    count: 0,
    firstCount: 0,
  },
  isCompanySearchEnded: false,
  isGlobalSearchLoading: false,
  isGlobalSearchLoaded: false,
  errorText: '',
  isCompanyExtendedLoaded: false,
  isPersonExtendedLoaded: false,
  isCompanyExtendedLoading: false,
  isPersonExtendedLoading: false,
  isBuyerGroupExtendedLoading: false,
  isBuyerGroupExtendedLoaded: false,
  isPagination: false,
};

const useGlobalSearchResultsSlice = createSlice({
  name: 'globalSearchResults',
  initialState,
  reducers: {
    globalSearchResults(state, action: PayloadAction<SearchResultsRequest>) {
      const { payload } = action;
      state.searchText = payload.query;
      state.searchResultspage = payload.page || 1;
      state.isGlobalSearchLoading = true;
      state.isGlobalSearchLoaded = false;
      state.isPagination = true;
      state.isCompanyExtendedLoading = true;
      state.isBuyerGroupExtendedLoading = true;
      state.isPersonExtendedLoading = true;
      state.isCompanyExtendedLoaded = payload.page
        ? state.isCompanyExtendedLoaded
        : false;
      state.isBuyerGroupExtendedLoaded = payload.page
        ? state.isBuyerGroupExtendedLoaded
        : false;
      state.isPersonExtendedLoaded = payload.page
        ? state.isPersonExtendedLoaded
        : false;
      state.peopleSection.persons = payload.page
        ? state.peopleSection.persons
        : [];
      state.peopleSection.count = payload.page ? state.peopleSection.count : 0;
      state.documentSection.count = payload.page ? state.documentSection.count : 0;
      state.companiesSection.companies = payload.page
        ? state.companiesSection.companies
        : [];
      state.companiesSection.count = payload.page
        ? state.companiesSection.count
        : 0;
      state.buyerGroupsSection.groups = payload.page
        ? state.buyerGroupsSection.groups
        : [];
      state.buyerGroupsSection.count = payload.page
        ? state.buyerGroupsSection.count
        : 0;
      state.companiesSection.count = payload.page
        ? state.companiesSection.count
        : 0;
      state.articlesSection.articles = payload.page
        ? state.articlesSection.articles
        : [];
      state.articlesSection.count = payload.page
        ? state.articlesSection.count
        : 0;
      state.industriesSection.industries = payload.page
        ? state.industriesSection.industries
        : [];
      state.industriesSection.count = payload.page
        ? state.industriesSection.count
        : 0;
      state.documentSection.documents = payload.page
        ? state.documentSection.documents
        : [];
    },
    onboardingGlobalSearchResultsLoaded(
      state,
      action: PayloadAction<SearchResultSection[]>,
    ) {
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = true;
      state.isPagination = false;

      //
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Companies,
        )
        .forEach((section) => {
          state.companiesSection.title = section.title;

          state.companiesSection.companies = [...section.companies ?? []];

          if (section.count > 0 && state.searchResultspage === 1) {
            state.companiesSection.count += section.count;
            state.companiesSection.firstCount = state.companiesSection.companies
              ? state.companiesSection.companies.length : 0;
          }
        });

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Executives,
        )
        .forEach((section) => {
          state.peopleSection.title = section.title;

          state.peopleSection.persons = [...(section.persons ?? [])];

          if (section.count > 0 && state.searchResultspage === 1) {
            state.peopleSection.count += section.count;
            state.peopleSection.firstCount = state.peopleSection.persons
              ? state.peopleSection.persons.length : 0;
          }
        });
    },
    globalSearchResultsLoaded(
      state,
      action: PayloadAction<SearchResultSection[]>,
    ) {
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = true;
      state.isPagination = false;

      // append data from response

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.BuyerGroups,
        )
        .forEach((section) => {
          state.buyerGroupsSection.title = section.title;

          state.buyerGroupsSection.groups = [...state?.buyerGroupsSection?.groups ?? [],
            ...section.groups ?? []];

          if (section.count > 0 && state.searchResultspage === 1) {
            state.buyerGroupsSection.count += section.count;
            state.buyerGroupsSection.firstCount = state.buyerGroupsSection.groups
              ? state.buyerGroupsSection.groups.length : 0;
          }
        });

      //
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Companies,
        )
        .forEach((section) => {
          state.companiesSection.title = section.title;

          state.companiesSection.companies = [...state?.companiesSection?.companies ?? [],
            ...section.companies ?? []];

          if (state?.companiesSection?.companies.length === section.count) {
            state.isCompanySearchEnded = true;
          }

          if (section.count > 0 && state.searchResultspage === 1) {
            state.companiesSection.count += section.count;
            state.companiesSection.firstCount = state.companiesSection.companies
              ? state.companiesSection.companies.length : 0;
          }
        });

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === 'Documents',
        )
        .forEach((section) => {
          state.documentSection.title = section.title;

          state.documentSection.documents = [...state?.documentSection?.documents ?? [],
            ...section.documents ?? []];

          if (state?.documentSection?.documents.length === section.count) {
            state.isCompanySearchEnded = true;
          }

          if (section.count > 0 && state.searchResultspage === 1) {
            state.documentSection.count += section.count;
            state.documentSection.firstCount = state.documentSection.documents
              ? state.documentSection.documents.length : 0;
          }
        });

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Executives,
        )
        .forEach((section) => {
          state.peopleSection.title = section.title;

          state.peopleSection.persons = [...(state.peopleSection.persons ?? []),
            ...(section.persons ?? [])];

          if (section.count > 0 && state.searchResultspage === 1) {
            state.peopleSection.count += section.count;
            state.peopleSection.firstCount = state.peopleSection.persons
              ? state.peopleSection.persons.length : 0;
          }
        });

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.News,
        )
        .forEach((section) => {
          state.articlesSection.title = section.title;

          state.articlesSection.articles = [...(state.articlesSection.articles ?? []),
            ...(section.articles ?? []),
          ];

          state.articlesSection.count = section?.count ?? 0;
          if (section.count > 0 && state.searchResultspage === 1) {
            state.articlesSection.firstCount = state.articlesSection.articles
              ? state.articlesSection.articles.length : 0;
          }
        });

      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Industries,
        )
        .forEach((section) => {
          state.industriesSection.title = section.title;

          state.industriesSection.industries = [...(state.industriesSection.industries ?? []),
            ...(section.industries ?? []),
          ];

          state.industriesSection.count = section?.count ?? 0;
          if (section.count > 0 && state.searchResultspage === 1) {
            state.industriesSection.firstCount = state.industriesSection.industries
              ? state.industriesSection.industries.length : 0;
          }
        });
    },

    globalSearchResultsError(state, action: PayloadAction<string>) {
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = true;
      state.errorText = action.payload;
      state.isPagination = false;
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;
      state.isCompanyExtendedLoaded = true;
      state.isCompanyExtendedLoading = false;
      state.isBuyerGroupExtendedLoading = false;
    },

    globalSearchResultsResetInit(state) {
      const stateToReturn = {
        ...initialState,
        peopleSection: {
          ...state.peopleSection,
          followedPersons: state.peopleSection.followedPersons,
        },
        companiesSection: {
          ...state.companiesSection,
          followedCompanies: state.companiesSection.followedCompanies,
        },
      };
      return stateToReturn;
    },

    globalSearchResultsResetEmpty(state) {
      state.articlesSection = initialState.articlesSection;
      state.buyerGroupsSection = initialState.buyerGroupsSection;
      state.peopleSection = initialState.peopleSection;
      state.companiesSection = initialState.companiesSection;
      state.industriesSection = initialState.industriesSection;
      state.isCompanySearchEnded = initialState.isCompanySearchEnded;
    },

    globalSearchResultsReset(state) {
      state.peopleSection = {
        ...state.peopleSection,
        followedPersons: state.peopleSection.followedPersons,
        persons: state.peopleSection.persons
          ?.slice(0, state.peopleSection.firstCount),
      };
      state.companiesSection = {
        ...state.companiesSection,
        followedCompanies: state.companiesSection.followedCompanies,
        companies: state.companiesSection.companies
          ?.slice(0, state.companiesSection.firstCount),
      };
      state.buyerGroupsSection = {
        ...state.buyerGroupsSection,
        groups: state.buyerGroupsSection.groups
          ?.slice(0, state.buyerGroupsSection.firstCount),
      };
      state.articlesSection = {
        ...state.articlesSection,
        articles: state.articlesSection.articles
          ?.slice(0, state.articlesSection.firstCount),
      };
      state.industriesSection = {
        ...state.industriesSection,
        industries: state.industriesSection.industries
          ?.slice(0, state.industriesSection.firstCount),
      };
    },

    personLinkedinSearch(state, action: PayloadAction<PersonLinkedinSearch>) {
      const { payload } = action;
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;

      state.peopleSection.persons = [
        ...(state.peopleSection.persons ?? []),
        ...payload.results,
      ];

      state.peopleSection.linkedInCount = payload.count ?? 0;
      if (
        state.peopleSection.linkedInCount > 0
        && state.searchResultspage === 1
      ) {
        state.peopleSection.count += state.peopleSection.linkedInCount;
        state.peopleSection.firstCount = state.peopleSection.persons
          ? state.peopleSection.persons.length : 0;
      }
    },
    companyLinkedinSearch(state, action: PayloadAction<CompanyLinkedinSearch>) {
      const { payload } = action;
      state.isCompanyExtendedLoaded = true;
      state.isCompanyExtendedLoading = false;

      state.companiesSection.companies = [
        ...(state.companiesSection.companies ?? []),
        ...payload.companies,
      ];

      state.companiesSection.linkedInCount = payload.count ?? 0;
      if (
        state.companiesSection.linkedInCount > 0
        && state.searchResultspage === 1
      ) {
        state.companiesSection.count += state.companiesSection.linkedInCount;
        state.companiesSection.firstCount = state.companiesSection.companies
          ? state.companiesSection.companies.length : 0;
      }
    },

    searchFollowHandling(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.peopleSection?.persons?.forEach((person) => {
        if (person.personId === payload) {
          person.personAdded = true;
          state.peopleSection.followedPersons?.push(person);
        }
      });
    },

    searchUnfollowHandling(state, action: PayloadAction<number[]>) {
      const { payload } = action;
      state.peopleSection?.persons?.forEach((person) => {
        if (payload.includes(person.personId)) {
          person.personAdded = false;
        }
      });
      payload.forEach((personId) => {
        const index = state.peopleSection.followedPersons?.findIndex(
          (followedPerson) => followedPerson.personId === personId,
        );
        if (index !== undefined && index !== -1) {
          state.peopleSection.followedPersons?.splice(index, 1);
        }
      });
    },
    handlePersonNotes(state, action: PayloadAction<number>) {
      const id = action.payload;
      const members = state.peopleSection?.persons ?? [];
      const filteredPerson = members
        .find((person) => person.personId === id);
      if (filteredPerson) {
        filteredPerson.personNotes = 0;
      }
    },
    searchCompanyFollowLoading(
      state,
      action: PayloadAction<FollowCompanyRequest>,
    ) {
      const { payload } = action;
      const { companyId } = payload;
      const index = state?.companiesSection?.companies?.findIndex(
        (company) => company.companyId === parseInt(companyId.trim(), 10),
      );
      if (index !== -1 && index !== undefined) {
        if (
          state
          && state.companiesSection
          && state.companiesSection.companies
        ) {
          state.companiesSection.companies[index].loading = true;
        }
      }
      const indexOfFollowed = state?.companiesSection?.followedCompanies?.findIndex(
        (company) => company.companyId === parseInt(companyId.trim(), 10),
      );
      if (
        indexOfFollowed !== -1
        && indexOfFollowed !== undefined
        && state?.companiesSection?.followedCompanies?.length
      ) {
        state.companiesSection.followedCompanies[indexOfFollowed].loading = true;
      }
    },

    searchCompaniesFollowHandling(
      state,
      action: PayloadAction<FollowCompanyResponse>,
    ) {
      const { payload } = action;
      const { companyIds, popUpResponse } = payload.response;
      const { companies } = state.companiesSection;
      const { followedCompanies } = state.companiesSection;

      companyIds.forEach((companyId) => {
        const index = companies?.findIndex(
          (company) => String(company?.companyId) === companyId,
        );
        if (index !== -1 && index !== undefined) {
          if (
            state
            && state.companiesSection
            && companies
          ) {
            if (popUpResponse.showPlanPopup !== 8) {
              followedCompanies?.push(
                companies[index],
              );
              companies[index].loading = false;
              companies[index].companySubscribed = true;
              companies[index].companySubscribers += 1;
            } else {
              companies[index].loading = false;
            }
          }
        }
      });
      state.companiesSection.companies = companies;
      state.companiesSection.followedCompanies = followedCompanies;
    },
    searchCompaniesUnfollowHandling(state, action: PayloadAction<string[]>) {
      const { payload } = action;
      const { companies } = state.companiesSection;
      const { followedCompanies } = state.companiesSection;
      companies?.forEach((company) => {
        if (payload.includes(String(company.companyId))) {
          company.companySubscribed = false;
          company.loading = false;
        }
      });
      payload.forEach((companyId) => {
        const index = followedCompanies?.findIndex(
          (followedCompany) => followedCompany.companyId === parseInt(companyId, 10),
        );
        if (index !== undefined && index !== -1) {
          followedCompanies?.splice(index, 1);
        }
        const indexOfCompaniesSection = companies?.findIndex(
          (company) => String(company?.companyId) === companyId,
        ) || 0;
        if (companies && indexOfCompaniesSection >= 0) {
          companies[indexOfCompaniesSection]
            .companySubscribers -= 1;
        }
      });
      state.companiesSection.companies = companies;
      state.companiesSection.followedCompanies = followedCompanies;
    },
    searchPersonExtendedLoaded(state) {
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;
      state.isCompanyExtendedLoading = false;
      state.isBuyerGroupExtendedLoading = false;
    },
    searchCompaniesExtendedLoaded(state) {
      state.isCompanyExtendedLoaded = true;
      state.isPersonExtendedLoading = false;
      state.isCompanyExtendedLoading = false;
      state.isBuyerGroupExtendedLoading = false;
    },
    searchBuyerGroupExtendedLoaded(state) {
      state.isPersonExtendedLoading = false;
      state.isCompanyExtendedLoading = false;
      state.isBuyerGroupExtendedLoading = false;
      state.isBuyerGroupExtendedLoaded = true;
    },
    searchIndustryFollowLoading(state, action: PayloadAction<number>) {
      const index = state?.industriesSection?.industries?.findIndex(
        (industry) => industry?.id === action.payload,
      );
      if (index !== undefined && index > -1 && state?.industriesSection?.industries) {
        state.industriesSection.industries[index].loading = true;
      }
    },
    searchIndustryFollowLoadingSuccess(state, action: PayloadAction<number>) {
      const index = state?.industriesSection?.industries?.findIndex(
        (industry) => industry?.id === action.payload,
      );
      if (index !== undefined && index > -1 && state?.industriesSection?.industries) {
        state.industriesSection.industries[index].loading = false;
        state.industriesSection.industries[index].added = true;
      }
    },
    searchIndustryFollowLoadingFail(state, action: PayloadAction<number>) {
      const index = state?.industriesSection?.industries?.findIndex(
        (industry) => industry?.id === action.payload,
      );
      if (index !== undefined && index > -1 && state?.industriesSection?.industries) {
        state.industriesSection.industries[index].loading = false;
      }
    },
    searchIndustriesUnfollowHandling(state, action: PayloadAction<number[]>) {
      const { payload } = action;
      state.industriesSection.industries?.forEach((industry) => {
        if (payload.includes(industry.id)) {
          industry.added = false;
          industry.loading = false;
        }
      });
    },
    setGlobalSearchLoaded(state, action: PayloadAction<boolean>) {
      state.isGlobalSearchLoaded = action.payload;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.articlesSection
          && state.articlesSection.articles
          && state.articlesSection.articles.length) {
          const index = state.articlesSection.articles.findIndex(
            (article) => article.articleId.toString() === articleId.toString(),
          );
          if (index !== -1) {
            state.articlesSection.articles[index].isFavorite = favoriteFlag === 1;
          }
        }
      }
    },
    groupDeleted(state, action: PayloadAction<DeleteGroupResult>) {
      const { payload } = action;
      const { groupId } = payload;
      state.buyerGroupsSection.groups = state.buyerGroupsSection.groups?.filter(
        (g) => g.groupId !== groupId,
      );
      state.buyerGroupsSection.count -= 1;
    },
    clearSearchText(state) {
      state.searchText = '';
    },
  },
});

export const { actions, reducer, name: sliceKey } = useGlobalSearchResultsSlice;

import { Popover } from 'antd';
import SocialShareIcons from 'Components/share/SocialShareIcons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import setImageSize, { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';

import { actions as favouriteArticleActions } from 'Slice/FavoriteArticlesSlice';
import { useEffect, useState, useRef } from 'react';
import { selectOpenDrawer } from 'Selector/CertificationSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  selectAlertDrawerVisible,
  selectArticle,
  selectArticleItemId,
  selectArticleItemSummary,
  selectArticleItemSummaryLoaded,
  selectArticleItemSummaryLoading, selectSimilarArticleTwo,
  selectSimilarDrawerVisible,
} from 'Selector/ArticleSector';
import {
  actions as articleHelper,
} from 'Slice/ArticlesSlice';
import { actions as UiAction } from 'Slice/UISlice';
import { selectArticles } from 'Selector/AlertsSelector';
import { Article, ArticleDetailResponse } from 'Types/ArticleTypes';
import { useSearchParams } from 'react-router-dom';
import {
  boxShadow,
  creator,
  favouriteArticleColor, greyColorSecondary,
  maxMobile,
  maxTablet,
  maxWidth, minExtraLargeScreen, minStandardScreen, minWidthApp, primaryColor, primaryFont,
  primaryHeadingColor, retina, secondaryFont, textBlack, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import { translateSkipAreas } from 'Utils/Constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SingleFavoriteIcon from 'Components/common/assets/svgIcons/SingleFavoriteIcon';
import ShareArticleStrokeIcon from 'Components/common/assets/svgIcons/ShareArticleStrokeIcon';

type Props = {
  image: string,
  heading: string,
  summary?: string,
  domain: string,
  date: string,
  industry: string,
  redirectUrl: string,
  trigger: string,
  articleId: number,
  showDrawer?: boolean;
  isRead?: boolean;
  alertId?: number;
  isFavorite?: boolean;
  articleAlert?: string;
  isSimilarArticle?: boolean
  companyId?: number
  similarArticlesCount?: number

} & typeof defaultProps;

const defaultProps = {
  showDrawer: false,
  isRead: false,
  alertId: 0,
  isFavorite: false,
  articleAlert: '',
  summary: '',
  isSimilarArticle: false,
  companyId: 0,
  similarArticlesCount: 0,
};

const ArticleWrapper = styled.div < { tiggerColor: string, tagWidth: number } >`
  &.active {
    background-color: #EFF5FB;
  }
  display:flex;
  align-items:center;
  position: relative;
  padding: 12px 12px 12px 5px;
  margin-bottom: 8px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0px;
    height: 100%;
    background-color: ${(props) => props.tiggerColor};
    width: 7px;
    transform: unset;
    @media all and (min-width: ${minExtraLargeScreen}) {
      width: 9px;
    }
    @media all and (${retina}) {
      width: 7px;
    }
    @media all and (max-width: ${minStandardScreen}) {
      width: 6px;
    }
    @media all and (max-width: ${maxTablet}) {
      width: 4px;
    }
    @media all and (max-width: ${maxMobile}){
      width: 5px;
    }
  }
  .text-area {
    min-width: unset;
    width: calc(100% - 129px);
    @media all and (max-width: ${maxWidth}), ${retina} {
      width: calc(100% - 104px);
      min-width: calc(100% - 104px);
    }
    @media all and (max-width: ${maxMobile}) {
      width: calc(100% - 92px);
      min-width: calc(100% - 92px);
    }
    .heading {
      &.heading-height {
        min-height: unset;
        margin-top: 0px;
      }
    }
    .bottom-action-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .action-sharing {
        display: flex;
        align-items: center;
        .favorite-icon {
          display: inline-flex;
          margin-right: 8px;
          width: 16px;
          height: 15.206px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            width: 19.6px;
            height: 18.628px;
            margin-right: 12px;
          }
          @media all and (${retina}) {
            width: 10.5px;
            height: 9.92px;
            margin-right: 8px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            width: 11.574px;
            height: 11px;
            margin-right: 7px;
          }
          @media all and (max-width: ${maxTablet}){
            margin-right: 5px;
          }
          @media all and (max-width: ${maxMobile}){
            margin-right: 6px;
          }
          svg{
            height: 100%;
            width: 100%;
          }
        }
        .popover-sharing {
          display: inline-flex;
          &.share-article-icon-svg {
            width: 12.511px;
            height: 15px;
            display: inline-flex;
            @media all and (min-width: ${minExtraLargeScreen}) {
              width: 15.301px;
              height: 18.346px;
            }
            @media all and (${retina}) {
              width: 9px;
              height: 11px;
            }
            @media all and (max-width: ${minStandardScreen}) {
              width: 9.175px;
              height: 11px;
            }
            & > svg{
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.tileListView{
    height: 120px;
    margin-bottom: 8px;
    padding: 10px 12px 10px 17px;
    box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);
    @media all and (min-width: ${minExtraLargeScreen}) {
      height: 136px;
      margin-bottom: 8px;
      padding: 12px 16.54px 12px 21px;
    }
    @media all and (${retina}) {
      height: 113px;
      margin-bottom: 8px;
      padding: 9px 12px 9px 17px;
    }
    @media all and (max-width: ${minStandardScreen}) {
      height: 106px;
      margin-bottom: 8px;
      padding: 9px 8px 9px 14px;
    }
    @media all and (max-width: ${maxTablet}) {
      height: 100px;
      margin-bottom: 6px;
      padding: 6px 8px 6px 10px;
    }
    @media all and (max-width: ${maxMobile}){
      margin-bottom: 8px;
      padding: 6px 5px 6px 11px;
    }
    .profile-area,
    .videoFrameArea{
      width: 100px;
      min-width: 100px;
      height: 100px;
      margin: 0;
      box-shadow: ${boxShadow};
      border-radius: 6px;
      overflow: hidden;
      @media all and (min-width: ${minExtraLargeScreen}) {
        width: 112px;
        min-width: 112px;
        height: 112px;
      }
      @media all and (${retina}) {
        width: 100px;
        min-width: 100px;
        height: 95px;
      }
      @media all and (max-width: ${minStandardScreen}) {
        min-width: 88px;
        width: 88px;
        height: 88px;
      }
      @media all and (max-width: ${maxMobile}){
        width: 86px;
        min-width: 86px;
        height: 88px;
      }
    }
    .profile-area{
      & > span{
        height: 100%;
        width: 100%;
        img{
          height: 100%;
          width: 100%;
        }
      }
      img {
        width: 100%;
      }
    }
    .videoFrameArea {
      position: relative;
      .thumbnail-image {
        height: 100%;
        width: 100%;
        min-width: 100%;
        border-radius: 6px;
      }
      .layer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
      .youtube-icon {
        height: auto;
        width: 35px;
      }
    }
    .footer-data-article{
      margin-top: 0;
      height: max-content;
      .bottom-tips{
        display:none;
      }
    }
    .text-area{
      margin-left: 10px;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: unset;
      height: 100%;
      @media all and (min-width: ${minExtraLargeScreen}) {
        margin-left: 12px;
        padding: 0;
      }
      @media all and (${retina}) {
        margin-left: 10px;
        padding: 0;
      }
      @media all and (max-width: ${minStandardScreen}) {
        margin-left: 8px;
        padding: 0;
      }
      @media all and (max-width: ${maxTablet}){
        margin-left: 6px;
        padding: 0;
        min-width: unset;
      }
      @media all and (max-width: ${maxMobile}){
        margin-left: 6px;
        padding: 0;
      }
      .title-summary-div{
        // just to save overflow on icon
        padding-right: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .tagAndIndustryBox{
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        margin-bottom: 4px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          gap: 24px;
          margin-bottom: 2px;
        }
        @media all and (${retina}) {
          gap: 16px;
        }
        @media all and (max-width: ${minStandardScreen}){
          gap: 12px;
          margin-bottom: 5px;
        }
        @media all and (max-width: ${maxTablet}){
          gap: 10px;
          margin-bottom: 4px;
        }
        @media all and (max-width: ${maxMobile}){
          gap: 8px;
          margin-bottom: 6px;
        }
        .industryName{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: ${creator};
          font-family: ${primaryFont};
          font-size: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 14px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
            line-height: 16px;
          }
          @media all and (${retina}) {
            font-size: 10px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 10.889px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 7.995px;
            line-height: 8.706px;
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 12px;
          }
        }
        .tagArticle {
          border: none;
          color: #FFF;
          font-size: 12px;
          line-height: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          letter-spacing: -0.361px;
          border-radius: 4px;
          padding: 0px 8px;
          position: unset;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          margin-left: auto;
          font-family: ${primaryFont};
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
            line-height: 14px;
            padding: 0 10.228px;
            border-radius: 6px;
            height: 24px;
          }
          @media all and (${retina}) {
            font-size: 10px;
            line-height: 10px;
            padding: 0 7.111px;
            border-radius: 4px;
            height: 16px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 10px;
            padding: 0 6px;
            border-radius: 3px;
            height: 14px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 7.995px;
            line-height: 8px;
            padding: 0 4px;
            border-radius: 2px;
            height: 11px;
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 10px;
            padding: 0 6px;
            border-radius: 2px;
            height: 14px;
          }
        }
      }
      .heading {
        color: ${textBlack};
        font-family: ${secondaryFont};
        font-weight: ${wMedium};
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.277px;
        margin-bottom: 4px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.641px;
          font-weight: ${wMBold};
        }
        @media all and (${retina}) {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.277px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.242px;
        }
        @media all and (max-width: ${maxTablet}){
          letter-spacing: -0.206px;
        }
        @media all and (max-width: ${maxMobile}){
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.242px;
          margin-bottom: 12px;
          padding-right: 0px;
        }
        &.text-truncate-one-line{
          @media all and (max-width: ${maxMobile}){
            -webkit-line-clamp: 3;
          }
        }
      }
      .summary {
        margin-bottom: 12px;
        margin-right: 0;
        font-weight: ${wLight};
        letter-spacing: -0.311px;
        color: ${primaryHeadingColor};
        font-family: ${primaryFont};
        font-size: 12px;
        line-height: normal;
        @media all and (min-width: ${minExtraLargeScreen}) {
          font-size: 14px;
          line-height: normal;
        }
        @media all and (max-width: ${minStandardScreen}) {
          font-size: 10px;
          line-height: normal;
        }
        @media all and (max-width: ${minWidthApp}){
          margin-right: 0;
        }
        @media all and (max-width: ${maxTablet}){
          margin-bottom: 15px;
        }
        @media all and (max-width: ${maxMobile}){
          display: none !important;
        }
        &.text-truncate-one-line{
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-wrap: break-word;
        }
      }
      .sourcesBox{
        display: flex;
        align-items: center;
        width: calc(100% - 52px);
        .separatorLeftOnly,
        .separatorBothSide{
          padding-left: 13px;
          position: relative;
          @media all and (min-width: ${minExtraLargeScreen}) {
            padding-left: 17px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            padding-left: 9px;
          }
          @media all and (max-width: ${maxTablet}){
            padding-left: 7px;
          }
          @media all and (max-width: ${maxMobile}){
            padding-left: 9px;
          }
          &::after,
          &::before{
            content: "";
            height: 10px;
            width: 1px;
            background-color: #CFCFCF;
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            @media all and (min-width: ${minExtraLargeScreen}) {
              left: 8px;
            }
            @media all and (max-width: ${minStandardScreen}){
              left: 4px;
              height: 10px;
            }
            @media all and (max-width: ${maxTablet}){
              left: 3px;
              height: 8px;
            }
            @media all and (max-width: ${maxMobile}){
              left: 4px;
              height: 9px;
            }
          }
          &::before{
            right: 6px;
            left: unset;
            @media all and (min-width: ${minExtraLargeScreen}) {
              right: 8px;
            }
            @media all and (max-width: ${minStandardScreen}){
              right: 4px;
            }
            @media all and (max-width: ${maxTablet}){
              right: 3px;
            }
            @media all and (max-width: ${maxMobile}){
              right: 4px;
            }
          }
        }
        .separatorBothSide{
          padding: 0 13px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            padding: 0 17px;
          }
          @media all and (${retina}) {
          }
          @media all and (max-width: ${minStandardScreen}){
            padding: 0 9px;
          }
          @media all and (max-width: ${maxTablet}){
            padding: 0 7px;
          }
          @media all and (max-width: ${maxMobile}){
            padding: 0 9px;
          }
        }
        .separatorLeftOnly{
          &::before{
            display: none !important;
          }
        }
        .source{
          color: ${greyColorSecondary};
          text-align: center;
          font-family: ${primaryFont};
          font-size: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
          }
          @media all and (${retina}) {
            font-size: 10px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 16px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 8px;
            line-height: 13.624px
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 14px;
          }
        }
        .similarArticles,
        .date{
          overflow: visible;
        }
        .similarArticles{
          color: ${primaryColor};
        }
      }
      .bottom-action-wrapper{
        margin-top: auto;
      }
    }
  }
  &.notImage{
    .profile-area {
      display: none;
    }
    .text-area{
      margin-left: 0;
    }
  }
`;

const ContentWrapper = styled.div`
`;

const ArticleTag = styled.span <{ tagColor: string }>`
  background-color: ${(props) => props.tagColor};
`;

const ArticleItem = function ArticleItem(props: Props) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [tagArticleWidth, setTagArticleWidth] = useState(109);
  const certificationDrawer = useSelector(selectOpenDrawer);
  const similarArticlesTwo = useSelector(selectSimilarArticleTwo);
  const host = window.location.href;
  const article = useSelector(selectArticle);
  const articleSummary = useSelector(selectArticleItemSummary);
  const summaryLoading = useSelector(selectArticleItemSummaryLoading);
  const summaryLoaded = useSelector(selectArticleItemSummaryLoaded);
  const alertDrawerVisible = useSelector(selectAlertDrawerVisible);
  const similarDrawerVisible = useSelector(selectSimilarDrawerVisible);
  const drawerArticles: Article[] = useSelector(selectArticles);
  const articleItemId = useSelector(selectArticleItemId);
  const articleItemSummary = useSelector(selectArticleItemSummary);
  const [searchParams] = useSearchParams();
  const articleIdDeepLink: number = parseInt(searchParams.get('articleId') || '0', 10);
  const refForTagArticle = useRef<HTMLDivElement>(null);
  const startTime = getCurrentTime();

  useEffect(() => {
    if (certificationDrawer && visible) {
      setVisible(false);
    }
  }, [certificationDrawer]);
  const {
    image,
    heading,
    summary,
    domain,
    date,
    industry,
    redirectUrl,
    trigger,
    articleId,
    showDrawer,
    isRead,
    alertId,
    isFavorite,
    articleAlert,
    isSimilarArticle,
    companyId,
    similarArticlesCount,
  } = props;

  const toggleVisible = () => {
    const isSimilar = !!alertDrawerVisible;

    const articleHelperParams = {
      articleid: articleId,
      ...companyId && companyId > 0 && { companyId },
      ...articleIdDeepLink > 0 && { isPushNotification: true },
      isSimilarArticle: isSimilar,
      ...(alertId > 0 && { alertId }),
    };

    dispatch(articleHelper.getArticle(articleHelperParams));
    dispatch(articleHelper.getSimilarArticle(
      { article_id: articleId, isSimilarArticle: isSimilar },
    ));

    if (articleItemId === articleId) {
      dispatch(articleHelper.setArticleSummary(
        { articleId, isSimilarArticle: isSimilar, summary: articleItemSummary },
      ));
    } else {
      dispatch(articleHelper.getArticleSummary({ articleId, isSimilarArticle: isSimilar }));
    }

    if (!alertDrawerVisible) {
      const selectedArticle = drawerArticles.find((articless) => articless.articleId === articleId);
      if (selectedArticle) {
        const articleDetailResponse = { response: selectedArticle };
        dispatch(articleHelper.setArticleDetail(articleDetailResponse as ArticleDetailResponse));
      }
      dispatch(articleHelper.setAlertDrawerVisible(!alertDrawerVisible));
    } else {
      dispatch(articleHelper.setSimilarDrawerVisible(!similarDrawerVisible));
    }
    setVisible(!visible);
  };

  const content = (
    <ContentWrapper className="sharingPopoverContentWrapper">
      <h6 className="heading-popover">QuickShare to:</h6>
      <SocialShareIcons
        articleid={articleId}
        title={heading}
        img={image}
        domain={domain}
        entity="article"
        alertId={alertId}
        url={redirectUrl}
        summary={articleSummary}
        summaryLoading={summaryLoading}
        summaryLoaded={summaryLoaded}
      />
    </ContentWrapper>
  );

  const addToFavorite = () => {
    const data = {
      articleId,
      favoriteFlag: isFavorite ? 0 : 1,
      isSimilarArticle: !!isSimilarArticle,
      isSimilarArticleTwo: similarArticlesTwo.length > 0,
    };
    dispatch(favouriteArticleActions.addToFavorite(data));
  };

  const handleClick = () => {
    if (similarArticlesTwo && similarArticlesTwo?.length !== 0) {
      dispatch(UiAction.isSimilarArticleScrollItem(false));
      dispatch(articleHelper.resetArticleTwo());
      dispatch(articleHelper.getSimilarArticle({
        article_id: articleId,
        isSimilarArticle: true,
      }));
      dispatch(articleHelper.getArticle({
        articleid: articleId,
        ...companyId && companyId > 0 && { companyId },
        isSimilarArticle: true,
        ...(alertId > 0 && { alertId }),
      }));
      dispatch(articleHelper.getArticleSummary({
        articleId,
        isSimilarArticle: true,
      }));
    } else {
      toggleVisible();
    }
    if (isSimilarArticle && article) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('similarArticle_clicked_open', startTime, {
          article_id: article.articleId,
          article_title: article.title,
          alert_id: article.alerts[0].alertId,
          article_link: article.url,
          host,
        }),
      ));
    } else {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('alertPanel_sales_tigger_clicked', 0, {
          ...articleAlert && { sales_trigger: articleAlert.toLowerCase() },
          host,
        }),
      ));
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('article_share_intent', 0, {
          host,
        }),
      ));
    }
  };

  // const handleOpenVisible = () => {
  //   toggleVisible();
  // };
  useEffect(() => {
    if (refForTagArticle?.current?.clientWidth) {
      setTagArticleWidth(refForTagArticle?.current?.clientWidth);
    }
  }, [summaryLoading]);

  return (
    <ArticleWrapper
      tagWidth={tagArticleWidth}
      tiggerColor={trigger}
      className={`article-item-revamp tileListView  ${!isRead ? 'active' : ''} ${!image ? 'notImage' : ''}`}
    >
      <div
        className="profile-area"
        onClick={showDrawer ? handleClick : () => { }}
        onKeyPress={showDrawer ? toggleVisible : () => { }}
        role="button"
        aria-label="Article is clickable"
        tabIndex={0}
      >
        {/* {image && <Image backgroundImage={setImageSize(image, 72, 72, articleId)} />} */}

        {image && (
          <LazyLoadImage
            src={setImageSize(image, 72, 72, articleId)}
            alt=""
          />
        )}
      </div>
      <div className="text-area">
        <div className="tagAndIndustryBox">
          <div
            className="industryName"
            onClick={showDrawer ? handleClick : () => { }}
            onKeyPress={showDrawer ? toggleVisible : () => { }}
            role="button"
            aria-label="Toggle drawer"
            tabIndex={0}
          >
            {industry}
          </div>
          {articleAlert && (
            <ArticleTag tagColor={trigger} className="tagArticle c-auto" ref={refForTagArticle}>
              {articleAlert}
            </ArticleTag>
          )}
        </div>

        <h6
          className={classNames(`heading ${!image && summary ? 'heading-height text-truncate-one-line' : 'text-truncate-three-line'}`)}
          onClick={showDrawer ? handleClick : () => { }}
          onKeyPress={showDrawer ? toggleVisible : () => { }}
          aria-hidden="true"
        >
          {heading}
        </h6>
        {!image && summary ? (
          <span
            className="summary text-truncate-two-line"
            onClick={showDrawer ? handleClick : () => { }}
            onKeyPress={showDrawer ? toggleVisible : () => { }}
            role="button"
            aria-label="Toggle drawer"
            tabIndex={0}
          >
            {summary}
          </span>
        ) : null}
        <div className="bottom-action-wrapper">
          <div className="sourcesBox">
            <div className="source date">{date}</div>
            <div className={`source domain
                ${translateSkipAreas}
                ${similarArticlesCount ? 'separatorBothSide' : 'separatorLeftOnly'}
              `}
            >
              {domain}
            </div>
            {similarArticlesCount ? (
              <div
                className="source similarArticles"
                onClick={() => {
                  if (alertDrawerVisible && !similarDrawerVisible) {
                    dispatch(UiAction.isSimilarArticleScrollItem(true));
                  } else if (!similarDrawerVisible) {
                    dispatch(UiAction.isSimilarArticleScroll(true));
                  }
                  handleClick();
                  dispatch(flurryActions.callFlurryEvent(
                    valueToObject('similarArticle_count_clicked', startTime, {
                      article_id: article?.articleId,
                      article_title: article?.title,
                      alert_id: article?.alerts[0].alertId,
                      article_link: article?.url,
                      host,
                    }),
                  ));
                }}
                onKeyPress={() => {
                  if (alertDrawerVisible && !similarDrawerVisible) {
                    dispatch(UiAction.isSimilarArticleScrollItem(true));
                  } else if (!similarDrawerVisible) {
                    dispatch(UiAction.isSimilarArticleScroll(true));
                  }
                  handleClick();
                  dispatch(flurryActions.callFlurryEvent(
                    valueToObject('similarArticle_count_clicked', startTime, {
                      article_id: article?.articleId,
                      article_title: article?.title,
                      alert_id: article?.alerts[0].alertId,
                      article_link: article?.url,
                      host,
                    }),
                  ));
                }}
                tabIndex={0}
                role="button"
              >
                Similar:
                {' '}
                <span>{similarArticlesCount}</span>
              </div>
            ) : null}
          </div>
          <div className="action-sharing">
            <span
              className="favorite-icon"
              onClick={() => addToFavorite()}
              onKeyPress={() => addToFavorite()}
              tabIndex={0}
              aria-label="Add to favourites"
              role="button"
            >
              {isFavorite
                ? (
                  <SingleFavoriteIcon
                    strokeColor={favouriteArticleColor}
                    fillColor={favouriteArticleColor}
                  />
                )
                : (
                  <SingleFavoriteIcon
                    strokeColor={greyColorSecondary}
                  />
                )}
            </span>
            <Popover
              placement="leftBottom"
              showArrow={false}
              content={content}
              trigger="click"
              onOpenChange={handleOpenChange}
              getPopupContainer={(e: HTMLElement) => e}
              className="popover-sharing popoverSharingCustom popoverSharingListingView"
              arrow={false}
            >
              <div aria-hidden="true" className="share-article-icon-svg">
                <ShareArticleStrokeIcon color={greyColorSecondary} />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </ArticleWrapper>
  );
};
ArticleItem.defaultProps = defaultProps;
export default ArticleItem;

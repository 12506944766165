import React, { ReactNode, useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsScroll, selectHighlight, selectActivetype, selectIsHighlighted,
} from 'Selector/CertificationSelector';
import classNames from 'classnames';
import { actions as certificationActions } from '../../../Slice/CertificationSlice';

const { Header } = Layout;

type Props = {
  children: ReactNode,
  headerClassName?: string,
} & typeof defaultProps;

const defaultProps = {
  headerClassName: '',
};

const SubNavBarPrimary = function SubNavBarPrimary(props: Props) {
  const { children, headerClassName } = props;
  const isScroll = useSelector(selectIsScroll);
  const isHighlight = useSelector(selectHighlight);
  const currentType = useSelector(selectActivetype);
  const isHighlighted = useSelector(selectIsHighlighted);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isHighlighted && !isScroll && (currentType === 'person_insights' || currentType === 'company_sales' || currentType === 'account_sales_tab')) {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
    }
  }, [isScroll, currentType]);

  return (
    <Header className={classNames(`sub-header-main-wrapper ${headerClassName}`, { highlightedSubNavBar: isHighlight && (currentType === 'person_insights' || currentType === 'company_sales' || currentType === 'account_sales_tab') })}>
      <div className="container h-100">
        <div className={classNames(
          'sub-header-wrapper',
        )}
        >
          {children}
        </div>
      </div>
    </Header>
  );
};
SubNavBarPrimary.defaultProps = defaultProps;
export default SubNavBarPrimary;

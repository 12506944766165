import styled from 'styled-components';
import { Modal } from 'antd';
import Iframe from 'react-iframe';
import YoutubeVideoCrossIcon from '../assets/svgIcons/YoutubeVideoCrossIcon';

const Wrapper = styled.div``;

type Props = {
  videoLink?: string,
  visible?: boolean;
  setVisible?: () => void;
} & typeof defaultProps;

const defaultProps = {
  videoLink: '',
  visible: false,
  setVisible: {},
};

const VideoPopup = function VideoPopup(props: Props) {
  const { visible, videoLink, setVisible } = props;
  return (
    <Wrapper className="popup_wrapper">
      <Modal
        open={visible}
        onCancel={setVisible}
        centered
        destroyOnClose
        maskClosable
        width={854}
        getContainer=""
        closeIcon={<div className="cross-icon-x"><YoutubeVideoCrossIcon /></div>}
        wrapClassName="video-popup-wrapper"
      >
        <Iframe
          url={videoLink}
          width="100%"
          height="100%"
          id="videoFrameId"
          className="videoFrame"
          display="block"
          position="relative"
          scrolling="no"
          frameBorder={0}
        />
      </Modal>
    </Wrapper>
  );
};

VideoPopup.defaultProps = defaultProps;
export default VideoPopup;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  UserOnboardingDataResponse,
  UserOnboardingRecommendedResponse,
} from 'Types/OnboardingTypes';
import { DailyIQCompanies, OnboardingState } from 'Types/GlobalTypes';
import { AddCompanyPopup, FollowCompanyResponse } from 'Types/CompaniesTypes';
import { Executive } from 'Types/PersonTypes';

export const initialState: OnboardingState = {
  recommendedCompanies: [],
  recommendedCompaniesLoading: false,
  companiesFollowed: [],
  personId: '',

  recommendedPeople: [],
  recommendedPeopleLoading: false,
  peopleFollowed: [],

  addCompanyPopup: {} as AddCompanyPopup,

  followedCompaniesCount: 0,
  dailyIqCompaniesLoading: false,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    getUserOnboardingData(state, action: PayloadAction<object>) {
      state.recommendedCompaniesLoading = true;
      state.recommendedPeopleLoading = true;
    },
    getRecommendedPeopleOnboarding(state, action: PayloadAction<object>) {
      state.recommendedPeopleLoading = true;
    },
    setRecommendedPeopleOnboarding(
      state,
      action: PayloadAction<UserOnboardingRecommendedResponse>,
    ) {
      const { data } = action.payload;
      state.recommendedPeopleLoading = false;
      state.peopleFollowed = data.filter((executive: Executive) => executive.personAdded);
      state.recommendedPeople = data.filter((executive: Executive) => !executive.personAdded);
    },
    setUserOnboardingData(state, action: PayloadAction<UserOnboardingDataResponse>) {
      const {
        companies, executives, followedCompanies, followedCompaniesCount, personId,
      } = action.payload.response;
      state.recommendedCompaniesLoading = false;
      state.recommendedPeopleLoading = false;

      state.personId = personId;

      state.companiesFollowed = companies.filter((company) => company.companySubscribed);
      state.recommendedCompanies = companies.filter((company) => !company.companySubscribed);

      state.peopleFollowed = executives.filter((executive) => executive.personAdded);
      state.recommendedPeople = executives.filter((executive) => !executive.personAdded);

      state.companiesFollowed = state.companiesFollowed.concat(followedCompanies);

      state.followedCompaniesCount = followedCompaniesCount;
    },
    handleCompanyFollow(state, action: PayloadAction<string>) {
      const companyId = parseInt(action.payload, 10);
      const { recommendedCompanies, companiesFollowed } = state;
      const index = recommendedCompanies.findIndex(
        (company) => companyId === company.companyId,
      );
      if (index !== -1) {
        recommendedCompanies[index].loading = true;
      }
    },
    setCompanyFollowed(state, action: PayloadAction<FollowCompanyResponse>) {
      const { companyIds, popUpResponse } = action.payload.response;
      const { recommendedCompanies, companiesFollowed } = state;
      if (popUpResponse.showPlanPopup === 8) {
        state.addCompanyPopup = popUpResponse;
        for (let i = 0; i < companyIds.length; i += 1) {
          const index = recommendedCompanies.findIndex(
            (company) => company.companyId === parseInt(companyIds[i].trim(), 10),
          );
          if (index !== -1) {
            recommendedCompanies[index].loading = false;
            state.recommendedCompanies = recommendedCompanies;
          }
        }
      } else {
        for (let i = 0; i < companyIds.length; i += 1) {
          const parsedId = parseInt(companyIds[i], 10);
          const index = recommendedCompanies.findIndex(
            (company) => parsedId === company.companyId,
          );
          if (index !== -1) {
            recommendedCompanies[index]
              .companySubscribed = !recommendedCompanies[index].companySubscribed;
            recommendedCompanies[index]
              .loading = false;
            recommendedCompanies[index].companySubscribers += 1;
            companiesFollowed.push(recommendedCompanies[index]);
            recommendedCompanies.splice(index, 1);
          }
        }
      }
    },
    handleCompanyUnFollow(state, action: PayloadAction<string>) {
      const companyId = parseInt(action.payload, 10);
      const { companiesFollowed } = state;
      const index = companiesFollowed.findIndex(
        (company) => companyId === company.companyId,
      );
      if (index !== -1) {
        companiesFollowed[index].loading = true;
      }
    },
    setCompanyUnFollowed(state, action: PayloadAction<string[]>) {
      const { payload: companyIds } = action;
      const { recommendedCompanies, companiesFollowed } = state;
      for (let i = 0; i < companyIds.length; i += 1) {
        const parsedId = parseInt(companyIds[i], 10);
        const index = companiesFollowed.findIndex(
          (company) => parsedId === company.companyId,
        );
        if (index !== -1) {
          companiesFollowed[index].companySubscribed = !companiesFollowed[index].companySubscribed;
          companiesFollowed[index].loading = false;
          companiesFollowed[index].companySubscribers -= 1;
          recommendedCompanies.push(companiesFollowed[index]);
          companiesFollowed.splice(index, 1);
        }
      }
    },
    setPeopleFollowed(state, action: PayloadAction<number>) {
      const { payload: personId } = action;
      const { recommendedPeople, peopleFollowed } = state;
      const index = recommendedPeople.findIndex(
        (person) => personId === person.personId,
      );
      if (index !== -1) {
        recommendedPeople[index].personAdded = !recommendedPeople[index].personAdded;
        peopleFollowed.push(recommendedPeople[index]);
        recommendedPeople.splice(index, 1);
      }
    },
    setPeopleUnFollowed(state, action: PayloadAction<number[]>) {
      const { payload: personIds } = action;
      const { recommendedPeople, peopleFollowed } = state;
      for (let i = 0; i < personIds.length; i += 1) {
        const index = peopleFollowed.findIndex(
          (person) => personIds[i] === person.personId,
        );
        if (index !== -1) {
          peopleFollowed[index].personAdded = !peopleFollowed[index].personAdded;
          recommendedPeople.push(peopleFollowed[index]);
          peopleFollowed.splice(index, 1);
        }
      }
    },
    resetOnboardingComplete(state, action) {
      return initialState;
    },
    resetAddCompanyPopup(state) {
      if (state.addCompanyPopup) {
        state.addCompanyPopup.showPlanPopup = 0;
        state.addCompanyPopup.popupHeading = '';
        state.addCompanyPopup.popupText = '';
        state.addCompanyPopup.contactUsEmail = '';
        state.addCompanyPopup.callToAction = '';
      }
    },
    getDailyIQCompanies(state, action: PayloadAction<DailyIQCompanies>) {
      state.dailyIqCompaniesLoading = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = onboardingSlice;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// UI & Style
import 'antd/dist/reset.css';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import ScrollToTop from 'Utils/ScrollToTop';
import ConditionalRendering from 'ConditionalRendering';
import { ConfigProvider as ConfigProvider5 } from 'antd';
import { configureAppStore } from './Store/SetupStore';
import App from './App';
import Style from './Style/Style';
import { MsalProvider } from './Components/MicrosoftEventScheduling/contexts/MsalContext';
// import * as Sentry from "@sentry/react";

const store = configureAppStore();
const siteKey: string = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';

// Sentry.init({
//   dsn: "https://041f09a37b6aed0434d692fbaec4cd87@o4507972650663936.ingest.us.sentry.io/4507972653809664",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing

//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={`${siteKey}`}
    >
      <MsalProvider>
        <Router>
          <Style />
          <DndProvider
            backend={TouchBackend}
            options={{ enableMouseEvents: true, enableTouchEvents: false }}
          >
            <ScrollToTop />
            <ConfigProvider5>
              <App>
                <Route path="*" element={<ConditionalRendering />} />
              </App>
            </ConfigProvider5>
          </DndProvider>

        </Router>
      </MsalProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
);

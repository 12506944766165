import {
  Checkbox, Input, InputRef, Modal, Progress,
} from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  maxWidth,
  primaryBgColor, primaryColor, primaryFont, retina, wMBold,
} from 'Utils/utilsStyle';
import {
  selectActiveTask, selectData,
  selectFullName, selectIsCertificationCompleted,
  selectLoading, selectNameLoading, selectActiveModule,
  selectAiCertificationData,
  selectAiCertificationLoading, selectIsAiCertificationCompleted,
} from 'Selector/CertificationSelector';
import { setLocalStorageValue, valueToObject } from 'Utils/UtilityFunctions';
import { CERTIFICATION_COMP_POPUPS } from 'Utils/Constants';
import { actions as flurryActions } from 'Slice/HelperSlice';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import { actions as UiActions } from 'Slice/UISlice';
import Cross from '../assets/svgIcons/Cross';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import Tick from '../assets/svgIcons/Tick';
import { actions as certificationActions } from '../../../Slice/CertificationSlice';

const Wrapper = styled.div``;

const ProgressBarDiv = styled.div`
  display: flex;
  width: 313px;
  margin: 0 auto 25px;
  .ant-progress {
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-progress-outer {
        width: 375px !important;
        padding-right: 40px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width: 325px !important;
        }
      }
      .ant-progress-bg {
        background-color: #E79540;
      }
      &.ant-progress-status-success {
        .ant-progress-bg {
          background-color: ${primaryColor};
        }
      }
      .ant-progress-text {
        display:none;
      }
      .ant-progress-inner {
        height: 10px;
        .ant-progress-bg {
          height: 10px !important;
        }
      }
      .anticon-check-circle {
        svg {
          margin-top: 5px;
          font-size: 16px;
        }
      }
    }
    .percentage{
    font-family: ${primaryFont};
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;

const Button = styled.span`
  background-color: ${primaryColor};
  width: 230px;
  height: 36px;
  border-radius: 16px;
  color: ${primaryBgColor};
  font-family: ${primaryFont};
  font-weight: ${wMBold};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 31px;
  cursor: pointer;
`;

const CertificationDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  .cross {
    position: absolute;
    right: 160px;
    cursor: pointer;
    svg {
      max-width: 8px;
      height: 8px;
    }
  }
  .tick {
    position: absolute;
    right: 142px;
    top: 1px;
    cursor: pointer;
    svg {
      max-width: 10px;
      height: 10px;
    }
  }
  .disabled{
    pointer-events: none;
  }
`;
const Image = styled.img<{ width: string }>`
    width: ${(props) => props.width};
    height: 131px;
`;

type Props = {
  show: boolean,
  onCancel: () => void,
  image: string,
  title: string,
  description: string,
  earnedPoints: string,
  buttonText: string,
  certification: boolean,
  imageWidth: string,
  isAi: boolean,
} & typeof defaultProps;

const defaultProps = {
  show: false,
  image: '',
  title: '',
  description: '',
  earnedPoints: '',
  buttonText: '',
  certification: false,
  imageWidth: '100px',
};

const CertificationCompletionPopup = function CertificationCompletionPopup(props: Props) {
  const {
    show,
    onCancel,
    image,
    title,
    description,
    earnedPoints,
    buttonText,
    certification,
    imageWidth,
    isAi,
  } = props;
  const fullName = useSelector(selectFullName);
  const salesxloading = useSelector(selectLoading);
  const aiLoading = useSelector(selectAiCertificationLoading);
  const nameLoading = useSelector(selectNameLoading);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(show);
  const nameRef = useRef<InputRef>(null);
  const [checked, setChecked] = useState(false);
  const activeTask = useSelector(selectActiveTask);
  const data = useSelector(selectData);
  const aiCertificationData = useSelector(selectAiCertificationData);
  const certificationData = isAi ? aiCertificationData : data;
  const loading = isAi ? aiLoading : salesxloading;
  const completed = useSelector(selectIsCertificationCompleted);
  const aiCompleted = useSelector(selectIsAiCertificationCompleted);
  const certificationCompleted = isAi ? aiCompleted : completed;

  const flurryObject = useSelector(selectActiveModule);

  const openCertification = () => {
    if (isAi) {
      dispatch(certificationActions.getCertificationData({
        type: 'ai_cert',
      }));
    } else {
      dispatch(certificationActions.getCertificationData({}));
    }
    dispatch(certificationActions.setOpenDrawer(true));
    dispatch(certificationActions.resetPopupData());
  };

  const getPercentage = () => {
    if (certificationCompleted) {
      return 100;
    }
    return Math.floor(certificationData.percentage);
  };

  useEffect(() => {
    if (certificationCompleted && show) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('certification_complete', 0, {
          ...flurryObject,
        }),
      ));
    }
    setShowModal(show);
    if (!show) {
      onCancel();
    }
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    setDisabled(true);
  };

  const formatName = (line: string) => line.trim().toLowerCase().replace(/  +/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  const handleTick = () => {
    const myName = formatName(value);
    if (myName.length) {
      if (isAi) {
        dispatch(certificationActions.editName({
          type: 'ai_cert',
          certificationName: myName,
        }));
      } else {
        dispatch(certificationActions.editName({ certificationName: myName }));
      }
    }
    setOpen(false);
    setDisabled(true);
  };

  const handleEditClick = () => {
    setTimeout(
      () => nameRef.current?.focus(),
      200,
    );
    setOpen(true);
    setValue(fullName || '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!val.length || val === fullName) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setValue(val);
  };

  const handleCheckboxBehavior = () => {
    if (checked) {
      setLocalStorageValue(CERTIFICATION_COMP_POPUPS, 'false');
    }
  };

  const handleButtonClick = () => {
    openCertification();
    setShowModal(false);
    if (certification) {
      dispatch(certificationActions.setCertificationDone(true));
    }
    handleCheckboxBehavior();
    dispatch(UiActions.isShowGilroyGlobalToggle(false));
  };

  const handleCheckBox = () => {
    setChecked(!checked);
  };

  const cancelAction = () => {
    handleCheckboxBehavior();
    onCancel();
  };

  useEffect(() => {
    dispatch(certificationActions.setIsCompletionPopupOpen(showModal));
  }, [showModal]);

  return (
    <Wrapper>
      <Modal
        centered
        width={582}
        open={showModal && !loading}
        closeIcon={<CrossCircle />}
        getContainer=""
        wrapClassName="certification-completion-popup"
        onCancel={cancelAction}
      >
        <Image src={image} alt="success" width={imageWidth} />
        <h4 className="certification-title">{title}</h4>
        <p className={`certification-description ${title.includes('Task') ? 'task-desc' : 'mbC10'}`}>{description}</p>
        {certification && !open && !nameLoading && (
          <CertificationDiv>

            <p className="certification-description name">
              {`"${fullName?.length ? fullName : ''}"`}
              {' '}
              will be the name displayed on your certificate
            </p>

            <span
              role="button"
              tabIndex={0}
              className="certification-description name edit"
              onKeyDown={handleEditClick}
              onClick={handleEditClick}
            >
              Edit
            </span>
          </CertificationDiv>
        )}
        {certification && open && (
          <CertificationDiv>
            <Input
              className="input"
              placeholder="Enter your name"
              ref={nameRef}
              onChange={handleChange}
              maxLength={20}
              value={value}
            />
            <span
              className="cross"
              tabIndex={0}
              role="button"
              onKeyDown={handleClose}
              onClick={handleClose}
            >
              <Cross />
            </span>
            <span
              className={`tick ${disabled ? 'disabled' : ''}`}
              tabIndex={0}
              role="button"
              onKeyDown={handleTick}
              onClick={handleTick}
            >
              <Tick />
            </span>
          </CertificationDiv>
        )}
        <p className={classNames('certification-earned-points showPoints', { mbC10: !certification })}>{HTMLReactParser(earnedPoints)}</p>
        {certification ? null : (
          <ProgressBarDiv>
            <Progress
              percent={getPercentage()}
              size="small"
              success={{ strokeColor: '#1EC252' }}
            />
            <span className="percentage">{`${getPercentage()}%`}</span>
          </ProgressBarDiv>
        )}
        <Button
          role="button"
          tabIndex={0}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
        {!activeTask && !certification
          && (
            <div className="certification-checkbox-div">
              <Checkbox onChange={handleCheckBox} checked={checked} />
              <span className="certification-earned-points show-text">
                Don’t show this again
              </span>
            </div>
          )}
      </Modal>
    </Wrapper>
  );
};
export default CertificationCompletionPopup;

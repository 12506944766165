/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  useEffect, useRef, useState,
} from 'react';
import {
  Badge, Drawer,
} from 'antd';
import styled from 'styled-components';
import CloseAlerts from 'Components/alerts/assets/svg/CloseAlerts';
import LayoutIcon from 'Components/common/assets/svgIcons/LayoutIcon';
import {
  boxShadow, primaryColor, primaryFont, primaryHeadingColor,
  secondaryFont, wMBold, wMedium, maxWidth, retina, maxMobile, maxTablet, smMobile,
} from 'Utils/utilsStyle';

import classNames from 'classnames';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllGroups, selectAllLoading,
  selectCreatingLoader, selectEndedGroups,
  selectGroupsCount, selectNewGroupId, selectNotification, selectSearchedGroups,
  selectGroupCreateSuccessfully, selectDropComplete, selectGroupShared, selectAllGroupsLoaded,
  selectEndedSearchedGroups,
  selectIsDuplication,
  selectSearchedGroupsCount,
  selectGroupDrawerOpen,
} from 'Selector/GroupsSelector';
import { actions } from 'Slice/GroupsSlice';
import { actions as helperActions, actions as flurryActions } from 'Slice/HelperSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SelectedElements, StaffBuyerGroupData } from 'Types/GroupsTypes';
import { PersonProps } from 'Components/common/components/PeopleCardLarge';
import { AddPersonInGroup, CreateGroup } from 'Types/RequestBodies';
import GroupsDisplaySkeleton from 'Components/common/skeleton/GroupsDisplaySkeleton';
import {
  useNavigate, useSearchParams, useLocation,
} from 'react-router-dom';
import NoPersonsFound from 'Components/common/components/NoPersonsFound';
import {
  certificationSmoothBehavior, formatNumberWithCommas, valueToObject,
} from 'Utils/UtilityFunctions';
import {
  COMPANY, GROUP_DETAIL_ROUTE, NO_GROUP_FOUND, PEOPLE_ROUTE,
  translateSkipAreas,
} from 'Utils/Constants';
import { usePrevious } from 'Utils/hooks/usePrevious';
import ShareIcon from 'Components/common/assets/svgIcons/ShareIcon';
import ShareGroupPeopleModal from 'Components/common/modal/ShareGroupPeopleModal';
import BackIcon from 'Components/alerts/assets/svg/BackIcon';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import {
  selectActivetype, selectData, selectHighlight,
  selectIsHighlighted,
  selectIsScroll, selectScrollToId, selectScrollToPage,
} from 'Selector/CertificationSelector';
import {
  selectCompanyDetailFlurry, selectCompanyDetailsCall,
} from 'Selector/CompaniesSelector';
import GroupsDisplayWidget, { GroupsProps } from './GroupsDisplayWidget';
import DropHereToCreateGroup from './DropHereToCreateGroup';
import DropWrapper from './DropWrapper';
import NewGroupWidget from './NewGroupWidget';

const Box = styled.span`
 color:#000;
 margin: 0px 0px 0px 12px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 25px;
 margin-top: 5px;
 height: 70px;
 width: 70px;
 background-color: #fff;
 border-radius: 8px 0px 0px 8px;
 box-shadow: ${boxShadow};
 transition: 0.1s ease all;
 position: fixed;
 top: 150px;
 right: 0px;
 z-index: 1;
 padding: 10px 0px 0px 0px;
cursor: pointer;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    border-radius: 5px 0px 0px 5px;
    height: 55px;
    width: 60px;
    padding: 0px;
  }
  @media all and (max-width: ${maxMobile}) {
    top: 120px;
    height: 35px;
    width: 35px;
  }
 &.drawerBoxOpen {
  right: 530px;
  transition: all .3s;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    right: 380px;
  }
 }
  svg {
    height: 22px;
    width: auto;
    margin-left: 6px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      width: 22px;
    }
    @media all and (max-width: ${maxMobile}) {
      width: 17px;
      height: 14px;
      margin-left: 3px;
    }
    path {
      opacity: 0.7;
    }
  }
  span {
    font-family: ${primaryFont};
    font-size: 12px;
    line-height: 12px;
    font-weight: ${wMedium};
    margin-top: 6px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 9px;
      line-height: 9px;
    }
  }
  .ant-badge-count {
    background:#424B50;
    width: 25px;
    font-size: 9px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    right: 5px;
    top: 4px;
    padding-top: 1px;
    @media all and (max-width: ${maxMobile}) {
      padding: 0px;
      width: 15px;
      height: 14px;
    }
    span{
      height: 14px;
      margin-top: 4px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  .create-group {
    padding: 0px 30px;
    @media all and (max-width: ${maxMobile}) {
      padding: 0px 16px;
    }
  }
`;

const Drop = styled.div`
  padding: 0px 30px;
  @media all and (max-width: ${maxMobile}) {
    display: none;
  }
`;

const Heading = styled.h3`
  font-family: ${secondaryFont};
  font-size: 46px;
  line-height: 44px;
  color: ${primaryHeadingColor};
  font-weight: ${wMBold};
  margin-bottom: 0px;
  display: inline-block;
  &:nth-child(1) {
    padding: 0px 10px 0px 30px;
  }
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 32px;
    line-height: 30px;
  }
  @media all and (max-width:${maxTablet}) {
    &:nth-child(1) {
      padding: 0 5px 0 30px;
    }
    &:nth-child(2) {
      padding: 0px;
    }
  }
  @media all and (max-width: ${maxMobile}){
    padding: 0px 16px;
    font-size: 20px;
    line-height: 22px;
    &:nth-child(1) {
     padding: 0 5px 0 10px;
    }
  }
  &.mb-head {
    margin-bottom: 15px;
  }
`;

const SearchWrapper = styled.div`
 padding: 0px 30px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 width: 100%;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    padding: 0px 30px;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 0 20px 0 10px;
  }
 .heading {
   color: #67696A;
   font-family: ${primaryFont};
   font-size: 18px;
   line-height: 34px;
   font-weight: ${wMedium};
   width: 90px;
   white-space: pre;
   @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 12px;
    line-height: 24px;
    }
  }
  .share-search-div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .share-icon {
      background-color: #fff;
      height: 28px;
      width: 28px;
      min-width: 28px;
      text-align: center;
      border: 1.5px solid #DADADA;
      border-radius: 18px;
      margin-right: 10px;
      cursor: pointer;
      &.active{
        background-color: ${primaryColor};
        border: 1.5px solid ${primaryColor};
      }
      svg {
        height: 26px;
        width: 10px;
        min-width: 10px;
      }
    }
  }
 .search-wrapper {
  display: flex;
  justify-content: flex-end;
  .ant-input-affix-wrapper {
    height: 28px;
    @media all and (max-width: ${smMobile}) {
      width: 160px;
    }
  }
 }
 .disabled{
    opacity:0;
    display:none;
    pointer-events:none;
  }
  .pointer-cursor{
    cursor:pointer
  }
`;

const BrowseAll = styled.span`
 font-size: 16px;
 font-weight: ${wMBold};
 font-family: ${primaryFont};
 color: ${primaryColor};
 margin-right: 23px;
 cursor: pointer;
 @media all and (max-width: ${maxTablet}) {
  font-size: 12px;
 }
`;

const Groups = styled.p`
  font-size: 14px;
  margin-top: 5px;
  font-weight: ${wMedium};
  letter-spacing: 0.3px;
  font-family: ${primaryFont};
  @media all and (max-width:${maxWidth}), ${retina} {
    font-size: 10px;
    margin-top: 0px;
  }
   @media all and (max-width: ${maxMobile}){
    font-size: 7px;
  }
  
`;

const DrawerFooter = styled.div<{ showFooter: boolean }>`
  /* display: flex; */
  display: ${(props) => {
    if (props.showFooter) return 'flex';
    return 'none';
  }};
  align-items: center;
  align-content: center;
  justify-content: 'space-between';
  padding: 15px 30px;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 34px;
  color: ${primaryColor};
  @media all and (max-width: ${maxTablet}) {
    padding: 15px;
  }
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
  .selected-div {
    margin-right: auto;
    .back-icon-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        height: 16px;
        width: 16px;
        min-width: 16px;
      }
      .back-seprator {
        border-left: 1.5px solid #7E7E7E;
        height: 13px;
        display: flex;
        align-items: center;
        padding: 0px 5px;
        margin: 0px 5px;
      }
    }
  }
  .footer-buttons {
    display: flex;
    align-content: center;
     @media all and (max-width: ${maxTablet}) {
      align-items: center;
     }
    .cancel-button-footer {
      padding: 7px 16px;
      font-size: 14px;
      font-weight: ${wMBold};
      line-height: 16px;
      border: 2px solid ${primaryColor};
      border-radius: 17px;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
      @media all and (max-width: ${maxTablet}) {
        padding: 4px 13px;
        font-size: 12px;
        line-height: 12px;
        height: 22px;
        display: inline-block;
        margin-top: 3px;
      }
    }
    .share-button-group {
      @media all and (max-width: ${maxTablet}) {
        padding: 4px 13px;
        font-size: 12px;
        line-height: 12px;
        height: 22px;
        display: inline-block;
      }
    }
  }
  .selected-div-inner {
    .c-pointer {
      @media all and (max-width: ${maxTablet}) {
        font-size: 11px;
      }
    }
  }
`;

type Props = {
  openNewCreate: boolean
  showNew: boolean
  setOpenNew: (a: boolean) => void
  toggleShowNew: (a: boolean) => void
  disableDrop?: boolean
  noButton: boolean
  drag?: boolean
} & typeof defaultProps;

const defaultProps = {
  disableDrop: false,
  drag: false,
};

let intervalId: null | ReturnType<typeof setTimeout> = null;
let intervalIdBottom: null | ReturnType<typeof setTimeout> = null;

const offSetJumps = 100;
const delayInNextJump = 300;

const DrawerPeopleGroup = function DrawerPeopleGroup(props: Props) {
  const {
    openNewCreate, setOpenNew, showNew, toggleShowNew, disableDrop, noButton, drag,
  } = props;

  const [newGroupPerson, setNewGroupPerson] = useState<PersonProps[]>([]);
  const [dropped, setDropped] = useState<boolean>(false);
  const [groups, setGroups] = useState<StaffBuyerGroupData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const prevSearchTerm = usePrevious(searchTerm);
  const visible = useSelector(selectGroupDrawerOpen);
  const [scrollListeners, setScrollListeners] = useState(true);
  const [listenerAdded, setListenerAdded] = useState(false);
  const [offSetToScrollTo, setOffSetToScrollTo] = useState(0);
  const loading = useSelector(selectAllLoading);
  const allGroups = useSelector(selectAllGroups);
  const groupsLoaded = useSelector(selectAllGroupsLoaded);
  const count = useSelector(selectGroupsCount);
  const searchedGroups = useSelector(selectSearchedGroups);
  const newGroupId = useSelector(selectNewGroupId);
  const createLoader = useSelector(selectCreatingLoader);
  const ended = useSelector(selectEndedGroups);
  const endedSearchedGroups = useSelector(selectEndedSearchedGroups);
  const searchedGroupsCount = useSelector(selectSearchedGroupsCount);

  const notificationMsg = useSelector(selectNotification);
  const groupCreateSuccessfully = useSelector(selectGroupCreateSuccessfully);
  const selectedDropComplete = useSelector(selectDropComplete);
  const groupShared = useSelector(selectGroupShared);

  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const groupIdParsed = groupId ? parseInt(groupId, 10) : 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollDivRef = useRef<null | HTMLDivElement>(null);
  const parentDivRef = useRef<null | HTMLDivElement>(null);
  const bottomAnchorDivRef = useRef<null | HTMLDivElement>(null);
  const topAnchorDivRef = useRef<null | HTMLDivElement>(null);
  const scrollInfiniteElement = document.querySelector('.infinite-scroll-group-browse') as HTMLElement;
  const groupCreating = useSelector(selectCreatingLoader);
  const isDuplication = useSelector(selectIsDuplication);

  const [shareGroup, setShareGroup] = useState(false);
  const [showSelectedGroupsText, setshowSelectedGroupsText] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState<StaffBuyerGroupData[]>([]);
  const data = useSelector(selectData);

  const flurryObject = useSelector(selectCompanyDetailFlurry);
  const isCalled = useSelector(selectCompanyDetailsCall);
  const host = window.location.href;
  const formattedGroupCount = formatNumberWithCommas(searchTerm.length > 0
    ? searchedGroupsCount : count);

  const onClickShare = () => {
    if (shareGroup) {
      setSelectedGroups([]);
    } else {
      dispatch(certificationActions.certificationAction({
        type: 'buyer_share',
        source: 'buyer',
        data,
      }));
    }
    setShareGroup(!shareGroup);
  };

  const cancelGroup = () => {
    setNewGroupPerson([]);
    toggleShowNew(false);
  };

  useEffect(() => {
    if ((showNew || newGroupPerson.length > 0) && !createLoader && groupCreateSuccessfully) {
      cancelGroup();
    }
    if (visible && !createLoader && newGroupId && newGroupId !== 0) {
      dispatch(actions.setGroupDrawerOpen(false));
      navigate(`${GROUP_DETAIL_ROUTE}${newGroupId}`);
    }
  }, [createLoader]);

  useEffect(() => {
    if (visible && !isDuplication && !groupCreating && !groupsLoaded) {
      dispatch(actions.getAllGroups({ offset: 0 }));
    }
    if (!visible && (showNew || newGroupPerson.length > 0)) cancelGroup();
  }, [groupCreating]);

  useEffect(() => {
    if (groupCreateSuccessfully && visible && isDuplication && !groupsLoaded) {
      dispatch(actions.getAllGroups({ offset: 0 }));
    }
    if (groupsLoaded) dispatch(actions.setIsDuplication(false));
  }, [groupCreateSuccessfully, groupsLoaded]);

  useEffect(() => {
    if (groupCreateSuccessfully && visible && !isDuplication && newGroupPerson.length) {
      dispatch(actions.getAllGroups({ offset: 0 }));
    }
    if (groupsLoaded) dispatch(actions.setIsDuplication(false));
  }, [groupCreateSuccessfully]);

  useEffect(() => {
    if (groupId) {
      dispatch(actions.setGroupDrawerOpen(false));
    }
  }, [groupId]);

  useEffect(() => {
    if (dropped) {
      setDropped(false);
      dispatch(certificationActions.certificationAction({
        type: 'person_drop',
        source: 'buyer',
        data,
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('buyer_group_create_dnd', 0, {
          host,
        }),
      ));
    }
  }, [dropped, data]);

  useEffect(() => {
    if (!visible && openNewCreate) {
      dispatch(actions.setGroupDrawerOpen(openNewCreate));
    } else if (visible && !openNewCreate && !selectedDropComplete) {
      dispatch(actions.setGroupDrawerOpen(openNewCreate));
    }
  }, [openNewCreate]);

  const calculateDistance = (
    elem: HTMLDivElement,
    mouseX: number,
    mouseY: number,
    range: number[] = [-30, 70],
  ) => {
    const viewportOffset = elem.getBoundingClientRect();
    const elTop = viewportOffset.top;
    const elLeft = viewportOffset.left;
    const elRight = viewportOffset.left + elem.offsetWidth;
    const differenceY = elTop - mouseY;
    if (
      (mouseX > elLeft && mouseX < elRight)
      && ((differenceY > range[0]) && (differenceY < range[1]))
    ) return true;

    return false;
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (topAnchorDivRef?.current) {
      const distance = calculateDistance(topAnchorDivRef.current, e.clientX, e.clientY);
      if (distance) {
        if (intervalId === null) {
          intervalId = setInterval(() => {
            setOffSetToScrollTo(
              (currentOffset) => {
                const newOffSet = currentOffset - offSetJumps;
                if (newOffSet < 0) return 0;
                return newOffSet;
              },
            );
          }, delayInNextJump);
        }
      } else if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    }

    if (bottomAnchorDivRef?.current) {
      const distance = calculateDistance(
        bottomAnchorDivRef.current,
        e.clientX,
        e.clientY,
        [-70, 30],
      );
      if (distance) {
        if (intervalIdBottom === null) {
          intervalIdBottom = setInterval(() => {
            setOffSetToScrollTo(
              (currentOffset) => {
                if (scrollDivRef.current) {
                  const newOffSet = currentOffset + offSetJumps;
                  if (newOffSet >= scrollDivRef.current.offsetTop) {
                    return scrollDivRef.current.offsetTop;
                  }
                  return newOffSet;
                }
                return 0;
              },
            );
          }, delayInNextJump);
        }
      } else if (intervalIdBottom) {
        clearInterval(intervalIdBottom);
        intervalIdBottom = null;
      }
    }
  };

  useEffect(() => {
    if (!visible && openNewCreate) {
      setOpenNew(false);
    }
    if (!visible && offSetToScrollTo !== 0) setOffSetToScrollTo(0);
    if (scrollListeners && visible) {
      if (!listenerAdded) {
        setListenerAdded(true);
        window.addEventListener('mousemove', handleMouseMove);
      }
    } else if (listenerAdded) {
      setListenerAdded(false);
      window.removeEventListener('mousemove', handleMouseMove);
    }
    if (visible) {
      dispatch(actions.getAllGroups({ offset: 0 }));
    }
  }, [visible]);

  const toggle = (isOpenFlurry = false) => {
    dispatch(actions.setSelectedGroups([]));
    if (visible) {
      setShareGroup(false);
      setSelectedGroups([]);
      setSearchTerm('');
    } else {
      dispatch(certificationActions.certificationAction({
        type: 'buyer_icon',
        source: 'buyer',
        data,
      }));
      if (isOpenFlurry) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('buyerGroup_sidePanel_icon_clicked', 0, {
            host,
          }),
        ));
      }
    }
    dispatch(actions.setGroupDrawerOpen(!visible));
    setScrollListeners(visible);
  };

  const handleBrowseAll = () => {
    toggle();
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('peopleDashboard_browseBuyerGroups_clicked', 0, {
        host,
      }),
    ));
  };

  const onSearching = (keywords: string) => {
    setSearchTerm(keywords);
    if (keywords === '') {
      setGroups(allGroups);
      if (prevSearchTerm?.length) {
        dispatch(actions.getAllGroups({ offset: groups.length }));
      }
    } else {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('buyerGroup_sidePanel_search_completed', 0, {
          search_query: keywords.trim(),
          host,
        }),
      ));
      dispatch(actions.getAllGroups({ offset: 0, searchTerm: keywords }));
    }
  };

  const fetchMore = () => {
    if (searchTerm === '' && !ended && showSelectedGroupsText && groups.length) {
      dispatch(actions.getAllGroups({ offset: groups.length }));
    } else if (searchTerm.length) {
      dispatch(actions.getAllGroups({
        searchTerm,
        offset: searchedGroups.length,
      }));
    }
  };

  const createNewGroup = (person: PersonProps) => {
    setNewGroupPerson([person]);
    setDropped(true);
  };

  const createGroup = (groupName: string) => {
    const groupNameStr = groupName.trim().replace(/  +/g, ' ');
    if (groupNameStr !== '') {
      const object: CreateGroup = {
        groupName: groupNameStr,
        displayMessage: location.pathname.includes(COMPANY),
        ...(newGroupPerson.length > 0 && {
          person_data: [{
            type: newGroupPerson[0].executiveType || newGroupPerson[0].personality,
            query: newGroupPerson[0].personName,
            entity_id: newGroupPerson[0].personId,
          }],
        })
        ,
      };
      dispatch(actions.creatingGroup(object));
    } else {
      dispatch(helperActions.setNotification({
        message: 'Group name cannot be empty.',
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  };

  const toggleDrawer = () => {
    dispatch(actions.setGroupDrawerOpen(false));
    if (groupIdParsed) {
      setShareGroup(false);
      setSelectedGroups([]);
      setSearchTerm('');
    }
  };

  const addPersonInGroup = (group: GroupsProps, person: PersonProps) => {
    dispatch(certificationActions.certificationAction({
      type: 'add_person_dnd',
      source: 'buyer',
      data,
    }));
    const object: AddPersonInGroup = {
      group_id: group.groupId || 0,
      person_data: [{
        type: person?.executiveType || person?.personality,
        query: person.personName,
        entity_id: person.personId,
      }],
      groupName: group.groupName || '',
    };
    dispatch(actions.addPersonInGroup(object));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('buyerGroup_Add_person_dnd', 0, {
        host,
      }),
    ));
  };

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    let tCopy = t;
    const bCopy = b;
    const cCopy = c;
    const dCopy = d;
    tCopy /= dCopy / 2;
    if (tCopy < 1) return (cCopy / 2) * tCopy * tCopy + bCopy;
    tCopy -= 1;
    return -(cCopy / 2) * (tCopy * (tCopy - 2) - 1) + bCopy;
  };
  const scrollTo = (element: HTMLElement, to: number, duration: number) => {
    const elementCopy = element;
    const start = elementCopy.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      if (drag) {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration);
        elementCopy.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      }
    };
    animateScroll();
  };

  useEffect(() => {
    if (scrollInfiniteElement && scrollDivRef?.current?.offsetTop) {
      scrollTo(scrollInfiniteElement, offSetToScrollTo, delayInNextJump);
    }
  }, [offSetToScrollTo]);

  const handleDrawerOpen = () => {
    if (drag) {
      dispatch(certificationActions.certificationAction({
        type: 'person_drag',
        source: 'buyer',
        data,
      }));
      dispatch(actions.setGroupDrawerOpen(true));
    }
  };

  const handleDrawerClose = () => {
    if (drag && groupId) {
      dispatch(actions.setGroupDrawerOpen(false));
    }
  };

  const itemClickHandler = (group: StaffBuyerGroupData) => {
    if (selectedGroups.findIndex(({ groupId: Id }) => Id === group.groupId) !== -1) {
      const remainingGroups = [...selectedGroups];
      remainingGroups.splice(remainingGroups.findIndex(
        ({ groupId: Id }) => Id === group.groupId,
      ), 1);
      setSelectedGroups(remainingGroups);
      return;
    }
    setSelectedGroups((prev) => [...prev, group]);
  };

  const selectedGroupHandler = () => {
    if (showSelectedGroupsText) {
      setGroups(selectedGroups);
    } else if (searchTerm !== '') setGroups([]);
    else setGroups(allGroups);
    setshowSelectedGroupsText(searchTerm !== '' ? false : !showSelectedGroupsText);
    setSearchTerm('');
  };

  const cancelHandler = () => {
    setSelectedGroups([]);
    setShareGroup(false);
  };

  useEffect(() => {
    if (!showSelectedGroupsText) {
      if (searchTerm === '') {
        if (selectedGroups.length) {
          setGroups(selectedGroups);
        } else {
          setGroups(allGroups);
          setshowSelectedGroupsText(!showSelectedGroupsText);
        }
      } else if (!selectedGroups.length) {
        setshowSelectedGroupsText(!showSelectedGroupsText);
      }
    }
    const selectedItems: SelectedElements[] = selectedGroups
      .map((group) => ({ name: group.groupName, id: group.groupId }));
    dispatch(actions.setSelectedGroups(selectedItems || []));
  }, [selectedGroups]);

  useEffect(() => {
    if (searchTerm === '') {
      setGroups(showSelectedGroupsText ? allGroups : selectedGroups);
    } else {
      setGroups(searchedGroups);
    }
  }, [allGroups, searchedGroups, searchTerm]);

  useEffect(() => {
    if (groupShared) {
      dispatch(actions.setSelectedGroups([]));
      dispatch(actions.setSelectedExecutives([]));
      setSelectedGroups([]);
      setShareGroup(false);
    }
  }, [groupShared]);

  useEffect(() => {
    const node = document.querySelector('body');
    return visible ? node?.classList.add('body-drawer-toggle') : node?.classList.remove('body-drawer-toggle');
  }, [visible]);

  const isScroll = useSelector(selectIsScroll);
  const isHighlight = useSelector(selectHighlight);
  const currentType = useSelector(selectActivetype);
  const scrollToPage: string = useSelector(selectScrollToPage);
  const scrollToId: string = useSelector(selectScrollToId);
  const isHighlighted = useSelector(selectIsHighlighted);

  useEffect(() => {
    if (isScroll && scrollToPage.includes(`${PEOPLE_ROUTE}`)) {
      certificationSmoothBehavior(scrollToId);
      dispatch(certificationActions.setIsScroll(false));
    }
  }, [isScroll, scrollToId]);

  useEffect(() => {
    if (!isHighlighted && !isScroll && currentType === 'buyer_icon') {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
    }
  }, [isScroll, currentType]);

  return (
    <Wrapper onMouseEnter={() => handleDrawerOpen()}>
      <Box onClick={() => toggle(!visible)} className={classNames('boxDoor', { drawerBoxOpen: visible }, { highlightedSubNavBar: isHighlight && currentType === 'buyer_icon' })}>
        <Badge count={notificationMsg !== 0 ? String(notificationMsg) : 0} overflowCount={99} className="group-badge">
          <LayoutIcon color="#1EC252" />
          <Groups>Groups</Groups>
        </Badge>
      </Box>
      {!noButton && (
        <BrowseAll onClick={handleBrowseAll} className={classNames({ drawerBoxOpen: visible })}>
          browse all groups
        </BrowseAll>
      )}

      <Drawer
        rootClassName="drawer-alerts people-group-drawer"
        placement="right"
        open={visible}
        onClose={() => toggle()}
        width={530}
        extra={(
          <span onClick={() => toggle()} onKeyPress={() => toggle()} tabIndex={0} role="button" aria-label="close" className="close-alerts"><CloseAlerts /></span>
        )}
      >
        <div onMouseLeave={() => handleDrawerClose()}>
          <Heading>Group</Heading>
          <Heading className="mb-head">browser</Heading>
          <Drop className={`create-group ${(disableDrop || showNew || newGroupPerson.length > 0) ? 'disabled' : ''} `}>
            <DropWrapper
              key="NewGroup"
              newGroup
              createNewGroup={createNewGroup}
            >
              <DropHereToCreateGroup />
            </DropWrapper>
          </Drop>
          <SearchWrapper>

            <span className="heading">
              My Groups
              <span className={` ${translateSkipAreas}`}>{` (${formattedGroupCount})`}</span>
            </span>
            <div className="share-search-div">
              <span
                role="button"
                tabIndex={0}
                className={classNames('share-icon', { active: shareGroup })}
                onClick={onClickShare}
                onKeyPress={onClickShare}
              >
                <ShareIcon
                  color={shareGroup ? '#fff' : '#2F363A'}
                />
              </span>
              <AlertsSearch placeholderText="Find Groups…" query={searchTerm} loading={loading} onSearching={onSearching} margin="0px" height="30px" />
            </div>

          </SearchWrapper>
          <div className="h-100">
            <div
              style={{
                height: '30px',
              }}
              ref={topAnchorDivRef}
            />
            <InfiniteScroll
              hasMore={!ended}
              dataLength={groups?.length ?? 0}
              next={fetchMore}
              loader={loading && !ended && !isDuplication
                && <GroupsDisplaySkeleton border />}
              height="auto"
              className={classNames('groups-wrapper infinite-scroll-group-browse', { noGroups: groups.length === 0 && !loading && !(showNew || newGroupPerson.length > 0), footerUnable: shareGroup, shareFooter: shareGroup })}
            >

              {(showNew || newGroupPerson.length > 0)
                && (
                  <NewGroupWidget
                    createGroup={createGroup}
                    cancelGroup={cancelGroup}
                    createLoader={createLoader}
                  />
                )}
              {groups.map((group) => (

                <DropWrapper
                  key={group.groupId}
                  addPersonGroup={addPersonInGroup}
                >
                  <GroupsDisplayWidget
                    onClickHandler={() => {
                      if (shareGroup) { itemClickHandler(group); }
                    }}
                    groupPersona={group.groupPersonality}
                    persons={group.persons}
                    companies={group.companyLogos}
                    groupName={group.groupName}
                    groupId={group.groupId}
                    personsCount={group.personsCount}
                    toggleDrawer={toggleDrawer}
                    shareGroupCheckBox={shareGroup}
                    isChecked={selectedGroups
                      .findIndex(({ groupId: Id }) => Id === group.groupId) !== -1}
                    isShared={group.groupShared}
                    sharedBy={group.sharedByFirstName}
                    sidePanel
                  />
                </DropWrapper>
              ))}
              {groups.length < 1 && ((loading || groupCreating)
                && !(showNew || newGroupPerson.length > 0))
                ? [1, 2, 3, 4].map(() => <GroupsDisplaySkeleton border />) : null}
              {groups.length < 1 && !loading
                && (ended || endedSearchedGroups)
                && !(showNew || newGroupPerson.length > 0)
                ? <NoPersonsFound title={NO_GROUP_FOUND} /> : null}
              <div
                style={{
                  position: 'relative',
                }}
                ref={scrollDivRef}
              />

            </InfiniteScroll>
            <div
              style={{
                height: '30px',
              }}
              ref={bottomAnchorDivRef}
            />
          </div>
          {
            shareGroup ? (
              <DrawerFooter showFooter>
                <div
                  className="selected-div"
                  tabIndex={0}
                  role="button"
                  onClick={selectedGroupHandler}
                  onKeyPress={selectedGroupHandler}
                >
                  {selectedGroups.length
                    ? (
                      <div className="selected-div-inner">
                        {showSelectedGroupsText ? (
                          <span className="c-pointer">{`Selected Groups (${selectedGroups.length})`}</span>
                        ) : (
                          <span className="back-icon-div">
                            <BackIcon />
                            <span className="back-seprator">
                              Back
                            </span>
                          </span>
                        )}
                      </div>
                    ) : null}
                </div>
                <div className={classNames('footer-buttons', { disabled: false })}>
                  <span
                    tabIndex={0}
                    role="button"
                    className="cancel-button-footer"
                    onClick={cancelHandler}
                    onKeyPress={cancelHandler}
                  >
                    Cancel
                  </span>
                  <ShareGroupPeopleModal iconMode={false} />
                </div>
              </DrawerFooter>
            ) : null
          }
        </div>
      </Drawer>
    </Wrapper>
  );
};

DrawerPeopleGroup.defaultProps = defaultProps;
export default DrawerPeopleGroup;

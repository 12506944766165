import { Spin, notification } from 'antd';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as companyActions } from 'Slice/CompaniesSlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import {
  certificationFlag,
  classnameFlag, companyId, getContainerEle, groupId, messageType,
  notificationMessage, protipFlag, rightPlacementFlag, showWarning, accountPlanFlag,
  selectClassName,
  selectMeetingLink,
} from 'Selector/HelperSelector';
import TooltipMessage from 'Components/common/components/TooltipMessage';
import { useNavigate } from 'react-router-dom';
import {
  COMPANY_OVERVIEW_ROUTE, COMPANY_SWOT_ROUTE, DOCUMENTS_ROUTE, GROUP_DETAIL_ROUTE, PROFILE,
} from 'Utils/Constants';
import { selectAccountPlanFailed, selectSWOTRefreshLoading } from 'Selector/CompaniesSelector';

const ToasterNotification = function ToasterNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupid: number = useSelector(groupId);
  const companyid: number = useSelector(companyId) ?? 0;
  const classNameFlag = useSelector(classnameFlag);
  const showWarningFlag = useSelector(showWarning);
  const rightplacementFlag = useSelector(rightPlacementFlag);
  const proTipFlag = useSelector(protipFlag);
  const toasterMessage = useSelector(notificationMessage);
  const certification = useSelector(certificationFlag);
  const accountplanFlag = useSelector(accountPlanFlag);
  const getContainerElement = useSelector(getContainerEle);
  const type = useSelector(messageType);
  const meetLink = useSelector(selectMeetingLink);
  const swotRefreshLoading: boolean = useSelector(selectSWOTRefreshLoading);
  const AccountPlanFailed = useSelector(selectAccountPlanFailed);
  const className = useSelector(selectClassName);

  const onClickNotification = () => {
    if (groupid !== 0) {
      navigate(`${GROUP_DETAIL_ROUTE}${groupid}`);
      notification.destroy();
    } else if (type === 'document') {
      navigate(DOCUMENTS_ROUTE);
      notification.destroy();
    }
  };

  const toaster = () => {
    if (type === 'accountPlan') {
      return (
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            notification.destroy();
            dispatch(companyActions.setShowSAPHistory(!AccountPlanFailed && true));
            dispatch(companyActions.setShowLimitPopupSAP(false));
            navigate(`${COMPANY_OVERVIEW_ROUTE}${companyid}&accountPlan=true`);
            dispatch(companyActions.setCurrentSapCompanyId(companyid));
          }}
          onKeyPress={() => {
            dispatch(companyActions.setShowLimitPopupSAP(false));
            navigate(`${COMPANY_OVERVIEW_ROUTE}${companyid}&accountPlan=true`);
            dispatch(companyActions.setCurrentSapCompanyId(companyid));
          }}
        >
          {toasterMessage}
        </span>
      );
    } if (companyid && !swotRefreshLoading) {
      return (
        <span className="weight-500">
          {toasterMessage}
          {' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              notification.destroy();
              navigate(`${COMPANY_SWOT_ROUTE}${companyid}`);
            }}
            onKeyPress={() => navigate(`${COMPANY_SWOT_ROUTE}${companyid}`)}
            className="underline-text-toaster-Message"
          >
            Click here
          </span>
          {' '}
          to view
        </span>
      );
    } if (companyid && swotRefreshLoading) {
      return (
        <span className="loading-toaster weight-500">
          <Spin />
          {toasterMessage}
        </span>
      );
    } if (type === 'Meeting') {
      return (
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            notification.destroy();
            window.open(meetLink, '_blank');
          }}
          onKeyPress={() => {
            notification.destroy();
            window.open(meetLink, '_blank');
          }}
        >
          {toasterMessage}
        </span>
      );
    }
    return (
      <span>
        {toasterMessage}
      </span>
    );
  };

  const content = () => {
    if (proTipFlag && !certification) {
      return (<TooltipMessage />);
    }
    if (type === 'person') {
      return (
        <span className="proTipText">
          <span className="weight-900">Pro tip:</span>
          <span>
            You can search or select profiles like
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => {
                navigate(PROFILE.TIM_COOK);
              }}
              onKeyPress={() => navigate(PROFILE.TIM_COOK)}
              className="underline-text-toaster-Message"
            >
              Tim Cook,
            </span>
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.MICHAEL_DELL)}
              onKeyPress={() => navigate(PROFILE.MICHAEL_DELL)}
              className="underline-text-toaster-Message"
            >
              Micheal Dell,
            </span>
            {' '}
            or
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.ELON_MUSK)}
              onKeyPress={() => navigate(PROFILE.ELON_MUSK)}
              className="underline-text-toaster-Message"
            >
              Elon Musk
            </span>
          </span>
        </span>
      );
    } if (type === 'company') {
      return (
        <span className="proTipText">
          <span className="weight-900">Pro tip:</span>
          <span>
            You can search or select profiles like
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => {
                navigate(PROFILE.APPLE);
              }}
              onKeyPress={() => navigate(PROFILE.APPLE)}
              className="underline-text-toaster-Message"
            >
              Apple Inc.,
            </span>
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.GOOGLE)}
              onKeyPress={() => navigate(PROFILE.GOOGLE)}
              className="underline-text-toaster-Message"
            >
              Google Inc.,
            </span>
            {' '}
            or
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.MICROSOFT)}
              onKeyPress={() => navigate(PROFILE.MICROSOFT)}
              className="underline-text-toaster-Message"
            >
              Microsoft Corporation
            </span>
          </span>
        </span>
      );
    } if (type === 'group') {
      return (
        <span className="proTipText">
          <span className="weight-900">Pro tip:</span>
          <span>
            You can readily create a new buyer group by clicking the
            {' '}
            <span className="weight-900">“Create Buyer Group“</span>
            {' '}
            button on company profiles. You can search or select company profiles like
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => {
                navigate(PROFILE.APPLE);
              }}
              onKeyPress={() => navigate(PROFILE.APPLE)}
              className="underline-text-toaster-Message"
            >
              Apple Inc.,
            </span>
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.GOOGLE)}
              onKeyPress={() => navigate(PROFILE.GOOGLE)}
              className="underline-text-toaster-Message"
            >
              Google Inc.,
            </span>
            {' '}
            or
            {' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => navigate(PROFILE.MICROSOFT)}
              onKeyPress={() => navigate(PROFILE.MICROSOFT)}
              className="underline-text-toaster-Message"
            >
              Microsoft Corporation
            </span>
          </span>
        </span>
      );
    }
    return '';
  };

  notification.config({
    getContainer: () => (document.querySelector(`.App`) as HTMLElement) || document.body,
  })

  useEffect(() => {
    if (toasterMessage !== '') {
      const toasterPosition = 80;
      notification.destroy();
      notification.open({
        style: {
          zIndex: 1000,
          top: toasterPosition,
        },
        ...(certification && { duration: 20 }),
        ...(accountplanFlag && { duration: 50 }),
        ...(companyid && { duration: 10 }),
        className: classNames(
          className,
          { successNotification: classNameFlag },
          { errorNotification: !classNameFlag },
          { warningNotification: classNameFlag && showWarningFlag },
          { centerMessage: !rightplacementFlag },
          { centerMessageCompany: proTipFlag },
          { moreWidth: companyid },
        ),
        message: toaster(),
        description: content(),
        onClose: () => dispatch(helperActions.setNotification({
          message: '', proTip: false, rightPlacement: false, groupId: 0, classnameFlag: false, className: '',
        })),
        onClick: () => onClickNotification(),
      });
    }
  }, [toasterMessage]);
  return (
    <>
    </>
  );
};
export default ToasterNotification;
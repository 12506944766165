/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  Checkbox, Dropdown, Popover,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { PersonalityTypes, PersonCard } from 'Types/PersonTypes';
import DrawerPersonPersonality from 'Components/person/components/DrawerPersonPersonality';
import DrawerPeopleMoveReason from 'Components/people/components/DrawerPeopleMoveReason';
import {
  getCurrentTime, getLocalStorageValue, isNutanixUser, isXiqUser, valueToObject,
} from 'Utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { COMPANY_OVERVIEW_ROUTE, PERSONALITY_INSIGHTS_ROUTE } from 'Utils/Constants';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { ChangeCategoryData } from 'Types/BuyerGroupsPageTypes';
import { actions } from 'Slice/BuyerGroupsPageSlice';
import {
  secondaryFont, wMBold, maxWidth, retina,
  primaryFont, wMedium, primaryHeadingColor, wELight, minWidthApp, maxTablet, maxMobile, smMobile,
  nutenixColor,
  primaryColor,
  minStandardScreen,
  maxWidth1300,
  noPersonImage,
} from '../../../Utils/utilsStyle';
import DragIcon from '../assets/svgIcons/DragIcon';
import { PersonProps } from './PeopleCardLarge';

const SingleHoloIconSvg = lazy(() => import('../assets/holoSvgIcon/SingleHoloIconSvg'));
const MultiHoloIconSvg = lazy(() => import('../assets/holoSvgIcon/MultiHoloIconSvg'));
const RemoveIcon = lazy(() => import('../assets/svgIcons/RemoveIcon'));

export type WidgetProps = {
  personInfo?: PersonCard,
  fromLargeCard?: boolean,
  persona?: string,
  logo: string,
  ringPadding?: string,
  hidePersonality?: boolean,
  personality?: string,
  diameter?: string,
  diameterSm?: string,
  diameterXsm?: string,
  diameterXxsm?: string,
  margin?: string,
  marginSm?: string,
  imageClassName?: string
  discImage?: boolean;
  wrapperColor?: string;
  showPersonName?: boolean;
  personName?: string;
  showCheckBox?: boolean;
  showRemoveIcon?: boolean;
  addRemovePerson?: (a: boolean, b: number, c: string, d: string) => void
  personId?: number,
  checked?: boolean,
  type?: string,
  companyId?: number,
  openInNewTab?: boolean,
  disableNavigation?: boolean,
  ringPosition?: string,
  ringSize?: string,
  ringPaddingSm?: string,
  isBoxResOne?: boolean,
  personForHover?: boolean;
  personDesignation?: string,
  isPersonPage?: boolean,
  personalityTypesColors?: PersonalityTypes[],
  holoDiameterXxsmHeight?: string,
  holoDiameterXxsmWidth?: string,
  holoDiameterMinHeight?: string,
  holoDiameterMinWidth?: string,
  holoDiameterHeight?: string,
  holoDiameterWidth?: string,
  isAffinitySearch?: boolean;
  firstPerson?: boolean;
  secondPerson?: boolean;
  isPersonMainCard?: boolean;
  showDrawerIcon?: boolean;
  showDropdownToDrag?: boolean;
  categoryData?: ChangeCategoryData;
  isExpended?: boolean;
  inGlobalSearch?: boolean,
  disclessFlag?: boolean
  personaType?: string,
  isPersonStyle?: boolean,
  executiveType?: string,
} & typeof defaultProps;

const defaultProps = {
  holoDiameterHeight: '570px',
  holoDiameterWidth: '551px',
  holoDiameterMinWidth: '115px',
  holoDiameterMinHeight: '105px',
  holoDiameterXxsmHeight: '114px',
  holoDiameterXxsmWidth: '124px',
  ringSize: 'cover',
  ringPosition: 'center',
  personId: 0,
  hidePersonality: true,
  showPersonName: false,
  personality: 'Conscientious',
  ringPadding: '0px',
  diameter: '160px',
  diameterSm: '90px',
  diameterXsm: '160px',
  diameterXxsm: '130px',
  margin: '0px 3px 0px 3px',
  marginSm: '0px 0px 0px 0px',
  imageClassName: '',
  discImage: false,
  checked: false,
  wrapperColor: '#979797',
  personalityTypesColors: [{
    description: '',
    color: '',
    image: '',
    key: '',
    value: '',
    cardColor: '',
  }],
  isPersonMainCard: false,
  persona: '#979797',
  personName: 'Adam',
  showCheckBox: false,
  showRemoveIcon: false,
  type: 'EXECUTIVE',
  companyId: 0,
  ringPaddingSm: '0px',
  addRemovePerson: (a: boolean, b: number, c: string, d: string) => { },
  openInNewTab: false,
  disableNavigation: false,
  isBoxResOne: false,
  personForHover: false,
  personDesignation: '',
  isPersonPage: false,
  fromLargeCard: false,
  isAffinitySearch: false,
  firstPerson: false,
  secondPerson: false,
  personInfo: {},
  showDrawerIcon: false,
  showDropdownToDrag: false,
  categoryData: {} as ChangeCategoryData,
  isExpended: false,
  inGlobalSearch: false,
  disclessFlag: false,
  personaType: '',
  isPersonStyle: false,
  executiveType: '',
};

const Wrapper = styled.div < { isPersonPage: boolean, ringPaddingSm: string, color: string, diameter: string, diameterXsm: string, diameterXxsm: string, diameterSm: string, margin: string, marginSm: string, ringPadding: string, holoDiameterMinHeight: string, holoDiameterMinWidth: string, holoDiameterXxsmHeight: string, holoDiameterXxsmWidth: string }>`
&.searchPersonWidget {
  @media all and (max-width: ${maxWidth1300}) {
  width: ${(props) => props.diameterXsm};
  height: ${(props) => props.diameterXsm};
  min-width: ${(props) => props.diameterXsm};
  }
  .holo-svg {
    @media all and (max-width: ${maxWidth1300}) {
      width: ${(props) => props.holoDiameterMinWidth};
      height: ${(props) => props.holoDiameterMinHeight};
    }
  }
  .image-wrap-placeholder {
    .person-image-holder {
      @media all and (max-width: ${maxWidth1300}) {
        margin: 2px;
      }
      
    }
  }
}
 width: ${(props) => props.diameter};
 height: ${(props) => props.diameter};
 min-width: ${(props) => props.diameter};
 border-radius: 50%;
 position:relative;
 margin: ${(props) => props.margin};
 @media all and (max-width: ${minWidthApp}) {
  width: ${(props) => props.diameterXsm};
  height: ${(props) => props.diameterXsm};
  min-width: ${(props) => props.diameterXsm};
 }

  @media all and (max-width: ${maxTablet}) {
    width: ${(prop) => (prop.isPersonPage ? prop.diameterXsm : prop.diameterXxsm)};
    height: ${(prop) => (prop.isPersonPage ? prop.diameterXsm : prop.diameterXxsm)};
    min-width: ${(prop) => (prop.isPersonPage ? prop.diameterXsm : prop.diameterXxsm)};
  }
 
  ${(props) => (props.isPersonPage
    ? `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -51%);
    width: 82%;
    height: 82%;
    min-width: 82%;
    @media all and (max-width: 1500px) {
      transform: translate(-52%,-51%);
    }
  ` : null)}
  .dragDropdown{
    min-width: 20px;
    display: none;
    position: absolute;
    right: 100%;
    top: -2px;
    cursor: pointer;
    &:hover{
      svg{
        rect{
          fill: #DEF6E6;
        }
        path{
          fill: ${primaryColor};
        }
      }
    }
    @media all and (max-width: ${minStandardScreen}), (max-width: ${minWidthApp}) {
      min-width: 16px;
    }
    svg{
      height: 20px;
      width: 20px;
      @media all and (max-width: ${minStandardScreen}), (max-width: ${minWidthApp}) {
        height: 16px;
        width: 16px;
      }
    }
  }
  .image-wrap-placeholder {
    background: linear-gradient(to right, ${(props) => props.color}, ${(props) => props.color});
    ${(props) => (props.isPersonPage
    ? `
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ` : null)}
}
  .holo-svg {
    @media all and (max-width: ${minWidthApp}) {
      width: ${(props) => props.holoDiameterMinWidth};
      height: ${(props) => props.holoDiameterMinHeight};
    }
    @media all and (max-width: ${maxTablet}) {
      width: ${(props) => props.holoDiameterXxsmWidth};
      height: ${(props) => props.holoDiameterXxsmHeight};
    }
  }

  .person-image-holder {
   height: ${(props) => (props.isPersonPage ? '95%' : '')};
   width: ${(props) => (props.isPersonPage ? '95%' : '')};
   border: 2px solid #fff;
  }
  
  &.isZindex {
    z-index: 1;
    &.isFirstPerson{
      margin-top: 35px;
      @media all and (max-width: ${maxTablet}) {
        margin-top: 40px;
      }
      @media all and (max-width: ${smMobile}) {
        margin-top: 27px;
      }
      .person-image-holder {
        margin: 3.6px;
        @media all and (max-width: ${maxTablet}) {
          margin: 2px;
        }
      }
    }
    &.isSecondPerson {
      left: -34px;
        @media all and (max-width: ${maxMobile}) {
         left: -10px;
        }
       .person-name-heading {
        margin-top: 32px;
        font-weight: ${wMBold};
        font-size: 16px;
        @media all and (max-width: ${maxTablet}) {
          margin-top: 19px;
        }
        @media all and (max-width: ${smMobile}) {
          font-size: 12px;
          margin-top: 12px;
        }
       }
       .person-image-holder {
        margin: 3.6px;
        @media all and (max-width: ${maxTablet}) {
          margin: 2px;
        }
       }
    }
    &.isFirstPerson, &.isSecondPerson {
      @media all and (max-width: ${smMobile}) {
        width: 100px;
        height: 100px;
        min-width: 100px;
        .holo-svg {
          width: 95px;
          height: 90px;
        }
      }
    }
  }

  .ant-checkbox {
    top: 0px;
    right: 10px;
    position: absolute;
    .ant-checkbox-inner {
     width: 25px;
     height: 25px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      width: 20px;
      height: 20px;
     }
     &:after {
       left: 30.5%;
     }
    }
  }
  .remove-btn {
    cursor: pointer;
    top: 0px;
    right: 10px;
    position: absolute;
    svg {
      width: 25px;
    }
  }
  &:hover {
   .personForHoverActive, .span-heading  {
     visibility: visible;
   }
  }
  .personForHoverActive {
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    color: #7E7E7E;
    text-align: center;
    line-height: 26px;
    position: absolute;
    left: 50%;
    bottom: -30px;
    margin-bottom: 6px;
    transform: translateX(-50%);
    width: max-content;
    visibility: hidden;
    @media all and (max-width: ${minWidthApp}) {
     display: none !important;
    }
  }
  .span-heading {
  color: ${primaryHeadingColor};
  font-weight:${wMedium};
  font-family:${primaryFont};
  font-size:14px;
  margin:0;
  transform: translateX(-50%);
  width: max-content;
  visibility: hidden;
  position: absolute;
  left: 35%;
  bottom: -27px;
  margin-bottom: 2px;
  span {
    font-size: 12px;
    font-weight:${wELight};
    font-family:${primaryFont};
  }
   @media all and (max-width: ${minWidthApp}) {
    display: none;
   }
 }
 &.isFromLargeCard {
   .name-wrapper {
     bottom: 5px;
      @media all and (max-width: ${minWidthApp}) {
      bottom: -5px;
     }
      @media all and (max-width: ${maxTablet}) {
      bottom: 0px;
     }
   }
 }
 .isAffinitySearch {
    bottom: 0px;
    border: 4px solid #FFF;
    @media all and (max-width: ${smMobile}) {
      bottom: -12px;
      border: 2px solid #FFF;
    }
 }
 &.isPersonStyle {
  @media all and (max-width: ${minWidthApp}){
    width: 200px;
    height: 200px;
    min-width: 200px;
  }
  .name-wrapper {
    h4 {
      @media all and (max-width: ${minWidthApp}){
        font-size: 14px !important;
      }
    }
    .anticon {
      @media all and (max-width: ${minWidthApp}){
        min-width: 17px;
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }
  .image-wrap-placeholder {
    transform: translate(-51%, -48%);
    width: 85%;
    height: 80%;
    min-width: 85%;
    @media all and (max-width: ${minWidthApp}){
      transform: translate(-51%, -51%);
      width: 80%;
      height: 80%;
      min-width: 80%;
    }
    .person-image {
      margin: 4px;
      @media all and (max-width: ${minWidthApp}){
        margin: 3px;
      }
    }
  }
 }
`;

const Image = styled.div<{ isPersonPage: boolean, image: string, ringSize: string, ringPosition: string, }>`
 border-radius: 50%;
 background-image: url(${(props) => props.image});
 background-color: #fff;
 background-repeat: no-repeat;
 background-position: ${(props) => props.ringPosition};
 background-size: ${(props) => props.ringSize};

  ${(props) => (props.isPersonPage
    ? null
    : `
      margin: 3px;
      height: 96%;
      width: 96%;
      top: 0px; 
      left: 0px;
      transform: unset;
      border: 2px solid #fff;
       @media all and (max-width: ${minWidthApp}) {
        margin: 1.7px;
       }
       @media all and (max-width: ${maxTablet}) {
        margin: 2px;
       }
      `
  )}
`;

const ImageWrapper = styled.div<{ isPersonPage: boolean, color: string }>`
    ${(props) => (props.isPersonPage
    ? null
    : `
      width: 77%;
      height: 77%;
      min-width: 77%;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-54%,-54%); 
      `
  )}
`;

const PersonalityWrapper = styled.div<{ isPersonPage: boolean, color: string, }>`
 min-width: 100px;
 width: fit-content;
 background: linear-gradient(to right, ${(props) => props.color}, ${(props) => props.color});
 position: absolute;
 bottom: 0px;
 left: 50%;
 transform: translate(-55%, -36%);
 border-radius: 30px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    min-width: 90px;
    bottom: 0px;
  }
  @media all and (max-width: ${minWidthApp}){
    min-width: 75px;
  }
  .anticon {
    min-width: 17px;
    color: rgb(255, 255, 255);
    margin-left: 5px;
    margin-right: 15px;
    @media all and (max-width: ${minWidthApp}){
      min-width: 12px;
      font-size: 10px;
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 6px;
    }
  }
  ${(props) => (props.isPersonPage
    ? null
    : `
  h4 {
      font-size: 16px !important;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 14px !important;
        margin: 3px !important;
      }
      @media all and (max-width: ${minWidthApp}){
        font-size: 12px !important;
        margin: 2px 0px 3px 0px !important;
        padding-left: 10px !important;
      }
    }
    `
  )}

  h4 {
    font-size: 14px;
    font-family: ${secondaryFont};
    letter-spacing: -0.35;
    color: #fff;
    margin: 3px 5px;
    padding-left: 10px;
    white-space: nowrap;
    @media all and (max-width: ${maxTablet}) {
      font-size: 10px !important;
    }
  }
 &:hover {
   cursor: pointer;
   /* transform: translate(-50%, -50%) scale(1.1); */
   /* transition: 0.05s all;    */
 }
 
`;

const PersonName = styled.span`
 font-family: ${secondaryFont};
 font-size: 14px;
 font-weight: ${wMBold};
 display: inline-block;
 width: 100%;
 text-align: center;
 margin-top: 8px;
`;

const PeopleWidget = function PeopleWidget(props: WidgetProps) {
  const navigate = useNavigate();
  const {
    persona, logo, personality, diameter, diameterSm, personInfo,
    hidePersonality, margin, marginSm, imageClassName,
    wrapperColor, showPersonName, personName, showCheckBox,
    showRemoveIcon, personId, addRemovePerson, type, checked,
    disableNavigation, personForHover, isPersonMainCard,
    companyId, openInNewTab, ringPadding,
    ringPosition, ringSize, ringPaddingSm, personDesignation,
    isPersonPage, personalityTypesColors,
    fromLargeCard, holoDiameterHeight, holoDiameterWidth,
    holoDiameterXxsmHeight, holoDiameterXxsmWidth,
    holoDiameterMinHeight, holoDiameterMinWidth, isAffinitySearch,
    secondPerson, firstPerson, discImage, isBoxResOne,
    showDrawerIcon, diameterXsm, diameterXxsm, showDropdownToDrag, categoryData,
    isExpended, inGlobalSearch, personaType, isPersonStyle, executiveType, disclessFlag,
  } = props;
  const { archeType } = personInfo;
  const dispatch = useDispatch();
  const {
    prevCategory, prevStage, addReasonPopup,
  } = categoryData;

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState<string>(prevCategory);
  const [stageValue, setStageValue] = useState<string>(prevStage);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [isMovePressed, setIsMovePressed] = useState(false);

  useEffect(() => {
    if (isMovePressed) {
      setIsMovePressed(false);
      return;
    }
    setStageValue(prevStage);
    setCategoryValue(prevCategory);
  }, [isPopoverVisible]);

  const onCategoriesChangeHandler = (e: RadioChangeEvent) => {
    setCategoryValue(e.target.value);
  };
  const onStagesChangeHandler = (e: RadioChangeEvent) => {
    setStageValue(e.target.value);
  };

  const dragPopoverContent = function dragPopoverContent(text: string) {
    return (
      <div>
        {text}
      </div>
    );
  };
  const dragDropdownContent = function dragDropdownContent() {
    if (isPopoverVisible) {
      dispatch(actions.setDragPersonPersonality(personalityTypesColors));
    }
    return (
      <div className="popoverContent">
        <div className="header">
          <span className="avatarWrapper">
            <img src={logo} className="avatar" alt="avatar" />
          </span>
          <p className="actionText">Move to</p>
        </div>
        <div className="radioGroupsWrapper">
          <div className="radioGroup">
            <p className="radioGroupHeading">Categories:</p>
            <Radio.Group onChange={onCategoriesChangeHandler} value={categoryValue}>
              <Radio value="Buyers">Buyers</Radio>
              <Radio value="Champion Buyers">Champion Buyers</Radio>
              <Radio value="Decision Makers">Decision Makers</Radio>
              <Radio value="Technical Buyers">Technical Buyers</Radio>
              <Radio value="Economic Buyers">Economic Buyers</Radio>
            </Radio.Group>
          </div>
          <div className="radioGroup">
            <p className="radioGroupHeading">Types:</p>
            <Radio.Group onChange={onStagesChangeHandler} value={stageValue}>
              <Radio value="Doers">Doers</Radio>
              <Radio value="Coaches">Coaches</Radio>
              <Radio value="Blockers">Blockers</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="buttonsWrapper">
          <span
            tabIndex={0}
            role="button"
            className="move-group-button cancel-button"
            onClick={() => {
              setStageValue(prevStage);
              setCategoryValue(prevCategory);
              setPopoverVisible(false);
              setIsMovePressed(false);
            }}
            onKeyPress={() => setPopoverVisible(false)}
          >
            Cancel
          </span>
          <span
            tabIndex={0}
            role="button"
            className={classNames('move-group-button move-button', { disabled: prevCategory === categoryValue && prevStage === stageValue })}
            onClick={() => {
              addReasonPopup({ personId, categoryData } as PersonProps, categoryValue, stageValue);
              setPopoverVisible(false);
              setIsMovePressed(true);
            }}
            onKeyPress={() => addReasonPopup(
              { personId, categoryData } as PersonProps,
              categoryValue,
              stageValue,
            )}
          >
            Move
          </span>
        </div>
      </div>
    );
  };

  const startTime = getCurrentTime();
  const selection = () => {
    addRemovePerson(!checked, personId, categoryData?.prevCategory, categoryData?.prevStage);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const personIdFromParams = queryParams.get('personId');
  const redirectPersonId = personId === 0 ? personIdFromParams : personId;
  const disclessPersonality = useSelector(selectDisclessPersonality) || getLocalStorageValue('disclessPersonality') === 'true' || disclessFlag;

  const navigateTo = () => {
    const replace = window.location.pathname.includes('similarpeople');
    if (!disableNavigation) {
      let url = '';
      if (personId) url = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${type}`;
      else if (companyId) url = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      if (url?.length) {
        if (openInNewTab) window.open(url);
        else navigate(url, { replace });
      }
    }
  };

  const items = [
    {
      label: (
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            if (isDropdownVisible) {
              setIsDropdownVisible(false);
              window.open(`${PERSONALITY_INSIGHTS_ROUTE}${redirectPersonId as string}&personType=${type}`, '_blank');
            } else {
              navigateTo();
            }
          }}
          onKeyPress={() => {
            if (isDropdownVisible) {
              setIsDropdownVisible(false);
              window.open(`${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${type}`, '_blank');
            } else {
              navigateTo();
            }
          }}
          aria-label="redirectCard"
        >
          Open in New Tab
        </span>),
      key: '1',
    },
  ];

  const handleWidgetClick = () => {
    if (!isXiqUser()) return;
    setIsDropdownVisible((prev) => !prev);
  };

  return (
    <Wrapper
      color={disclessPersonality ? nutenixColor : wrapperColor}
      diameter={diameter}
      diameterXsm={diameterXsm}
      diameterXxsm={diameterXxsm}
      diameterSm={diameterSm}
      margin={margin}
      marginSm={marginSm}
      ringPadding={ringPadding}
      ringPaddingSm={ringPaddingSm}
      isPersonPage={isPersonPage}
      holoDiameterMinHeight={holoDiameterMinHeight}
      holoDiameterMinWidth={holoDiameterMinWidth}
      holoDiameterXxsmHeight={holoDiameterXxsmHeight}
      holoDiameterXxsmWidth={holoDiameterXxsmWidth}
      className={classNames(`person-item-widget person-profile-widget ${personaType}`, {
        isFromLargeCard: fromLargeCard,
        isZindex: isAffinitySearch,
        isFirstPerson: firstPerson,
        isSecondPerson: secondPerson,
        searchPersonWidget: inGlobalSearch,
        isPersonStyle,
      })}
    >
      {(
        personalityTypesColors.length > 1
          ? (
            <Suspense
              fallback={
                <div />
              }
            >
              <MultiHoloIconSvg
                colorOne={personalityTypesColors.length > 0
                  && !disclessPersonality
                  ? personalityTypesColors[0].color : nutenixColor}
                colorTwo={personalityTypesColors.length > 1
                  && !disclessPersonality
                  ? personalityTypesColors[1].color : nutenixColor}
                height={holoDiameterHeight}
                width={holoDiameterWidth}
              />
            </Suspense>
          )
          : (
            <Suspense
              fallback={
                <div />
              }
            >
              <SingleHoloIconSvg
                color={
                  personalityTypesColors.length > 0 && !disclessPersonality
                    ? personalityTypesColors[0].color
                    : nutenixColor
                }
                height={holoDiameterHeight}
                width={holoDiameterWidth}
              />
            </Suspense>
          )
      )}

      <ImageWrapper color={wrapperColor} isPersonPage={isPersonPage} className="image-wrap-placeholder">
        <Dropdown
          menu={{ items }}
          trigger={['contextMenu']}
          visible={isDropdownVisible}
          onOpenChange={handleWidgetClick}
        >
          <Image
            className={`person-image-holder ${imageClassName} ${!(personId === 0) || !(companyId === 0) ? 'c-pointer' : ''}` || 'c-pointer'}
            image={disclessPersonality ? noPersonImage : logo}
            onClick={navigateTo}
            ringPosition={ringPosition}
            ringSize={ringSize}
            isPersonPage={isPersonPage}
          />
        </Dropdown>

      </ImageWrapper>
      {showDrawerIcon && (
        <DrawerPeopleMoveReason personId={personId} showNotes isPersonCard />
      )}
      {showDropdownToDrag && (
        <Popover
          trigger="click"
          placement="bottom"
          content={dragDropdownContent()}
          overlayClassName="dragDropdownContentWrapper"
          getPopupContainer={isExpended
            ? ((e) => e?.closest('.contentWrapperComponent') as HTMLElement)
            : (e: HTMLElement) => e}
          showArrow={false}
          open={isPopoverVisible}
          onOpenChange={setPopoverVisible}
          zIndex={1}
          arrow={false}
        >
          <Popover
            trigger="hover"
            placement="top"
            content={dragPopoverContent('Move to')}
            overlayClassName="dragPopoverContentWrapper"
            arrow={false}
          >
            <span className="dragDropdown">
              <DragIcon />
            </span>
          </Popover>
        </Popover>
      )}
      {
        showCheckBox && (
          <Checkbox
            checked={checked}
            onChange={selection}
          />
        )
      }
      {
        showRemoveIcon && (
          <div className="remove-btn">
            <Suspense
              fallback={
                <div />
              }
            >
              <RemoveIcon />
            </Suspense>
          </div>
        )
      }
      {personDesignation && showPersonName
        ? (
          <h4 className="span-heading">
            {personName}
            {' '}
            <span className="person-designation">{personDesignation}</span>
          </h4>
        ) : showPersonName
          ? (
            <PersonName className={
              classNames('text-truncate person-name-heading', { personForHoverActive: personForHover })
            }
            >
              {personName}
            </PersonName>
          )
          : null}
      {
        (hidePersonality || disclessPersonality)
          ? null : isPersonMainCard
            ? (
              <DrawerPersonPersonality
                personaColor={personInfo?.archeType?.color}
                personality={personInfo?.personName}
                personalityName={personality}
                disk={archeType && archeType?.image ? archeType?.image : ''}
                personMainCardDrawer
                learnMoreText=""
              />

            )
            : (
              (!disclessPersonality) && (
                <PersonalityWrapper
                  isPersonPage={isPersonPage}
                  color={persona}
                  className={classNames('d-flex justify-content-center align-items-center name-wrapper', {
                    isAffinitySearch,
                  })}
                >
                  <h4>
                    {personality}
                  </h4>
                  <ArrowRightOutlined />
                </PersonalityWrapper>
              )
            )
      }
    </Wrapper>
  );
};

PeopleWidget.defaultProps = defaultProps;
export default PeopleWidget;

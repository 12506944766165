import React, {
  useState,
} from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';
import NavigationLinks from 'Components/layout/asider/NavigationLinks';
import BurgerIcon from '../assets/svgIcons/BurgerIcon';

const Wrapper = styled.div`
  position: relative;
  padding: 0px;
`;

const MenuDrawer = function MenuDrawer() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Wrapper>
      <span
        className="drawer-action c-pointer"
        onClick={toggleDrawer}
        onKeyPress={toggleDrawer}
        tabIndex={0}
        role="button"
        aria-label="close"
      >

        {open ? <CloseCircleFilled /> : <BurgerIcon />}
      </span>
      <Drawer
        rootClassName="menu-drawer"
        open={open}
        onClose={toggleDrawer}
        width={244}
        placement="left"
      >
        <NavigationLinks />
      </Drawer>
    </Wrapper>
  );
};

export default MenuDrawer;

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/OnboardingSlice';
import { actions as companyAction } from 'Slice/CompaniesSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
// import {
//   GetCompaniesRequest,
// } from 'Types/RequestBodies';
import {
  UserOnboardingDataResponse,
  UserOnboardingRecommendedData,
} from 'Types/OnboardingTypes';
import { DailyIQCompanies } from 'Types/GlobalTypes';
import { GlobalResponse } from 'Types/ResponseBodies';
import { CompaniesDataResponse } from 'Types/CompaniesTypes';

export function* getUserOnboardingData(dispatched: PayloadAction<object>) {
  try {
    const response: UserOnboardingDataResponse = yield call(
      axios.post,
      API.GET_RECOMMENDED_USER_ONBOARDING,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setUserOnboardingData(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRecommendedPeopleOnboarding(dispatched: PayloadAction<object>) {
  try {
    const response: UserOnboardingRecommendedData = yield call(
      axios.post,
      API.GET_ONBOARDING_RECOMMENDED_PEOPLE,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setRecommendedPeopleOnboarding(response.response[0]));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getDailyIQCompanies(dispatched: PayloadAction<DailyIQCompanies>) {
  try {
    const response: CompaniesDataResponse = yield call(
      axios.post,
      API.GET_DAILYIQ_COMPANIES,
      dispatched?.payload,
    );
    if (response?.status === true) {
      if (response?.response?.companies) {
        const { offset } = dispatched?.payload ?? {};
        if (offset === 0) {
          yield put(companyAction.setSearchCompanies(response));
        } else {
          yield put(companyAction.setCompanies(response));
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* onboardingSaga() {
  yield takeLatest(actions.getUserOnboardingData.type, getUserOnboardingData);
  yield takeLatest(actions.getRecommendedPeopleOnboarding.type, getRecommendedPeopleOnboarding);
  yield takeLatest(actions.getDailyIQCompanies.type, getDailyIQCompanies);
}

import React, {
  useState, useEffect, Suspense, lazy,
} from 'react';
import styled from 'styled-components';
import { Dropdown, Input } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  useNavigate, NavigateFunction, useSearchParams, useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { actions as searchActions } from 'Slice/GlobalSearchResultsSlice';
import { selectRecentSearches, selectLoading as topicsLoader, selectExpiredPopup } from 'Selector/GlobalSearchContentSelector';
import { Section } from 'Types/GlobalSearchContentTypes';
import {
  getLocalStorageValue, isGuestfromEmail, isSalesforce, validateSearchQuery,
} from 'Utils/UtilityFunctions';
import { usePrevious } from 'Utils/hooks/usePrevious';
import {
  COMPANIES, COMPANY, FEED, GROUPDETAILS, INDUSTRY, INDUSTRIES,
  ONBOARDING, PEOPLE, SRI, SEARCH_ROUTE, USER_EXPIRED_ROUTE,
} from 'Utils/Constants';
import { PopUpResponse } from 'Types/PersonTypes';
import classNames from 'classnames';
import { CompliancePopupContent } from 'Types/LoginTypes';
import { useFocus, FocusRefObj } from '../../../Utils/hooks/useFocus';
import { actions as uiActions } from '../../../Slice/UISlice';
import { actions as loginActions } from '../../../Slice/LoginSlice';
import {
  boxShadow,
  primaryBgColor, maxWidth, retina,
  primaryColor, primaryFont, primaryHeadingColor, textBlack, wLight,
  minWidthApp, maxTablet, maxMobile, smMobile,
} from '../../../Utils/utilsStyle';
import { Cross } from '../assets/Cross';
import { GlobalSearchMenu } from './GlobalSearchMenu';
import { actions as companiesActions } from '../../../Slice/CompaniesSlice';

const SearchGlobalIcon = lazy(() => import('Components/common/assets/svgIcons/SearchGlobalIcon'));

type Props = {
  iconMode?: boolean;
  groupId?: number;
  highlighted?: boolean;
};

const SearchWrapper = styled.div`
  position: relative;
  margin-left: 20px;

  &.s-disable {
    background-color: ${primaryBgColor};
    pointer-events: none;
    opacity: 0.5;
    cursor: no-drop;
    input {
      background-color: transparent;
    }
  }

  &.shrink-mode{
    min-width: 516px;
    height: 34px;
    margin-right: 10px;
    @media all and (max-width: ${minWidthApp}) {
      min-width: 400px;
    }
    @media all and (max-width: ${maxTablet}) {
      min-width: 300px;
      height: 28px;
    }
    @media all and (max-width: ${maxWidth}) , ${retina} { 
      margin-right: 8px;
    }
    .ant-input-affix-wrapper {
      @media all and (max-width: ${maxTablet}) {
        height: 100%;
      }
    }
  }
  &.icon-mode{
    width: 30px;
    border: none;
    @media all and (max-width: ${maxTablet}) {
      width: 20px;
    }
    .ant-input-affix-wrapper {
      padding: 0px;
      margin-top: 5px;
      border-radius: 0px;
      border: none;
      @media all and (max-width: ${maxTablet}) {
        top: -3px !important;
      }
      input {
        display: none;
      }
      .ant-input-prefix {
        svg {
          cursor: pointer;
        }
      }
    }
    .ant-input-prefix {
     padding: 0px;
     border-right: none;
     margin-right: 0px;
      svg {
        height: 19px;
      }
    }
    .clear-search {
      display: none;
    }
  }
  &.large-mode{
    min-width: 516px;
    height: 34px;
    margin-right: 10px;
    background:white;
    border-radius: 27px;
    box-shadow: ${boxShadow};
    background-color: transparent;
    border-radius: 6px;
    @media all and (max-width: ${minWidthApp}) {
      min-width: 400px !important;
    }
    @media all and (max-width: ${maxMobile}) {
      min-width: 350px !important;
      box-shadow: none;
      margin-left: 0px;
    }
    @media all and (max-width: ${maxTablet}) {
      height: 28px;
      box-shadow: none;
    }

    @media all and (max-width: ${smMobile}) {
      min-width: 300px !important;
      box-shadow:none;
    }

    @media all and (max-width: ${maxWidth}) , ${retina} {
      min-width: 516px;
      margin-right: 8px;
    }
    
    .input-dropdown-wrapper {
      @media all and (max-width: ${maxMobile}) { 
        position: absolute;
        width: 100%;
      }
    }
    .ant-dropdown {
      display: block;
      width: 100%;
      inset: 0px auto auto 0px !important;
      @media all and (max-width: ${maxTablet}) {
        margin-left: 0px;
      }
    }
  }
  .ant-dropdown {
    top: 10px !important;
    z-index: -1;
    padding-top: 20px;
    background-color: #fff;
    border: 1px solid #C1C1C1;
    border-radius: 6px;
    display: none;
    @media all and (max-width: ${maxTablet}) {
      top: 5px !important;
      border-radius: 6px;
    }
    @media all and (max-width: ${maxMobile}) {
      top: 0px !important;
      left: 0px !important;
      border-radius: 6px;
    }

  }
  

  .search-field {
    border-radius: 6px;
    &.ant-input-affix-wrapper-lg {
      position: absolute;
      z-index:unset;
      transition: none;
      &.ant-dropdown-open {
        @media all and (max-width: ${maxTablet}){
          height: 28px;
        }
        @media all and (max-width: ${maxMobile}){
          top: -5px;
          right: 3px;
          left:10px;
          position: relative;
          width: 100%;
        }
        .ant-dropdown-menu{
          border-radius:6px;
        }
      }
    }
  }

  .clear-search {
    position: absolute;
    right: 2%;
    z-index: 1;
    &.large-mode-positioning{
        top: 9px;
        @media all and (max-width: ${maxTablet}) {
          top: 6px;
        }
        @media all and (max-width: ${maxMobile}) {
          top: 16px;
          right: 0px;
        }
    }
    &.shrink-mode-positioning{
      top:22%;
      right: 2%;
    }
  }
  .disabled{
    opacity:0;
    display:none;
    pointer-events:none;
  }
  .pointer-cursor{
    cursor:pointer
  }
  .ant-input-affix-wrapper {
    padding: 3px 30px 3px 11px;;
     input {
      /* on type style */
       font-weight: ${wLight};
       font-size:16px;
       font-family: ${primaryFont};
       letter-spacing: -0.45px;
       color: ${textBlack};
       &::placeholder {
        /* on placeholder before active */
        font-weight: ${wLight};
        font-size:16px;
        font-family: ${primaryFont};
        letter-spacing: -0.45px;
        color: ${textBlack};
        font-style: normal;
       }
       &:focus::placeholder {
         /* on placeholder after active */
        color: ${primaryHeadingColor};
        font-family: ${primaryFont};
        font-size: 14px;
        font-weight: ${wLight};
        line-height: 18px;
        letter-spacing: -0.45px;
        font-style: normal;
        opacity: 0.5;
       }
       @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 13px;
          margin-top: 3px;
        }
     }
    &:focus {
      border-color: ${primaryColor};
      box-shadow: none;
    }
    &:hover {
      border-color: ${primaryColor};
    }
  }
  .ant-input-prefix {
    margin-right: 6px;
    padding-right: 6px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      margin-right: 4px;
      padding-right: 8px;
    }
    svg {
     width: auto;
     height: 18px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        height: 14px;
      }
    }
  }
  .ant-input {
    &:focus {
      border-color: ${primaryColor};
    }
  }
  .ant-form-input-control,
  .input.ant-input,
  .input.ant-input:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid ${primaryColor};
  }
  &.z-index-1{
    z-index: 0;
  }

  &.global-search-dropdown {
    &.large-mode {
      @media all and (max-width: ${maxMobile}) {
      position: absolute;
      right: 0px;
      top: 5px;
      z-index: 1;
      }
    }
  }
`;

const GlobalSearch = function GlobalSearch(props: Props) {
  const { iconMode, groupId, highlighted } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [isCrossVisible, setIsCrossVisible] = useState(false);
  const [isLargeMode, setIsLargeMode] = useState(false);
  const previousIsLargeMode = usePrevious(isLargeMode);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const recentSearches: Section = useSelector(selectRecentSearches) as Section;
  const expiredPopup: PopUpResponse = useSelector(selectExpiredPopup);
  const topicsLoading: boolean = useSelector(topicsLoader);
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const [dataFromChild, setDataFromChild] = useState<string>('');

  const {
    htmlElementRef: inputRef,
    setFocus: setFocusOnInput,
  }: FocusRefObj = useFocus();

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = validateSearchQuery(e?.target?.value);
    if (searchQuery.length === 0) {
      setSearchText('');
      setIsCrossVisible(false);
      return;
    }
    setSearchText(searchQuery);
    setIsCrossVisible(true);
  };
  const clearSearch = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchText('');
    if (isLargeMode === false) {
      setIsLargeMode(true);
    }
    setIsCrossVisible(false);
    setFocusOnInput();
  };

  const enableLargeMode = () => {
    setIsLargeMode(true);
    setFocusOnInput();
  };

  const disableLargeMode = () => {
    setIsLargeMode(false);
    dispatch(uiActions.isSearchOpen(false));
  };

  const findTerm = (term: string) => {
    if (pathname.includes(term)) {
      return true;
    }
    return false;
  };

  const getScreenName = () => {
    switch (true) {
      case findTerm(FEED):
        return 'Digest';
      case findTerm(COMPANY):
        return 'Company';
      case findTerm(PEOPLE):
        return 'People';
      case findTerm(SRI):
        return 'Sales Index';
      case findTerm(ONBOARDING):
        return 'Onboarding';
      case findTerm(INDUSTRY):
        return 'Industry';
      case findTerm(GROUPDETAILS):
        return 'People';
      case findTerm(COMPANIES):
        return 'Company';
      case findTerm(INDUSTRIES):
        return 'Industry';
      default:
        return 'Search';
    }
  };

  const getScreenNameFun = () => {
    if (getScreenName() === 'Company') {
      dispatch(companiesActions.setSortingType(''));
    }
    dispatch(actions.setScreenName(getScreenName()));
  };

  const triggerSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      let searchQuery = validateSearchQuery(searchText.trim());
      if (searchQuery.length === 0) {
        return;
      }
      dispatch(searchActions.globalSearchResultsResetEmpty());
      dispatch(searchActions.setGlobalSearchLoaded(false));
      disableLargeMode();
      dispatch(actions.updateRecentSearch(searchQuery));
      getScreenNameFun();
      if (!searchQuery.includes('linkedin.com')) {
        searchQuery = searchQuery?.replace(/&/g, '%26');
      }
      let path = '';

      if (!isSalesforce()) {
        path = `${SEARCH_ROUTE}${searchQuery}`;
        if (pathname.includes(GROUPDETAILS) && groupId && groupId > 0) {
          path = `${path}&groupId=${groupId}`;
        }
        navigate(path, {
          state: {
            enableBackButton: true,
            query: searchText,
            previousPath: currentPath,
          },
        });
      } else {
        const section = searchParams.get('extend') || '';
        path = `${SEARCH_ROUTE}${searchQuery}&extend=${section}`;
      }
      navigate(path, { state: { enableBackButton: true, previousPath: currentPath } });
    }
  };

  useEffect(() => {
    if (!previousIsLargeMode && isLargeMode) setFocusOnInput();
  }, [isLargeMode]);

  const triggerSearchIcon = () => {
    dispatch(uiActions.isSearchOpen(true));
  };

  const MODES: Record<string, string> = {
    LARGE: 'LARGE',
    SMALL: 'SMALL',
    ICON: 'ICON',
  };
  const modeCSSMap: Record<string, string> = {
    LARGE: 'large-mode',
    SMALL: 'shrink-mode',
    ICON: 'icon-mode',
  };

  const searchMode: string = (() => {
    if (isLargeMode) {
      return MODES.LARGE;
    }
    if (iconMode) {
      return MODES.ICON;
    }
    return MODES.SMALL;
  })();

  useEffect(() => {
    if (recentSearches.searches && recentSearches.searches.length === 0 && !isGuestfromEmail()) {
      dispatch(actions.globalSearchContent());
      const compliancePopup = getLocalStorageValue('userCompliancePopup');
      if (compliancePopup) {
        const userCompliancePopup = JSON.parse(compliancePopup) as CompliancePopupContent;
        if (userCompliancePopup.complianceId && userCompliancePopup.guidelines) { dispatch(loginActions.setCompliancePopup({ content: userCompliancePopup, visible: true })); }
      } else {
        dispatch(actions.setApiCalled(true));
      }
    }
  }, []);
  useEffect(() => {
    if (expiredPopup.showPlanPopup) {
      navigate(USER_EXPIRED_ROUTE);
    }
  }, [topicsLoading]);

  const query = searchParams.get('query') ?? '';

  useEffect(() => {
    if (validateSearchQuery(query) !== '') {
      setIsLargeMode(false);
      setSearchText(query);
      setIsCrossVisible(true);
    }
  }, [searchParams]);

  // Function to handle the data received from the child
  const handleDataFromChild = (childData: string) => {
    setDataFromChild(childData);
  };

  useEffect(() => {
    setSearchText(dataFromChild);
  }, [dataFromChild]);

  useEffect(() => {
    setSearchText(query);
  }, [query]);

  return (
    <SearchWrapper
      className={`global-search-dropdown ${modeCSSMap[searchMode]} z-index-1 skiptranslate`}
      onClick={enableLargeMode}
    >
      <OutsideClickHandler onOutsideClick={disableLargeMode}>
        <div
          className={classNames('input-dropdown-wrapper', { highlightedComponent: highlighted })}
          onClick={triggerSearchIcon}
          onKeyDown={triggerSearchIcon}
          role="button"
          aria-label="Search"
          tabIndex={0}
        >
          <Dropdown
            overlay={(
              <GlobalSearchMenu getScreenNameFun={getScreenNameFun} onDataPass={handleDataFromChild} />
            )}
            trigger={['click']}
            open={isLargeMode}
            getPopupContainer={() => document.querySelector('.input-dropdown-wrapper') as HTMLElement}
            autoAdjustOverflow={false}
            placement="bottom"
          >
            <Input
              size="large"
              placeholder="Search"
              prefix={(
                <Suspense
                  fallback={
                    <div />
                  }
                >
                  <SearchGlobalIcon />
                </Suspense>
              )}
              className="search-field"
              onChange={onSearchTextChange}
              value={searchText}
              ref={inputRef}
              onKeyDown={triggerSearch}
              maxLength={300}
            />
          </Dropdown>
          <Cross className={`clear-search ${isCrossVisible ? 'pointer-cursor' : 'disabled'}  ${isLargeMode ? 'large-mode-positioning' : 'shrink-mode-positioning'}`} onClick={clearSearch} />
        </div>
      </OutsideClickHandler>
    </SearchWrapper>
  );
};

export default GlobalSearch;

GlobalSearch.defaultProps = {
  iconMode: false,
  groupId: 0,
  highlighted: false,
};

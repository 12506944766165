/* eslint-disable @typescript-eslint/unbound-method */
import {
  compose,
  configureStore,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './SetupReducers';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const injectorsEnhancer = createInjectorsEnhancer({
    createReducer,
    runSaga,
  });

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
      .concat(middlewares),
    enhancers: (defaultEnhancers) => {
      // Ensure type safety for enhancers
      const enhancersArray = Array.isArray(defaultEnhancers)
        ? defaultEnhancers
        : [defaultEnhancers];
      return [...enhancersArray, injectorsEnhancer] as StoreEnhancer[];
    },
  });

  // Make reducers hot reloadable
  if (module.hot) {
    module.hot.accept('./SetupReducers', () => {
      store.replaceReducer(createReducer());
    });
  }

  return store;
}

import styled from 'styled-components';
import { Modal, Popover } from 'antd';
import {
  primaryColor,
  primaryFont, wMedium,
} from 'Utils/utilsStyle';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAiImgLink, selectAiPDFLink, selectImgLink, selectOpenGilroyDrawer, selectPDFLink,
} from 'Selector/CertificationSelector';
import { useRef } from 'react';
import DownloadCertificateSvg from 'Components/alerts/assets/svg/DownloadCertificateSvg';
import { actions as flurryActions } from 'Slice/HelperSlice';
import ShareIcon from 'Components/common/assets/svgIcons/ShareIcon';
import SocialShareIcons from 'Components/share/SocialShareIcons';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import { valueToObject } from '../../../Utils/UtilityFunctions';
import SkeletonCustom from '../skeleton/SkeletonCustom';

const Wrapper = styled.div`
  .view-certificate{
    .ant-modal-content {
      .ant-modal-footer {
        .ant-btn.ant-btn-default{
          padding: 0px;
          min-width: unset;
          margin: 0px;
        }
        .ant-btn {
          :active,:focus {
            background-color: ${primaryColor} !important;
            color: #fff !important;
          }
          font-family: ${primaryFont};
          font-weight: ${wMedium};
          font-size: 16px;
          line-height: 20px;
          height: 38px;
          border: none;
          margin: 0 10px;
          &:hover {
            outline: none;
          }
          .download-btn {
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
            }
          }
        }
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 70px;
      }
      .ant-modal-body{
        padding: 56px 10px 0px 10px;
        height: 590px;
        img{
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

const ModalStyle = styled(Modal) <{ background: string }>`
  .ant-modal-body {
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 88px;
  height: 38px;
  border-radius: 8px;
  background-color: ${primaryColor};
  color: #FFFFFF;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  svg {
    margin-right: 8px;
    min-width: 16px;
  }
`;
const ContentWrapper = styled.div`
`;

type Props = {
  visible?: boolean;
  setVisible?: () => void;
} & typeof defaultProps;

const defaultProps = {
  visible: false,
  setVisible: {},
};

const ButtonText = function ButtonText() {
  return (
    <span
      className="download-btn"
      role="button"
      tabIndex={0}
    >
      <DownloadCertificateSvg />
      Download Certificate
    </span>
  );
};

const ShareButton = function ShareButton() {
  const loading = false;
  const content = (
    <ContentWrapper className="sharingPopoverContentWrapper">
      <h6 className="heading-popover">QuickShare to:</h6>
      {loading
        ? (
          <>
            {[1, 2, 3].map(() => (
              <SkeletonCustom
                width="19px"
                height="19px"
                diameter="50%"
                margin="0 4px"
                marginSm="0px 4px"
              />
            ))}
          </>
        ) : (
          <SocialShareIcons
            certificationPage
            title="xiQ Sales Xelerator Certified"
            entity="article"
          />
        )}
    </ContentWrapper>
  );
  return (
    <Popover
      placement="rightBottom"
      content={content}
      trigger="click"
      getPopupContainer={(e: HTMLElement) => e}
      className="popover-sharing-custom line-block certification-popover popup-certificate popoverSharingCustom"
      arrow={false}
    >
      <div
        className="article-share-icon"
        aria-hidden="true"
        onClick={(e) => {
          e.stopPropagation();
          // downloadProfile(true);
        }}
      >
        <Button>
          <ShareIcon />
          Share
        </Button>
      </div>
    </Popover>
  );
};

const ViewCertificatePopup = function ViewCertificatePopup(props: Props) {
  const {
    visible, setVisible,
  } = props;
  const dispatch = useDispatch();
  const isGilroyDrawer = useSelector(selectOpenGilroyDrawer);
  const salesxImgLink = useSelector(selectImgLink);
  const salesxPdfLink = useSelector(selectPDFLink);
  const aiPdfLink = useSelector(selectAiPDFLink);
  const aiImgLink = useSelector(selectAiImgLink);
  const imgLink = isGilroyDrawer ? aiImgLink : salesxImgLink;
  const pdfLink = isGilroyDrawer ? aiPdfLink : salesxPdfLink;
  const elementRef = useRef<HTMLDivElement>(null);

  const downloadPdf = function downloadPdf() {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('certificate_download', 0),
    ));
    if (pdfLink.length) window.open(pdfLink);
  };

  return (
    <Wrapper ref={elementRef}>
      <ModalStyle
        getContainer={elementRef.current || ''}
        visible={visible}
        onCancel={setVisible}
        centered
        destroyOnClose
        maskClosable
        width={825}
        closeIcon={<div className="cross-icon-x"><CrossCircle /></div>}
        wrapClassName="view-certificate"
        okText={<ButtonText />}
        onOk={() => downloadPdf()}
        cancelText={<ShareButton />}
        background={imgLink}
      />
    </Wrapper>
  );
};

ViewCertificatePopup.defaultProps = defaultProps;
export default ViewCertificatePopup;

import styled from 'styled-components';
import {
  Popover, Button, Divider, Col, Row, Spin, Tabs,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  primaryColor,
  primaryFont, primaryHeadingColor, secondaryFont, wMedium, maxWidth, retina, driver, wMBold,
} from 'Utils/utilsStyle';
// svgs

import classNames from 'classnames';
import { Params, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { allValueInKeyOfArrayExists, smoothBehavior } from 'Utils/UtilityFunctions';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import { SLFilterType } from 'Types/ServiceLinesTypes';
import { Filter } from 'Types/FilterTypes';
import {
  ALERTS, COMPANIES, NODATAFOUND, SLALERTS, SLCOMPANIES,
} from 'Utils/Constants';
import FilterDarkIcon from '../assets/svgIcons/FilterDarkIcon';
import FilterIcon from '../assets/svgIcons/FilterIcon';

import AlertsTriggersCheckBox from '../../alerts/components/AlertsTriggersCheckBox';
import FeedLoadingSkeleton from '../skeleton/FeedFilterSkeleton';
import CompanyWidgetSmall from './CompanyWidgetSmall';
import NoDataFound from './NoDataFound';

const ContentWrapper = styled.div`
  h2 {
    font-size: 28px;
    color: ${primaryHeadingColor};
    font-weight: 800;
    font-family: ${secondaryFont};
    letter-spacing: -0.45px;
    line-height: 1.111 !important;
    margin-bottom: 0px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 20px;
    }
  }
  svg{
     @media all and (max-width: ${maxWidth}) , ${retina} {
      max-width: 16px;
    }
  }
  p {
    font-size: 16px;
    color: #7E7E7E !important;
    font-weight: ${wMedium};
    font-family: ${primaryFont};
    letter-spacing: -0.4px;
    margin: 0;
  }
  .upperPart{
    padding: 20px 23px 0 23px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 20px 16px 0 16px;
    }
    .no-filter{
      color: ${driver} !important;
      text-align: center;
      margin: 10px 0 -10px 0;
    }
  }
  .lowerPart{
    padding: 0 47px 20px 23px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 0 16px 20px 16px;
    }
  }
  .userInfoSection {
    margin-top: 18px;
  }
  .linksSection {
    margin-top: 24px;
    padding: 18px 18px 20px 18px;
    background-color: #F8F8F8;
    border-radius: 0 0 20px 20px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 0 0 10px 10px;
    }
    .heading-panel{
      button{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size:12px;
          padding: 0;
        }
      }
      h6.head{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size:12px;
        }
      }
    }
  }
  .linkMarginTop {
    padding-top: 18px;
  }
  .filter-btn {
    display: inline-block;
    border-radius: 8px;
    background-color: ${primaryColor};
    color: #fff;
    font-family: Roboto;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0px;
  }
  .disabled-btn {
    opacity: 0.7;
    pointer-events: none;
  }
  .filter-tab-with-company {
    margin-top: 20px;
    .ant-tabs-nav {
      margin-bottom: 0px !important;
    }
    .ant-tabs-nav-wrap {
      justify-content: center;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      &:nth-child(1) {
        .ant-tabs-tab-btn {
          border-radius: 8px 0px 0px 8px !important;
        }
      }
      &:nth-child(2) {
        .ant-tabs-tab-btn {
          border-radius: 0px 8px 8px 0px !important;
        }
      }
      .ant-tabs-tab-btn {
        padding: 8px 40px;
      }
    }
    .companySmall-widget-filter {
      margin-bottom: 5px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        height: 75px;
      }
      .image-placeholder {
         width: 153px;
         height: 89%;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width: 122px;
          height: 90%; // it is because of reason
        }
      }
    }
    .margin-to-set {
      margin: 0px 13px 0px 0px;
    }
    .show-company-name {
      font-family: ${secondaryFont};
      font-weight: ${wMBold};
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.45px;
      color: ${primaryHeadingColor};
      margin-left: 5px;
      display: inline-block;
      margin-bottom: 20px;
      margin-top: 5px;
    }
    .grid-checkbox-wrapper {
     .search-wrapper, .ant-input-affix-wrapper {
       height: 35px !important;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        height: 28px !important;
      }
      .cross-icon {
        top: 9px;
        right: 9px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          top: 6px;
          right: 9px;
        }
      }
     }
    }
  }
  .no_data_found{
    height: 200px;
    width: 100%;
    div{
      height: 100%;
    }
  }
  .d-none {
    display:none;
  }
  .category-filter{
    margin-top: 2px;
  }
`;

const LoadingWrapper = styled.div`
  &.iconModeActive {
    .ant-spin-spinning {
      margin-top: 8px;
    }
  }
  height: 38px;
  .ant-spin-spinning {
   margin-top: 2px;
  }
`;

type Props = {
  onlyIcon?: boolean,
  loading?: boolean,
  filters: Filter[],
  selectAllAllert?: boolean,
  selectAllAccount?: boolean,
  iconMode?: boolean,
  onChangeFilters?: (flag: boolean, id: string) => void,
  onClickHandlerAlert?: () => void,
  onClickHandlerAccount?: () => void,
  applyFilterHandler?: () => void,
  disableApplyBtn?: boolean,
} & typeof defaultProps;

const defaultProps = {
  onlyIcon: true,
  loading: false,
  selectAllAllert: false,
  selectAllAccount: false,
  iconMode: false,
  onChangeFilters: (a: true, id: '0') => { console.log(a, id); },
  onClickHandlerAlert: () => { },
  onClickHandlerAccount: () => { },
  applyFilterHandler: () => { },
  disableApplyBtn: false
  ,
};

const FilterPopOverWithCompany = function FilterPopOverWithCompany({
  onlyIcon,
  loading,
  filters,
  selectAllAllert,
  selectAllAccount,
  iconMode,
  onChangeFilters,
  onClickHandlerAlert,
  onClickHandlerAccount,
  applyFilterHandler,
  disableApplyBtn,
}: Props) {
  const { section } = useParams<Params>();
  const [popOverFilter, setPopOverFilter] = useState<Filter[]>();
  const [isNoFilterSelected, setIsNoFilterSelected] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setIsNoFilterSelected(allValueInKeyOfArrayExists(filters, 'isSelected', false));
    setPopOverFilter(filters?.filter(
      (item) => item.filterTypeName === SLCOMPANIES
        || item.filterTypeName === COMPANIES,
    ));
  }, [filters]);

  const applyData = () => {
    applyFilterHandler();
    smoothBehavior();
  };

  const searchCompanies = (searchTerm: string) => {
    const companyFilters = filters?.filter(
      (item) => item.filterTypeName === SLCOMPANIES || item.filterTypeName === COMPANIES,
    );
    if (searchTerm !== '') {
      const tempFilter = companyFilters?.filter(
        (item) => item.filterName.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setPopOverFilter(tempFilter);
    } else {
      setPopOverFilter(companyFilters);
    }
    setSearchText(searchTerm);
  };

  const content = (
    <ContentWrapper>
      <div className="upperPart">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FilterDarkIcon />
            <h2 className="primary-color text-center ml-1">
              Filter
            </h2>
          </div>
          <div className="d-flex align-items-center">
            <Button
              onClick={applyData}
              className={`filter-btn ${(disableApplyBtn || isNoFilterSelected) ? 'disabled-btn' : ''}`}
              aria-label="Profile picture for menu"
            >
              Apply
            </Button>
          </div>
        </div>
        {isNoFilterSelected && <p className="no-filter">Please Select One Filter at Least</p>}
      </div>
      <Tabs defaultActiveKey="1" className="filter-tab-with-company">
        <Tabs.TabPane tab="Sales Triggers" key="1">
          <div className="linksSection">
            <div className="heading-panel">
              <h6 className="head">{`${section === 'social' ? 'Social' : 'Content category alerts:'}`}</h6>
              <span className="unselect" onClick={onClickHandlerAlert} onKeyPress={onClickHandlerAlert} tabIndex={0} role="button" aria-label="unselect all">{selectAllAllert ? 'unselect all' : 'select all'}</span>
            </div>
            <div className="grid-checkbox-wrapper">
              <Row className="category-filter">
                {loading ? (
                  <FeedLoadingSkeleton />
                )
                  : (
                    <Col span={24}>
                      {filters?.filter(
                        (item) => item.filterTypeName === SLALERTS
                          || item.filterTypeName === ALERTS,
                      ).map(
                        (item) => (
                          <AlertsTriggersCheckBox
                            key={item.filterId}
                            title={item.filterName}
                            color={item.filterColor}
                            checked={item.isSelected}
                            onCheckedChange={onChangeFilters}
                            id={item.filterId}
                          />
                        ),
                      )}
                    </Col>
                  )}
              </Row>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Companies" key="2">
          <div className="linksSection">
            <div className="heading-panel">
              <h6 className="head">Companies:</h6>
              {searchText === '' ? <span className="unselect" onClick={onClickHandlerAccount} onKeyPress={onClickHandlerAccount} tabIndex={0} role="button" aria-label="unselect all">{selectAllAccount ? 'unselect all' : 'select all'}</span> : null}
            </div>
            <div className="grid-checkbox-wrapper">
              <AlertsSearch
                placeholderText="Find company..."
                loading={false}
                onSearching={searchCompanies}
                height="50px"
                margin="0px 13px 20px 0px"
              />
              <div className="margin-to-set">
                <Row gutter={12} className="w-100 margin-alert-to-center">
                  {popOverFilter?.map((company: SLFilterType) => (
                    <Col span={12} className={`${company?.filterName.toLowerCase().includes(searchText.toLowerCase()) ? '' : 'd-none'}`}>
                      <CompanyWidgetSmall
                        wrapperClassName="companySmall-widget-filter"
                        logo={company.logo || ''}
                        persona={company.personality || []}
                        triggerColor={company.pulse || []}
                        checked={company.isSelected}
                        selection={onChangeFilters}
                        companyId={company.filterId}
                        backgroundPosition="center 5px"
                        backgroundSize="50%"
                      />
                      <span className="show-company-name">{company.filterName}</span>
                    </Col>
                  ))}
                  {!popOverFilter?.length ? <div className="no_data_found"><NoDataFound title={NODATAFOUND} /></div> : null}
                </Row>
              </div>

            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

    </ContentWrapper>
  );

  return (
    loading
      ? (
        <LoadingWrapper className={classNames('tabs-wrapper justify-content-end loading-tab', { iconModeActive: iconMode })}>
          <div className="tabs-item">
            <Spin />
          </div>
        </LoadingWrapper>
      )
      : (
        <Popover
          overlayClassName="global-filter"
          placement="bottomRight"
          content={content}
          overlayInnerStyle={{
            borderRadius: '20px',
            padding: '0px',
          }}
          trigger="click"
          getPopupContainer={(e: HTMLElement) => e}
          arrow={false}
        >
          <div className={classNames('tabs-wrapper justify-content-end', { iconModeActive: iconMode })}>
            <Button
              className="tabs-item d-flex justify-content-center align-items-center global-tabs-item"
              aria-label="Filter Icon"
            >
              <FilterIcon />
              {
                onlyIcon ? (
                  <>
                    <span
                      className="filter-text"
                      style={{
                        marginLeft: '10px',
                        marginRight: '8px',
                      }}
                    >
                      Filter
                    </span>
                    <Divider type="vertical" />
                    <CaretDownOutlined />
                  </>
                ) : null
              }
            </Button>
          </div>
        </Popover>
      )
  );
};

FilterPopOverWithCompany.defaultProps = defaultProps;
export default FilterPopOverWithCompany;

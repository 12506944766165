/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { PayloadAction } from '@reduxjs/toolkit';
import { actions } from 'Slice/DocumentHubSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  DocumentDeleteRequest,
  DocumentPreviewRequest, DocumentsRequest, GetDocumentDownloadRequest, RenameDocumentRequest,
  UploadRequest,
  ValidateFileNameRequest,
} from 'Types/RequestBodies';
import {
  API, DOCUMENTS_DELETE_SUCCESS,
  DOCUMENT_DELETE_FAIL, DOCUMENT_DELETE_SUCCESS, DOCUMENT_RENAME_FAIL,
  DOCUMENT_RENAME_SUCCESS, FILE_UPLOAD_ERROR, NO_DOCUMENT_DELETED,
} from 'Utils/Constants';
import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import axios from 'Utils/Axios';
import {
  DocumentUploaded, DocumentsResponse, DownloadResponse, UploadResponse,
  UploadStatusResponse,
} from 'Types/DocumentHubTypes';
import { SearchDeleteResponse } from 'Types/ResponseBodies';
import { getCurrentTime, getFileSize, valueToObject } from 'Utils/UtilityFunctions';

export function* getDocuments(dispatched: PayloadAction<DocumentsRequest>) {
  const startTime = getCurrentTime();
  const { inSmartDrawer, ...payload } = dispatched.payload;
  try {
    const response: DocumentsResponse = yield call(
      axios.post,
      API.GET_DOCUMENTS,
      payload,
    );
    if (response?.status === true) {
      const {
        companyLogo, companyName, companyId, chatgptAllowed,
        data, documentCount, isAdmin, userDocumentCount,
        selectedDocumentCount, unselectedDocumentCount,
      } = response.response;
      if (dispatched.payload.offset === 0) {
        yield put(actions.setHubCompany({
          companyLogo,
          companyName,
          companyId,
          chatgptAllowed,
          documentCount,
          isAdmin,
          userDocumentCount,
          selectedDocumentCount,
          unselectedDocumentCount,
          searchTerm: payload?.searchTerm,
        }));
      }
      const params = {
        payload: data,
        inSmartDrawer: inSmartDrawer as boolean,
      };
      yield put(actions.setDocuments(params));
      yield put(flurryActions.callFlurryEvent(
        valueToObject(
          'documentHub_loaded',
          startTime,
          {
            ...dispatched.payload,
          },
        ),
      ));
    } else {
      const params = {
        payload: [],
        inSmartDrawer: inSmartDrawer as boolean,
      };
      yield put(actions.setDocuments(params));
    }
  } catch (err) {
    console.log(err);
  }
}

function* uploadDocumentTime(dispatched: any) {
  let percentCompleted = 0;
  percentCompleted = Math.round(
    ((dispatched.loaded - (dispatched.loaded * 5 / 100)) * 100) / dispatched.total,
  );
  yield put(actions.setDocumentUploadCount(percentCompleted));
}

export function* uploadDocument(dispatched: PayloadAction<UploadRequest>) {
  try {
    const { inSmartDrawer, handleProgress, ...payload } = dispatched.payload;
    const response: UploadResponse = yield call(
      axios.post,
      API.UPLOAD_DOCUMENT,
      payload,
      {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = 0;
          percentCompleted = Math.round(
            ((progressEvent.loaded - (progressEvent.loaded * 5 / 100))
              * 100) / (progressEvent.total || 0),
          );
          if (handleProgress) {
            handleProgress(percentCompleted);
          }
        },
      },
    );
    const file = response?.response?.data[0];
    const isLink = !!dispatched.payload.fileUrl;
    if (response?.status === true) {
      yield put(actions.setDocumentUploadCount(0));
      if (file.uploaded === 1) {
        yield put(actions.setSuccessDocuments({ ...file, isLink }));
      } else {
        yield put(actions.setFailDocuments({
          name: file.fileName,
          size: file.fileSize,
          failReason: FILE_UPLOAD_ERROR,
          type: file.extension,
          isLink,
        }));
      }
      yield put(actions.documentUploaded({ file, inSmartDrawer: inSmartDrawer as boolean }));
    } else {
      yield put(actions.documentUploaded({} as DocumentUploaded));
      const uploadFile = dispatched?.payload.file;
      yield put(actions.setFailDocuments({
        name: uploadFile ? uploadFile?.name : file?.fileName,
        size: uploadFile ? getFileSize(uploadFile?.size) : '',
        failReason: FILE_UPLOAD_ERROR,
        type: file.extension,
        isLink,
      }));
    }
    yield put(actions.removeUploadDocument());
    yield put(actions.setUploadLink(''));
  } catch (err) {
    console.log(err);
  }
}

export function* validateFileName(dispatched: PayloadAction<ValidateFileNameRequest>) {
  try {
    const { name, from } = dispatched.payload;
    const filename = name;
    const response: UploadStatusResponse = yield call(
      axios.post,
      API.UPLOAD_DOCUMENT_STATUS,
      { filename },
    );
    if (response.status) {
      yield put(actions.setUploadDocumentStatus(response?.response));
      if (!from) {
        if (!response.response.filenameAllowed) {
          yield put(actions.setUploadDocumentError(true));
        } else {
          yield put(actions.setUploadDocumentError(false));
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
}
export function* deleteDocument(dispatched: PayloadAction<DocumentDeleteRequest>) {
  const {
    isSelectAll, selectedDocuments, unSelectedDocuments, searchList,
    searchTerm, deleteMultipleFiles, uploadedByMe,
  } = dispatched.payload;

  try {
    const response: SearchDeleteResponse = yield call(
      axios.post,
      API.DELETE_DOCUMENT,
      {
        isSelectAll,
        selectedDocuments,
        unSelectedDocuments,
        searchList,
        ...(searchTerm && { searchTerm }),
        uploadedByMe,
      },
    );
    if (response?.status === true) {
      const searchCountDelete = response?.response?.searchCount;
      const deletedDocumentIds = response?.response?.documentIds;
      const deletedDocumentLength = deletedDocumentIds?.length;
      const data = {
        isSelectAll,
        selectedDocuments,
        unSelectedDocuments,
        searchList,
        deletedDocumentIds,
        searchCountDelete,
      };
      const flurryEventName = deleteMultipleFiles ? `confirmed_deleteMultipleFiles_documentHub_${deletedDocumentLength}` : 'confirmed_deleteSingleFile_documentHub';

      yield put(flurryActions.callFlurryEvent(valueToObject(flurryEventName, 0)));
      yield put(actions.setSearchCount(searchCountDelete));
      yield put(actions.documentDeleted(data));
      yield put(actions.resetSelectedDocuments(false));
      yield put(flurryActions.setNotification({
        message: deletedDocumentLength > 0
          ? `${deletedDocumentLength > 1 ? DOCUMENTS_DELETE_SUCCESS : DOCUMENT_DELETE_SUCCESS}`
          : NO_DOCUMENT_DELETED,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: deletedDocumentLength > 0,
      }));
    } else {
      const id = selectedDocuments?.split(',').map((docId) => parseInt(docId.trim(), 10));
      yield put(actions.documentDeleteFail(id ?? []));
      yield put(flurryActions.setNotification({
        message: DOCUMENT_DELETE_FAIL,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* renameDocument(dispatched: PayloadAction<RenameDocumentRequest>) {
  try {
    const { payload } = dispatched;
    const response: UploadResponse = yield call(
      axios.post,
      API.RENAME_DOCUMENT,
      payload,
    );
    const file = response?.response?.data[0];
    if (response?.status === true) {
      yield put(actions.documentRenamed({ ...file, isSelected: false }));
      yield put(flurryActions.setNotification({
        message: DOCUMENT_RENAME_SUCCESS,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(flurryActions.setNotification({
        message: DOCUMENT_RENAME_FAIL,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* downloadDocument(dispatched: PayloadAction<GetDocumentDownloadRequest>) {
  try {
    const { payload } = dispatched;
    const response: DownloadResponse = yield call(
      axios.post,
      API.DOWNLOAD_DOCUMENT,
      payload,
    );
    if (response?.status === true) {
      const data = {
        documentId: dispatched.payload.id,
        fileURL: response?.response?.fileUrl,
        fileViewUrl: response?.response?.fileViewUrl,
      };
      yield put(actions.setDocumentDownloadURL(data));
      yield put(flurryActions.callFlurryEvent(valueToObject('download_uploadedFile_documentHub', 0)));
    } else {
      const data = {
        documentId: dispatched.payload.id,
        fileURL: '',
        fileViewUrl: '',
      };
      yield put(actions.setDocumentDownloadURL(data));
      yield put(flurryActions.setNotification({
        message: 'Document download failed.',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    const data = {
      documentId: dispatched.payload.id,
      fileURL: '',
      fileViewUrl: '',
    };
    yield put(actions.setDocumentDownloadURL(data));
    console.log(err);
  }
}
export function* previewDocumentAPI(dispatched: PayloadAction<DocumentPreviewRequest>) {
  try {
    const { payload } = dispatched;
    const {
      id, extension, isTag, isSap,
    } = payload;
    const response: DownloadResponse = yield call(
      axios.post,
      API.DOWNLOAD_DOCUMENT,
      { id },
    );
    if (response?.status === true) {
      const data = {
        documentId: dispatched.payload.id,
        fileURL: response?.response?.fileUrl,
        fileViewUrl: response?.response?.fileViewUrl,
      };
      yield put(actions.setDocumentPreviewURL(data));
      yield put(flurryActions.callFlurryEvent(valueToObject(`clicked_uploadedFile_${extension}_${isTag ? 'taggingPopup' : 'documentHub'}`, 0, { ...(isSap && { redirection_from: 'smartAccountPlan' }) })));
    } else {
      const data = {
        documentId: dispatched.payload.id,
        fileURL: '',
        fileViewUrl: '',
      };
      yield put(actions.setDocumentPreviewURL(data));
      yield put(flurryActions.setNotification({
        message: 'Document preview failed.',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    const data = {
      documentId: dispatched.payload.id,
      fileURL: '',
      fileViewUrl: '',
    };
    yield put(actions.setDocumentPreviewURL(data));
    console.log(err);
  }
}

export function* DocumentHubSaga() {
  yield takeLatest(actions.getDocuments.type, getDocuments);
  yield takeLatest(actions.validateFileName.type, validateFileName);
  yield takeLatest(actions.uploadDocument.type, uploadDocument);
  yield takeEvery(actions.deleteDocument.type, deleteDocument);
  yield takeLatest(actions.renameDocument, renameDocument);
  yield takeEvery(actions.getDocumentDownloadURL, downloadDocument);
  yield takeEvery(actions.getPreviewDocument.type, previewDocumentAPI);
}

import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode,
};

const AlertsArticleArea = function AlertsArticleArea(props: Props) {
  const { children } = props;
  return (
    <div className="articles-wrapper-flow" id="articles-wrapper">
      {children}
    </div>
  );
};

export default AlertsArticleArea;

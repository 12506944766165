/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { SriSelectedUser, SRIState } from 'Types/GlobalTypes';
import {
  SRIOverviewData,
  SRIuserOverviewCard,
  SRIUserScore,
  SRIUserScoreContainer,
  SRIMyEngagementData,
  SRICompanyPeopleViewGraph, SRIPersonDetails,
  TeamLeaderBoardData,
  LeaderboardSummary,
  SRICompanyDetails,
  SRIContentDetails,
  SRIFilters,
  SRIUserScoreRequest,
  TeamReadinessTeamDetailsData,
  TeamReadinessBelt,
  SRITeamReadinessTeam,
  TeamReadinessTeamsObject,
  TeamReadinessAvgResponse,
  TeamReadinessDetailsObject,
  SRIFiltersRequest,
  SRIuserOverviewCardData,
  SRIOverviewContainer,
  TeamLeaderBoardDataIn,
  SalesSigmaUserData,
  SalesSigmaFilter,
  SRIScoreResult,
  SalesSigmaData,
  SRILeaderboardRequest,
  ComplianceDashboard,
}
  from 'Types/SRITypes';
import {
  GlobalRequest,
  SRITabFilters,
  SRIOverviewAllRequest,
  UpdatePersonProfileFollow,
  AddToFavoriteRequest,
  FollowCompanyRequest,
  GetComplianceRequest,
  ExportComplianceRequest,
} from 'Types/RequestBodies';
import { AddCompanyPopup, ResponseOfAddCompany } from 'Types/CompaniesTypes';

export const initialState: SRIState = {
  cardLoading: true,
  cardDetails: {} as SRIuserOverviewCard,
  tabs: ['Overview', 'Leaderboard', 'My Engagement', 'Team Readiness'],
  userScoreLoading: false,
  userScore: {} as SRIUserScore,
  userScoreGraph: [],
  myEngagementDetailsCompany: {} as SRIMyEngagementData,
  myEngagementDetailsPeople: {} as SRIMyEngagementData,
  myEngagementDetailsAlert: {} as SRIMyEngagementData,
  overviewCompanyEngagement: [] as SRIOverviewData[],
  overviewPersonEngagement: [] as SRIOverviewData[],
  overviewAlertEngagement: [] as SRIOverviewData[],
  peopleViewGraph: {} as SRICompanyPeopleViewGraph,
  companyViewGraph: {} as SRICompanyPeopleViewGraph,
  articleViewGraph: {} as SRICompanyPeopleViewGraph,
  sessionViewGraph: {} as SRICompanyPeopleViewGraph,
  emailOpenGraph: {} as SRICompanyPeopleViewGraph,
  peopleGraphLoading: false,
  companyGraphLoading: false,
  articleGraphLoading: false,
  sessionGraphLoading: false,
  emailOpenGraphLoading: false,
  teamLeaderBoardLoading: false,
  teamLeaderBoardGraph: [] as TeamLeaderBoardDataIn[],
  leaderBoardSummaryLoading: false,
  leaderBoardSummaryGraph: [] as LeaderboardSummary[],
  filters: {
    start_date: '',
    end_date: '',
    allTime: true,
  } as SRIFilters,
  filtersType: 'All Time',
  teamReadinessLoading: false,
  teamReadinessFiltersLoading: false,
  teamReadinessFilterDetails: [],
  teamReadinessFiltersData: [],
  teamReadinessBeltsLoading: false,
  teamReadinessBeltsGraph: [] as LeaderboardSummary[],
  teamReadinessAvgLoading: false,
  teamReadinessAvgGraph: {} as TeamReadinessAvgResponse,
  teamReadinessRegions: [],
  teamReadinessTeams: {},
  teamReadinessSelectedRegion: '',
  teamReadinessSelectedTeam: {} as SRITeamReadinessTeam,
  teamReadinessTeamRankLoading: false,
  teamReadinessTeamRankData: {} as TeamReadinessDetailsObject,
  leaderBoardSelectedRegion: '',
  leaderBoardSelectedTeam: {} as SRITeamReadinessTeam,
  selectedUserId: null,
  selectedUser: {
    cardDetails: {} as SRIuserOverviewCard,
    userScore: {} as SRIUserScore,
    userScoreGraph: [],
    overviewCompanyEngagement: [] as SRIOverviewData[],
    overviewPersonEngagement: [] as SRIOverviewData[],
    overviewAlertEngagement: [] as SRIOverviewData[],
    peopleViewGraph: {} as SRICompanyPeopleViewGraph,
    companyViewGraph: {} as SRICompanyPeopleViewGraph,
    articleViewGraph: {} as SRICompanyPeopleViewGraph,
    sessionViewGraph: {} as SRICompanyPeopleViewGraph,
    emailOpenGraph: {} as SRICompanyPeopleViewGraph,
  } as SriSelectedUser,
  addCompanyPopup: {
    showPlanPopup: 0,
    popupHeading: '',
    popupText: '',
    contactUsEmail: '',
    callToAction: '',
  } as AddCompanyPopup,
  salesSigmaLoading: false,
  salesSigmaLoaded: false,
  salesSigmaData: null,
  complianceData: [],
  complianceDataLoading: false,
  complianceExportLoading: false,
  overviewCompanyEngagementLoading: false,
  overviewPersonEngagementLoading: false,
  overviewAlertsEngagementLoading: false,
  overviewEngagementFetchMoreCompanyLoading: false,
  overviewEngagementFetchMorePersonLoading: false,
  myEngagementCompanyLoading: false,
  myEngagementPersonLoading: false,
  myEngagementAlertLoading: false,
  myEngagementFetchMoreCompanyLoading: false,
  myEngagementFetchMorePersonLoading: false,
  myEngagementFetchMoreAlertLoading: false,
  hasMoreCompany: true,
  hasMorePeople: true,
  hasMoreAlert: true,
};

const sriSlice = createSlice({
  name: 'sri',
  initialState,
  reducers: {
    getSRICardDetails(state, action: PayloadAction<SRIUserScoreRequest>) {
      state.cardLoading = true;
    },
    setSRICardDetails(state, action: PayloadAction<SRIuserOverviewCardData>) {
      const { payload } = action;
      state.cardLoading = false;
      state.cardDetails = payload?.user_data;
      if (payload.user_id) {
        state.selectedUser.cardDetails = payload?.user_data;
      } else {
        const teamSriPermission = payload?.user_data?.team_sri_permission;
        const topPerformersSriPermission = payload?.user_data?.top_performers_sri_permission;
        let newTabs = state.tabs;
        if (teamSriPermission === false) {
          newTabs = newTabs.filter((tab) => tab !== 'Team Readiness');
        }
        if (topPerformersSriPermission === false) {
          newTabs = newTabs.filter((tab) => tab !== 'Leaderboard');
        }
        state.tabs = newTabs;
      }
    },
    getSRIUserScore(state, action: PayloadAction<SRIUserScoreRequest>) {
      state.userScoreLoading = true;
    },
    setSRIUserScore(state, action: PayloadAction<SRIUserScoreContainer>) {
      const { payload } = action;
      state.userScoreLoading = false;
      const { containers, user_id: userId } = payload;
      if (userId && containers?.length >= 1) {
        const [data] = containers;
        state.selectedUser.userScore = data;
        state.selectedUser.userScoreGraph = data.results.map(
          (item) => ({ name: item.name, y: item.percentage, color: item.color }),
        );
      } else if (containers?.length >= 1) {
        const [data] = containers;
        state.userScore = data;
        state.userScoreGraph = data.results.map(
          (item) => ({ name: item.name, y: item.percentage, color: item.color }),
        );
      }
    },
    getSRIMyEngagement(state, action: PayloadAction<SRIUserScoreRequest>) {
      const { filterType, offset } = action.payload;
      switch (filterType) {
        case 'company':
          if (offset) {
            state.myEngagementFetchMoreCompanyLoading = true;
          } else {
            state.myEngagementCompanyLoading = true;
            state.myEngagementDetailsCompany = {} as SRIMyEngagementData;
          }
          break;
        case 'person':
          if (offset) {
            state.myEngagementFetchMorePersonLoading = true;
          } else {
            state.myEngagementPersonLoading = true;
            state.myEngagementDetailsPeople = {} as SRIMyEngagementData;
          }
          break;
        default:
          if (offset) {
            state.myEngagementFetchMoreAlertLoading = true;
          } else {
            state.myEngagementAlertLoading = true;
            state.myEngagementDetailsAlert = {} as SRIMyEngagementData;
          }
      }
    },
    setSRIMyEngagement(state, action: PayloadAction<SRIMyEngagementData>) {
      const { payload } = action;
      const dataType = payload?.detail_containers[0]?.category;

      if (dataType === 'company') {
        if (Object.keys(state.myEngagementDetailsCompany).length === 0) {
          state.myEngagementDetailsCompany = payload;
          state.hasMorePeople = true;
          state.myEngagementCompanyLoading = false;
        } else {
          const payloadResults = payload?.detail_containers[0]?.results;
          if (!payloadResults.length) {
            state.hasMoreCompany = false;
          }
          const targetContainer = state.myEngagementDetailsCompany.detail_containers[0];
          const stateResults = targetContainer?.results as SRICompanyDetails[];
          stateResults.push(...payloadResults as SRICompanyDetails[]);
          state.myEngagementCompanyLoading = false;
          state.myEngagementFetchMoreCompanyLoading = false;
        }
      } else if (dataType === 'person') {
        if (Object.keys(state.myEngagementDetailsPeople).length === 0) {
          state.myEngagementDetailsPeople = payload;
          state.hasMorePeople = true;
          state.myEngagementPersonLoading = false;
        } else {
          const payloadResults = payload?.detail_containers[0]?.results;
          if (!payloadResults.length) {
            state.hasMorePeople = false;
          }
          const targetContainer = state.myEngagementDetailsPeople.detail_containers[0];
          const stateResults = targetContainer?.results as SRIPersonDetails[];
          stateResults.push(...payloadResults as SRIPersonDetails[]);
          state.myEngagementPersonLoading = false;
          state.myEngagementFetchMorePersonLoading = false;
        }
        state.myEngagementPersonLoading = false;
      } else if (dataType === 'article') {
        if (Object.keys(state.myEngagementDetailsAlert).length === 0) {
          state.myEngagementDetailsAlert = payload;
          state.hasMorePeople = true;
          state.myEngagementAlertLoading = false;
        } else {
          const payloadResults = payload?.detail_containers[0]?.results;
          if (!payloadResults.length) {
            state.hasMoreAlert = false;
          }
          const targetContainer = state.myEngagementDetailsAlert.detail_containers[0];
          const stateResults = targetContainer?.results as SRIContentDetails[];
          stateResults.push(...payloadResults as SRIContentDetails[]);
          state.myEngagementAlertLoading = false;
          state.myEngagementFetchMoreAlertLoading = false;
        }
      }
    },
    getSRIOverviewCompanyViews(state, action: PayloadAction<SRIOverviewAllRequest>) {
      state.companyGraphLoading = true;
    },
    setSRICompanyGraph(state, action: PayloadAction<SRICompanyPeopleViewGraph>) {
      const { payload } = action;
      state.companyGraphLoading = false;
      const userId = payload?.user_id;
      if (userId) {
        state.selectedUser.companyViewGraph = payload;
      } else {
        state.companyViewGraph = payload;
      }
    },
    getSRIOverviewPeopleViews(state, action: PayloadAction<SRIOverviewAllRequest>) {
      state.peopleGraphLoading = true;
    },
    setSRIPeopleGraph(state, action: PayloadAction<SRICompanyPeopleViewGraph>) {
      const { payload } = action;
      state.peopleGraphLoading = false;
      const userId = payload?.user_id;
      if (userId) {
        state.selectedUser.peopleViewGraph = payload;
      } else {
        state.peopleViewGraph = payload;
      }
    },
    getSRIOverviewArticlsRead(state, action: PayloadAction<SRIOverviewAllRequest>) {
      state.articleGraphLoading = true;
    },
    setSRIArticleGraph(state, action: PayloadAction<SRICompanyPeopleViewGraph>) {
      const { payload } = action;
      state.articleGraphLoading = false;
      const userId = payload?.user_id;
      if (userId) {
        state.selectedUser.articleViewGraph = payload;
      } else {
        state.articleViewGraph = payload;
      }
    },
    getSRIOverviewPlatformSessions(state, action: PayloadAction<SRIOverviewAllRequest>) {
      state.sessionGraphLoading = true;
    },
    setSRISessionGraph(state, action: PayloadAction<SRICompanyPeopleViewGraph>) {
      const { payload } = action;
      state.sessionGraphLoading = false;
      const userId = payload?.user_id;
      if (userId) {
        state.selectedUser.sessionViewGraph = payload;
      } else {
        state.sessionViewGraph = payload;
      }
    },
    getSRIOverviewEmailsOpened(state, action: PayloadAction<SRIOverviewAllRequest>) {
      state.emailOpenGraphLoading = true;
    },
    setSRIEmailOpenedGraph(state, action: PayloadAction<SRICompanyPeopleViewGraph>) {
      const { payload } = action;
      state.emailOpenGraphLoading = false;
      const userId = payload?.user_id;
      if (userId) {
        state.selectedUser.emailOpenGraph = payload;
      } else {
        state.emailOpenGraph = payload;
      }
    },
    getSRIOverviewEngagement(state, action: PayloadAction<SRIOverviewAllRequest>) {
      const { filterType, offset } = action.payload;
      switch (filterType) {
        case 'company':
          if (offset) {
            state.overviewEngagementFetchMoreCompanyLoading = true;
          } else {
            state.overviewCompanyEngagementLoading = true;
            state.overviewCompanyEngagement = [] as SRIOverviewData[];
          }
          break;
        case 'person':
          if (offset) {
            state.overviewEngagementFetchMorePersonLoading = true;
          } else {
            state.overviewPersonEngagementLoading = true;
            state.overviewPersonEngagement = [] as SRIOverviewData[];
          }
          break;
        default:
          state.overviewAlertsEngagementLoading = true;
      }
    },
    setSRIOverviewEngagement(state, action: PayloadAction<SRIOverviewContainer>) {
      const { payload } = action;
      const { results } = payload;
      const subCategory = results[0]?.sub_category;

      const isSelectedUser = !!payload.user_id;
      const targetState = isSelectedUser ? state.selectedUser : state;

      switch (subCategory) {
        case 'company':
          if (!targetState.overviewCompanyEngagement.length) {
            targetState.overviewCompanyEngagement = results;
            state.overviewCompanyEngagementLoading = false;
          } else {
            targetState.overviewCompanyEngagement = [
              {
                ...targetState.overviewCompanyEngagement[0],
                results: [
                  ...targetState.overviewCompanyEngagement[0].results,
                  ...results[0].results,
                ],
              },
            ];
            state.overviewCompanyEngagementLoading = false;
            state.overviewEngagementFetchMoreCompanyLoading = false;
          }
          break;
        case 'person':
          if (!targetState.overviewPersonEngagement.length) {
            targetState.overviewPersonEngagement = results;
            state.overviewPersonEngagementLoading = false;
          } else {
            targetState.overviewPersonEngagement = [
              {
                ...targetState.overviewPersonEngagement[0],
                results: [
                  ...targetState.overviewPersonEngagement[0].results,
                  ...results[0].results,
                ],
              },
            ];
            state.overviewPersonEngagementLoading = false;
            state.overviewEngagementFetchMorePersonLoading = false;
          }
          break;
        default:
          targetState.overviewAlertEngagement = results;
          state.overviewAlertsEngagementLoading = false;
      }
    },

    addPersonToNetwork(state, action: PayloadAction<UpdatePersonProfileFollow>) {

    },

    addPersonToNetworkSuccess(state, action: PayloadAction<UpdatePersonProfileFollow>) {
      const { payload } = action;
      const { person_data: personData } = payload;
      if (personData && personData?.length > 0) {
        const { entity_id: personId } = personData[0];
        const peopleDetails = state.myEngagementDetailsPeople.detail_containers[0];
        const peopleData: SRIPersonDetails[] = peopleDetails?.results as SRIPersonDetails[];
        const index = peopleData.findIndex((item) => item.personId === personId);
        if (index > -1) {
          peopleData[index].personAdded = true;
        }
        if (peopleDetails) {
          peopleDetails.results = peopleData;
          state.myEngagementDetailsPeople.detail_containers[0] = peopleDetails;
          // state.myEngagementDetails.detail_containers = state.myEngagementDetails
          //   .detail_containers.map(
          //     (item) => {
          //       if (item.category === 'person') {
          //         return peopleDetails;
          //       }
          //       return item;
          //     },
          //   );
        }
      }
    },
    addPersonToNetworkFailure(state, action: PayloadAction<UpdatePersonProfileFollow>) {
    },

    addCompany(state, action: PayloadAction<FollowCompanyRequest>) {
    },
    addCompanySuccess(state, action: PayloadAction<ResponseOfAddCompany>) {
      const { companyIds, popUpResponse, subcribedCompaniesCount } = action.payload;
      state.addCompanyPopup = popUpResponse;
      const companyId = companyIds[0];
      if (companyId && popUpResponse.showPlanPopup === 0) {
        const companiesDetails = state.myEngagementDetailsCompany.detail_containers[0];
        const companiesData: SRICompanyDetails[] = companiesDetails?.results as SRICompanyDetails[];
        const index = companiesData.findIndex((item) => item.companyId === Number(companyId));
        if (index > -1) {
          companiesData[index].subscribed = true;
        }
        if (companiesDetails) {
          companiesDetails.results = companiesData;
          state.myEngagementDetailsCompany.detail_containers[0] = companiesDetails;
          // state.myEngagementDetails.detail_containers = state.myEngagementDetails
          //   .detail_containers.map(
          //     (item) => {
          //       if (item.category === 'company') {
          //         return companiesDetails;
          //       }
          //       return item;
          //     },
          //   );
        }
      }
    },
    resetSRICompanyPopup(state) {
      if (state.addCompanyPopup) {
        state.addCompanyPopup.showPlanPopup = 0;
        state.addCompanyPopup.popupHeading = '';
        state.addCompanyPopup.popupText = '';
        state.addCompanyPopup.contactUsEmail = '';
        state.addCompanyPopup.callToAction = '';
      }
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId } = action.payload;
      if (articleId && state.myEngagementDetailsAlert.detail_containers?.length) {
        const contentDetails = state.myEngagementDetailsAlert.detail_containers[0];
        if (contentDetails) {
          const contentData: SRIContentDetails[] = contentDetails?.results as SRIContentDetails[];
          const matchedArticles = contentData.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = true;
            });
          }
          contentDetails.results = contentData;
          state.myEngagementDetailsAlert.detail_containers[0] = contentDetails;
          // state.myEngagementDetails.detail_containers = state.myEngagementDetails
          //   .detail_containers.map(
          //     (item) => {
          //       if (item.category === 'article') {
          //         return contentDetails;
          //       }
          //       return item;
          //     },
          //   );
        }
      }
    },

    getTeamLeaderBoardGraph(state, action: PayloadAction<SRITabFilters>) {
      state.teamLeaderBoardLoading = true;
    },
    setTeamLeaderBoardGraph(state, action: PayloadAction<TeamLeaderBoardDataIn[]>) {
      state.teamLeaderBoardLoading = false;
      state.teamLeaderBoardGraph = action.payload;
    },
    getLeaderBoardSummaryGraph(state, action: PayloadAction<SRITabFilters>) {
      state.leaderBoardSummaryLoading = true;
    },
    setLeaderBoardSummaryGraph(state, action: PayloadAction<TeamReadinessBelt[]>) {
      state.leaderBoardSummaryLoading = false;
      state.leaderBoardSummaryGraph = action?.payload;
    },
    setFilters(state, action: PayloadAction<SRIFiltersRequest>) {
      const { payload } = action;
      const startDate = payload.start_date;
      const endDate = payload.end_date;
      const { allTime } = payload;
      state.filters = { start_date: startDate, end_date: endDate, allTime };
      state.filtersType = payload.type;
    },

    getTeamReadinessFiltersDetails(state, action: PayloadAction<GlobalRequest>) {
      state.teamReadinessFiltersLoading = true;
    },
    setTeamReadinessFiltersData(state, action: PayloadAction<TeamReadinessTeamDetailsData>) {
      state.teamReadinessFiltersLoading = false;
      const { payload } = action;
      const { filters_data: filtersData, filters_details: filterDetails } = payload;
      if (filtersData && filtersData?.length > 0) {
        state.teamReadinessFiltersData = filtersData;
        const regions = filtersData.map((item) => (item.region_name));
        let teams = {} as TeamReadinessTeamsObject;
        filtersData.forEach((item) => {
          const obj = { [item.region_name]: item.teams };
          teams = { ...teams, ...obj };
        });
        state.teamReadinessRegions = regions;
        state.teamReadinessTeams = teams;
        const [region]: string[] = regions;
        if (Object.keys(teams).length > 0 && region) {
          state.teamReadinessSelectedRegion = region;
          state.leaderBoardSelectedRegion = region;
          const [team] = teams[region];
          state.teamReadinessSelectedTeam = team;
          state.leaderBoardSelectedTeam = team;
        }
      }
      if (filterDetails && filterDetails?.length > 0) {
        state.teamReadinessFilterDetails = filterDetails;
      }
    },

    setTeamReadinessFilterRegion(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.teamReadinessSelectedRegion = payload;
      const [team] = state.teamReadinessTeams[payload];
      state.teamReadinessSelectedTeam = team;
    },

    setTeamReadinessFilterTeam(state, action: PayloadAction<SRITeamReadinessTeam>) {
      const { payload } = action;
      state.teamReadinessSelectedTeam = payload;
    },

    getTeamReadinessBeltsGraph(state, action: PayloadAction<SRITabFilters>) {
      state.teamReadinessBeltsLoading = true;
    },
    setTeamReadinessBeltsGraph(state, action: PayloadAction<TeamReadinessBelt[]>) {
      state.teamReadinessBeltsLoading = false;
      state.teamReadinessBeltsGraph = action.payload;
    },
    getTeamReadinessAvgGraph(state, action: PayloadAction<SRITabFilters>) {
      state.teamReadinessAvgLoading = true;
    },
    setTeamReadinessAvgGraph(state, action: PayloadAction<TeamReadinessAvgResponse>) {
      state.teamReadinessAvgLoading = false;
      state.teamReadinessAvgGraph = action.payload;
    },

    getTeamReadinessTeamDetails(state, action: PayloadAction<SRITabFilters>) {
      state.teamReadinessTeamRankLoading = true;
    },
    setTeamReadinessTeamDetails(state, action: PayloadAction<TeamReadinessDetailsObject>) {
      state.teamReadinessTeamRankLoading = false;
      const payload = action?.payload;
      if (payload) {
        state.teamReadinessTeamRankData = payload;
      }
    },

    setLeaderBoardFilterRegion(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.leaderBoardSelectedRegion = payload;
      const [team] = state.teamReadinessTeams[payload];
      state.leaderBoardSelectedTeam = team;
    },

    setLeaderBoardFilterTeam(state, action: PayloadAction<SRITeamReadinessTeam>) {
      const { payload } = action;
      state.leaderBoardSelectedTeam = payload;
    },

    setSelectedUserId(state, action: PayloadAction<number | null>) {
      const { payload } = action;

      if (state.selectedUser && state.selectedUserId !== payload) {
        state.selectedUserId = payload;
        state.selectedUser = {
          cardDetails: {} as SRIuserOverviewCard,
          userScore: {} as SRIUserScore,
          userScoreGraph: [],
          // overviewEngagement: [] as SRIOverviewData[],
          overviewCompanyEngagement: [] as SRIOverviewData[],
          overviewPersonEngagement: [] as SRIOverviewData[],
          overviewAlertEngagement: [] as SRIOverviewData[],
          peopleViewGraph: {} as SRICompanyPeopleViewGraph,
          companyViewGraph: {} as SRICompanyPeopleViewGraph,
          articleViewGraph: {} as SRICompanyPeopleViewGraph,
          sessionViewGraph: {} as SRICompanyPeopleViewGraph,
          emailOpenGraph: {} as SRICompanyPeopleViewGraph,
        } as SriSelectedUser;
      } else {
        state.selectedUserId = payload;
      }
    },
    getSalesSigmaForFeed(state, action: PayloadAction<SRILeaderboardRequest>) {
      state.salesSigmaLoading = true;
      state.salesSigmaLoaded = false;
      state.salesSigmaData = null;
    },
    setSalesSigmaForFeed(state, action: PayloadAction<SalesSigmaData>) {
      const { payload } = action;
      state.salesSigmaLoading = false;
      state.salesSigmaLoaded = true;
      state.salesSigmaData = payload;
    },
    getSalesSigmaForFeedFailed(state) {
      state.salesSigmaLoading = false;
      state.salesSigmaLoaded = true;
      state.salesSigmaData = null;
    },

    getComplianceData(state, action: PayloadAction<GetComplianceRequest>) {
      state.complianceDataLoading = true;
    },

    setComplianceData(state, action: PayloadAction<ComplianceDashboard[]>) {
      if (action.payload) {
        state.complianceData = [...state.complianceData, ...action.payload];
      }
      state.complianceDataLoading = false;
    },

    setComplianceDataLoading(state, action: PayloadAction<boolean>) {
      state.complianceDataLoading = action.payload;
    },

    exportComplianceData(state, action: PayloadAction<ExportComplianceRequest>) {
      state.complianceExportLoading = true;
    },

    setExportComplianceData(state, action: PayloadAction<string>) {
      const linkO = document.createElement('a');
      linkO.href = action.payload;
      linkO.dispatchEvent(new MouseEvent('click'));
      state.complianceExportLoading = false;
    },

    setComplianceExportLoading(state, action: PayloadAction<boolean>) {
      state.complianceExportLoading = action.payload;
    },
    addSriTab(state, action: PayloadAction<string>) {
      if (!state.tabs.includes(action.payload)) {
        state.tabs.push(action.payload);
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = sriSlice;

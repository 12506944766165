import styled from 'styled-components';
import {
  Popover, Button, Divider, Col, Row, Spin,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  primaryColor,
  primaryFont, primaryHeadingColor, secondaryFont,
  wMedium, maxWidth, retina, driver, maxTablet, scrollbar,
} from 'Utils/utilsStyle';
// svgs
import { Filter } from 'Types/FilterTypes';
import classNames from 'classnames';
import { Params, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  allValueInKeyOfArrayExists, certificationSmoothBehavior, smoothBehavior,
} from 'Utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import { FEED_NEWS_ROUTE } from 'Utils/Constants';
import FilterDarkIcon from '../assets/svgIcons/FilterDarkIcon';
import FilterIcon from '../assets/svgIcons/FilterIcon';

import AlertsTriggersCheckBox from '../../alerts/components/AlertsTriggersCheckBox';
import FeedLoadingSkeleton from '../skeleton/FeedFilterSkeleton';

const Wrapper = styled.div`
 .active{
    background: #DEF6E6 !important;
    color: #09A43A;
    svg{
      g{
        fill: #09A43A;
      }
    }
 }
`;
const ContentWrapper = styled.div`
  h2 {
    font-size: 28px;
    color: ${primaryHeadingColor};
    font-weight: 800;
    font-family: ${secondaryFont};
    letter-spacing: -0.45px;
    line-height: 1.111 !important;
    margin-bottom: 0px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 20px;
    }
  }
  svg{
     @media all and (max-width: ${maxWidth}) , ${retina} {
      max-width: 22px;
    }
  }
  p {
    font-size: 16px;
    color: #7E7E7E !important;
    font-weight: ${wMedium};
    font-family: ${primaryFont};
    letter-spacing: -0.4px;
    margin: 0;
  }
  .upperPart{
    padding: 20px 23px 0 23px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 20px 16px 0 16px;
    }
    .no_filter{
      color: ${driver} !important;
      text-align: center;
      margin: 10px 0 -10px 0;
    }
  }
  .lowerPart{
    padding: 0 47px 20px 23px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 0 16px 20px 16px;
    }
  }
  .userInfoSection {
    margin-top: 18px;
  }
  .linksSection {
    margin-top: 24px;
    padding: 18px 18px 20px 18px;
    background-color: #F8F8F8;
    border-radius: 0 0 20px 20px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 0 0 10px 10px;
    }
    .heading-panel{
      button{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size:12px;
          padding: 0;
        }
      }
      h6.head{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size:12px;
        }
      }
    }
    .grid-checkbox-wrapper {
      @media all and (max-width: 1400px) {
        max-height: 310px;
        ${scrollbar};
      }
    }
  }
  .linkMarginTop {
    padding-top: 18px;
  }
  .filter-btn {
    display: inline-block;
    border-radius: 8px;
    background-color: ${primaryColor};
    color: #fff;
    font-family: Roboto;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0px;
  }
  .disabled-btn {
    opacity: 0.7;
    pointer-events: none;
  }
`;

const LoadingWrapper = styled.div`
  &.iconModeActive {
    .ant-spin-spinning {
      margin-top: 8px;
    }
  }
  height: 38px;
  @media all and (max-width: ${maxTablet}) {
   height: auto; 
  }
  .ant-spin-spinning {
   margin-top: 2px;
  }
`;

type Props = {
  onlyIcon?: boolean,
  loading?: boolean,
  filters: Filter[],
  selectAll?: boolean,
  iconMode?: boolean,
  iconForBtn?: object,
  isActive?: boolean,
  onChangeFilters?: (flag: boolean, id: string) => void,
  onClickHandler?: () => void,
  applyFilterHandler?: () => void,
  disableApplyBtn?: boolean,
  salesTriggerPopup?: boolean,
  buttonTitle?: string,
  showContent?: boolean,
} & typeof defaultProps;

const defaultProps = {
  onlyIcon: true,
  loading: false,
  selectAll: false,
  iconMode: false,
  isActive: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeFilters: (a: true, id: '0') => { },
  onClickHandler: () => { },
  applyFilterHandler: () => { },
  disableApplyBtn: false,
  salesTriggerPopup: false,
  iconForBtn: <FilterIcon />,
  buttonTitle: 'Filter',
  showContent: true,
};

const FilterPopOver = function FilterPopOver({
  onlyIcon,
  loading,
  filters,
  selectAll,
  iconMode,
  onChangeFilters,
  onClickHandler,
  applyFilterHandler,
  disableApplyBtn,
  isActive,
  salesTriggerPopup,
  iconForBtn,
  buttonTitle,
  showContent,
}: Props) {
  const { section } = useParams<Params>();
  const [isNoFilterSelected, setIsNoFilterSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsNoFilterSelected(allValueInKeyOfArrayExists(filters, 'isSelected', false));
  }, [filters]);

  const applyData = () => {
    applyFilterHandler();
    if (salesTriggerPopup) {
      certificationSmoothBehavior('sales_triggers');
    } else {
      smoothBehavior();
    }
  };

  const data = useSelector(selectData);
  const dispatch = useDispatch();
  const [applyClicked, setApplyClicked] = useState(false);

  const { pathname } = useLocation();

  const handleClick = () => {
    setApplyClicked(true);
    applyData();
    if (pathname.includes(FEED_NEWS_ROUTE)) {
      dispatch(certificationActions.certificationAction({
        type: 'account_sales_tab',
        source: 'account_activity',
        data,
      }));
    }
  };

  useEffect(() => {
    if (applyClicked) {
      setApplyClicked(false);
    }
  }, [applyClicked]);

  const content = (
    <ContentWrapper>
      <div className="upperPart">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FilterDarkIcon />
            <h2 className="primary-color text-center ml-1">
              Filter
            </h2>
          </div>
          <div className="d-flex align-items-center">
            <Button
              onClick={handleClick}
              className={`filter-btn ${(disableApplyBtn || isNoFilterSelected) ? 'disabled-btn' : ''}`}
              aria-label="Profile picture for menu"
            >
              Apply

            </Button>
          </div>
        </div>
        {isNoFilterSelected && <p className="no_filter">Please Select One Filter at Least</p>}
      </div>
      <div className="linksSection">
        <div className="heading-panel">
          <h6 className="head">{`${section === 'social' ? 'Social' : 'Content category alerts:'}`}</h6>
          <span className="unselect" onClick={onClickHandler} onKeyPress={onClickHandler} tabIndex={0} role="button" aria-label="unselect all">{selectAll ? 'unselect all' : 'select all'}</span>
        </div>

        <div className="grid-checkbox-wrapper">
          <Row>
            {loading || applyClicked ? (
              <FeedLoadingSkeleton />
            )
              : (
                <>
                  <Col span={12}>
                    {filters.slice(0, Math.ceil((filters.length / 2))).map(
                      (item) => (
                        <AlertsTriggersCheckBox
                          key={item.filterId}
                          title={item.filterName}
                          color={item.filterColor}
                          checked={item.isSelected}
                          onCheckedChange={onChangeFilters}
                          id={item.filterId}
                        />
                      ),
                    )}
                  </Col>
                  <Col span={12}>
                    {filters.slice(Math.ceil((filters.length) / 2), filters.length).map(
                      (item) => (
                        <AlertsTriggersCheckBox
                          key={item.filterId}
                          title={item.filterName}
                          color={item.filterColor}
                          checked={item.isSelected}
                          onCheckedChange={onChangeFilters}
                          id={item.filterId}
                        />
                      ),
                    )}
                  </Col>
                </>
              )}
          </Row>
        </div>
      </div>
    </ContentWrapper>
  );

  return (
    loading || applyClicked
      ? (
        <LoadingWrapper className={classNames('tabs-wrapper justify-content-end loading-tab', { iconModeActive: iconMode })}>
          <div className="tabs-item spin-tablet">
            <Spin />
          </div>
        </LoadingWrapper>
      )
      : (
        <Wrapper>
          {showContent
            ? (
              <Popover
                overlayClassName="global-filter"
                placement="bottomRight"
                content={content}
                overlayInnerStyle={{
                  borderRadius: '20px',
                  padding: '0px',
                }}
                trigger="click"
                getPopupContainer={(e: HTMLElement) => e}
                arrow={false}
              >
                <div className={classNames(
                  'tabs-wrapper justify-content-end resolution-filter-popover',
                  { iconModeActive: iconMode },
                )}
                >
                  <Button
                    className={classNames(
                      'tabs-item d-flex justify-content-center align-items-center global-tabs-item',
                      { active: isActive },
                    )}
                    aria-label="Filter Icon"
                  >
                    {iconForBtn}
                    {
                      onlyIcon ? (
                        <>
                          <span
                            className="filter-text"
                            style={{
                              marginLeft: '10px',
                              marginRight: '8px',
                            }}
                          >
                            {buttonTitle}
                          </span>
                          <Divider type="vertical" />
                          <CaretDownOutlined />
                        </>
                      ) : null
                    }
                  </Button>
                </div>
              </Popover>
            )
            : (
              <div className={classNames(
                'tabs-wrapper justify-content-end resolution-filter-popover',
                { iconModeActive: iconMode },
              )}
              >
                <Button
                  className={classNames(
                    'tabs-item d-flex justify-content-center align-items-center global-tabs-item',
                    { active: isActive },
                  )}
                  aria-label="Filter Icon"
                >
                  {iconForBtn}
                  {
                    onlyIcon ? (
                      <>
                        <span
                          className="filter-text"
                          style={{
                            marginLeft: '10px',
                            marginRight: '8px',
                          }}
                        >
                          {buttonTitle}
                        </span>
                        <Divider type="vertical" />
                        <CaretDownOutlined />
                      </>
                    ) : null
                  }
                </Button>
              </div>
            )}
        </Wrapper>
      )
  );
};

FilterPopOver.defaultProps = defaultProps;
export default FilterPopOver;

import React from 'react';
import styled from 'styled-components';
import {
  maxTablet,
  primaryColor, primaryFont, sadGilroyFace, wMBold, wMedium,
} from 'Utils/utilsStyle';
import NavBarPrimary from 'Components/layout/headers/NavBarPrimary';
import Logo from 'Components/layout/assets/Logo';

const Wrapper = styled.div`
  .headers-space {
    width: 100%;
    margin-bottom: 0px;
    .container {
      padding: 0px 18px;
      margin: 0px;
      .responsive-logo {
        display: none;
      }
    }
    .header-wrapper {
      .logo-app {
        display: flex;
        align-items: center;
      }
    }
  }
  .content {
    background-color: rgb(249, 248, 246);
    height: calc(100vh - 80px);
    @media all and (max-width: ${maxTablet}) {
      height: calc(100vh - 65px);
    }
    .results {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
      padding: 72px 10px 0px 10px;
      .result-text {
        margin: 0px;
        max-width: 655px;
        color: #001823;
        text-align: center;
        font-family: ${primaryFont};
        font-size: 16px;
        font-weight: ${wMedium};
        letter-spacing: -0.5px;
        span {
          color:${primaryColor};
        }
      }
    } 
  }
`;

const RestrictPage: React.FC = function () {
  return (
    <Wrapper>
      <NavBarPrimary headerClassName="headers-space no-overflow-on-header">
        <div className="logo-app"><Logo /></div>
      </NavBarPrimary>
      <div className="content">
        <div className="results">
          <div className="result-img">
            <img src={sadGilroyFace} alt="" />
          </div>
          <p className="result-text">
            You are attempting to access xiQ Sales Xelerator from an unverified IP address.
            Please ensure you are connecting from one of the approved IP addresses.
            For further assistance, contact your subscription administrator or
            {' '}
            <span>support@xiqinc.com</span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default RestrictPage;

import { Modal } from 'antd';
import classNames from 'classnames';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import AffinitySearchResultItem from 'Components/person/components/AffinitySearchResultItem';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  maxMobile,
  primaryColor, primaryFont, secondaryFont, wLight, wMBold,
} from 'Utils/utilsStyle';
import { actions } from 'Slice/GroupsSlice';
import {
  selectExecutiveCount,
  selectExecutivesEnded, selectExecutivesLoading, selectExecutivesPage,
  selectExecutivesSearched, selectGroupShared,
  selectOrganizationExecutiveForGilroy, selectOrganizationExecutives,
  selectOrganizationName, selectSearchedCount,
  selectSearchedEnded, selectSearchedPage, selectSelectedGroups,
} from 'Selector/GroupsSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Executive } from 'Types/PersonTypes';
import { NODATAFOUND, translateSkipAreas } from 'Utils/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import ShareIcon from '../assets/svgIcons/ShareIcon';
import AffinitySearchResultItemSkeleton from '../skeleton/AffinitySearchResultItemSkeleton';
import ShareGroupConformationModal from './ShareGroupConformationModal';
import NoDataFound from '../components/NoDataFound';
import SkeletonCustom from '../skeleton/SkeletonCustom';

const Wrapper = styled.div`
.share-button-group {
  &.groupPageButton {
    height: 40px;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    @media all and (max-width: ${maxMobile}) {
      padding: 10px 12px 2px 12px;
    }
    .icon-mode-text, .seperator {
      @media all and (max-width: ${maxMobile}) {
        display: none;
      }
    }
  }
  background-color: ${primaryColor};
  height: 30px;
  border-radius: 17px;
  padding: 7px 16px;
  font-family: ${secondaryFont};
  color: #fff;
  font-weight: ${wMBold};
  font-size: 14px;
  line-height: 17.09px;
  .icon-mode {
    display: inline-flex;
    align-content: center;
    .icon-mode-text {
      font-weight: ${wLight};
      font-family: ${primaryFont};
      font-size: 14px;
    }
    .seperator {
      height: 18px;
      border-left: 1.5px solid #fff;
      margin: 0px 10px;
    }
    .share-icon {
      svg {
        height: 18px;
        width: 13px;
        min-width: 13px;
      }
    }
  }
}
`;

const Button = styled.span`
  cursor: pointer;
`;

type Props = {
  iconMode?: boolean,
  onClickHandler?: (a: boolean) => void,
  onCancelHandler?: (a: boolean) => void,
  isDetailPage?: boolean
} & typeof defaultProps;

const defaultProps = {
  iconMode: false,
  onClickHandler: () => { },
  onCancelHandler: () => { },
  isDetailPage: false,
};

const ShareGroupPeopleModal = function ShareGroupPeopleModal(props: Props) {
  const {
    iconMode, onClickHandler, onCancelHandler, isDetailPage,
  } = props;
  const dispatch = useDispatch();
  const loading = useSelector(selectExecutivesLoading);
  const allExecutives = useSelector(selectOrganizationExecutives);
  const totalCount = useSelector(selectExecutiveCount);
  const searchedExecutives = useSelector(selectExecutivesSearched);
  const organizationName = useSelector(selectOrganizationName);

  const executivesPage = useSelector(selectExecutivesPage);
  const searchedPage = useSelector(selectSearchedPage);
  const searchedCount = useSelector(selectSearchedCount);

  const executivesEnded = useSelector(selectExecutivesEnded);
  const searchedEnded = useSelector(selectSearchedEnded);

  const selectedGroups = useSelector(selectSelectedGroups);
  const groupShared = useSelector(selectGroupShared);
  const organizationExecutiveForGilroy = useSelector(selectOrganizationExecutiveForGilroy);

  const [executivesToDisplay, setExecutivesToDisplay] = useState<Executive[]>([]);
  const [selectedExecutives, setSelectedExecutives] = useState<Executive[]>([]);

  const [showPopup, setShowPopup] = useState(false);
  const [isSubModel, setSubModel] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSelectedPeopleText, setshowSelectedPeopleText] = useState(true);

  const getExecutives = () => (showSelectedPeopleText ? allExecutives : selectedExecutives);

  const scrollTabInView = () => {
    const id = 'share-group-dropdown';
    const element = document.getElementById(id);
    if (element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const data = useSelector(selectData);

  const onSearching = (searchText: string) => {
    scrollTabInView();
    setSearchTerm(searchText);
    if (searchText !== '') {
      const obj = {
        page: 1,
        ...(searchText && { query: searchText }),
      };
      dispatch(actions.getOrganizationExecutives(obj));
    } else {
      setExecutivesToDisplay(getExecutives());
    }
    dispatch(actions.resetSearchedExecutives());
  };

  const openPopup = () => {
    onClickHandler();
    setShowPopup(true);
    dispatch(certificationActions.certificationAction({
      type: 'buyer_select',
      source: 'buyer',
      data,
    }));
  };

  const closePopup = () => {
    setShowPopup(!showPopup);
    setSelectedExecutives([]);
  };

  const onSubModel = (stateSub = true, stateMain = false) => {
    setShowPopup(stateMain);
    setSubModel(stateSub);
    dispatch(certificationActions.certificationAction({
      type: 'people_select',
      source: 'buyer',
      data,
    }));
  };

  const selectedPeopleHandler = () => {
    if (showSelectedPeopleText) {
      setExecutivesToDisplay(selectedExecutives);
    } else if (searchTerm !== '') setExecutivesToDisplay([]);
    else setExecutivesToDisplay(allExecutives);
    setshowSelectedPeopleText(searchTerm !== '' ? false : !showSelectedPeopleText);
    setSearchTerm('');
  };

  const itemClickHandler = (person: Executive) => {
    if (selectedExecutives.findIndex(({ userId: Id }) => Id === person.userId) !== -1) {
      const remainingExecutives = [...selectedExecutives];
      remainingExecutives.splice(remainingExecutives.findIndex(
        ({ userId: Id }) => Id === person.userId,
      ), 1);
      setSelectedExecutives(remainingExecutives);
      return;
    }
    setSelectedExecutives((prev) => [...prev, person]);
  };

  useEffect(() => {
    if ((!allExecutives?.length || organizationExecutiveForGilroy) && showPopup) {
      const obj = {
        page: 1,
      };
      dispatch(actions.getOrganizationExecutives(obj));
    }
  }, [showPopup]);

  useEffect(() => {
    if (showSelectedPeopleText) { setExecutivesToDisplay(allExecutives || []); }
  }, [allExecutives]);

  useEffect(() => {
    if (!showSelectedPeopleText) {
      if (searchTerm === '') {
        if (selectedExecutives.length) {
          setExecutivesToDisplay(selectedExecutives);
        } else {
          setExecutivesToDisplay(allExecutives);
          setshowSelectedPeopleText(!showSelectedPeopleText);
        }
      } else if (!selectedExecutives.length) {
        setshowSelectedPeopleText(!showSelectedPeopleText);
      }
    }
    dispatch(actions.setSelectedExecutives(selectedExecutives || []));
  }, [selectedExecutives]);

  useEffect(() => {
    if (searchTerm !== '') { setExecutivesToDisplay(searchedExecutives); }
  }, [searchTerm, searchedExecutives]);

  const fetchMore = () => {
    if (!loading) {
      if (searchTerm === '' && !executivesEnded && showSelectedPeopleText) {
        dispatch(actions.getOrganizationExecutives({ page: executivesPage }));
      } else if (searchTerm !== '' && !searchedEnded) {
        dispatch(actions.getOrganizationExecutives({
          page: searchedPage,
          ...(searchTerm && { query: searchTerm }),
        }));
      }
    }
  };

  useEffect(() => {
    if (groupShared) {
      setSelectedExecutives([]);
      onCancelHandler();
      dispatch(actions.groupSharedFailed());
      setShowPopup(false);
    }
  }, [groupShared]);

  return (
    <Wrapper>
      <Button
        role="button"
        tabIndex={0}
        onClick={openPopup}
        onKeyPress={openPopup}
        className={classNames('share-button-group', { groupPageButton: iconMode }, { disabled: iconMode ? false : !selectedGroups.length })}
      >
        {iconMode ? (
          <span className="icon-mode">
            <span className="icon-mode-text">
              Share
            </span>
            <span className="seperator" />
            <span className="share-icon">
              <ShareIcon
                color="#fff"
              />
            </span>
          </span>
        ) : (
          <span>
            Share Group
          </span>
        )}
      </Button>
      {showPopup && (
        <Modal
          centered
          open={showPopup}
          onCancel={() => closePopup()}
          width={379}
          wrapClassName="share-group-with-people-popup"
          closable
        >
          {loading && !allExecutives.length ? (
            <SkeletonCustom
              width="250px"
              height="28px"
              margin="0px 0px 0px 0px"
              marginSm="0px 0px 0px 0px"
            />
          ) : (
            <span className="heading-popup">
              {`Share with People in ${organizationName}`}
            </span>
          )}
          <div className="person-search-bar">
            <AlertsSearch
              placeholderText="Search People"
              query={searchTerm}
              loading={loading}
              onSearching={onSearching}
              margin="0px"
              height="36px"
            />
          </div>
          <div className="d-flex justify-content-between">
            <span
              className="heading-popup second-heading-popup"
            >
              {searchTerm === ''
                ? (showSelectedPeopleText
                  ? (
                    <>
                      Select People
                      <span className={translateSkipAreas}>
                        (
                        {totalCount}
                        )
                      </span>
                    </>
                  )
                  : `Selected People (${selectedExecutives.length})`)
                : `Search Results (${searchedCount})`}

            </span>
            {selectedExecutives.length ? (
              <span
                tabIndex={0}
                className="heading-popup selected-people"
                role="button"
                onClick={selectedPeopleHandler}
                onKeyPress={selectedPeopleHandler}
              >
                {showSelectedPeopleText ? `Selected People (${selectedExecutives.length})` : 'Back'}
              </span>
            ) : null}
          </div>
          <div className="h-100 w-100" id="share-group-dropdown">
            <InfiniteScroll
              hasMore={searchTerm === '' ? !executivesEnded : !searchedEnded}
              dataLength={executivesToDisplay?.length ?? 0}
              next={fetchMore}
              loader={false}
              className="share-group-dropdown"
              height="auto"
            >
              {executivesToDisplay?.length ? executivesToDisplay?.map((person) => (
                <div className="search-item">
                  <div className="affinity-search-item">
                    <AffinitySearchResultItem
                      key={person.personId}
                      person={person}
                      onClickHandler={() => itemClickHandler(person)}
                      isSelected={false}
                      isDisabled={false}
                      email={person.email}
                      checkboxShow
                      isChecked={selectedExecutives
                        .findIndex(({ userId: Id }) => Id === person.userId) !== -1}
                    />
                  </div>
                </div>
              )) : null}
              {loading
                && (
                  <>
                    {[1, 2, 3, 4].map(() => <AffinitySearchResultItemSkeleton />)}
                  </>
                )}
              {!executivesToDisplay?.length && !loading && (executivesEnded || searchedEnded)
                ? <div className="no_data_found"><NoDataFound title={NODATAFOUND} /></div>
                : null}
            </InfiniteScroll>
          </div>
          <div className="share-group-footer">
            <span
              tabIndex={0}
              role="button"
              className={classNames('share-group-button share-button', { disabled: !selectedExecutives.length })}
              onClick={() => onSubModel(true)}
              onKeyPress={() => onSubModel(true)}
            >
              Share
            </span>
            <span
              tabIndex={0}
              role="button"
              className="share-group-button cancel-button"
              onClick={() => closePopup()}
              onKeyPress={() => closePopup()}
            >
              Cancel
            </span>
          </div>
        </Modal>
      )}
      <ShareGroupConformationModal
        showPopup={isSubModel}
        setShowPopup={onSubModel}
        isDetailPage={isDetailPage}
      />
    </Wrapper>
  );
};

ShareGroupPeopleModal.defaultProps = defaultProps;
export default ShareGroupPeopleModal;

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getLocalStorageValue, objToFormData } from './UtilityFunctions';

const instance: AxiosInstance = axios.create();

instance.interceptors.request.use(
  async (req: AxiosRequestConfig | any) => {
    req.headers['X-CSRFToken'] = getLocalStorageValue('csrfToken');
    const username = getLocalStorageValue('username');
    if (username) {
      req.data.username = getLocalStorageValue('username');
    }
    req.data.appName = 'xiq-webapp'; // will add a util function to change the source/app name LATER
    req.data = req.data ? req.data : {};
    req.data = objToFormData(req.data);
    return req;
  },
  async (err: any) => Promise.reject(err),
);

instance.interceptors.response.use(
  async (resp: AxiosResponse) => resp.data,
  async (error: any) => {
    console.log('error', error);
    if (error.response?.status === 403) {
      localStorage.clear();
      // will move back to login page with the last route url LATER
    }
    return error.response.data;
  },
);
export default instance;

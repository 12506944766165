/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
import classNames from 'classnames';
import setImageSize, {
  IsMobile,
  getCurrentTime,
  isGuest, valueToObject,
} from 'Utils/UtilityFunctions';
import HTMLReactParser from 'html-react-parser';
import SocialShareIcons from 'Components/share/SocialShareIcons';
import { Article } from 'Types/ArticleTypes';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { actions as favouriteArticleActions } from 'Slice/FavoriteArticlesSlice';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { selectData, selectIsCertificationCompleted, selectOpenDrawer } from 'Selector/CertificationSelector';
import {
  selectArticleItemSummary, selectArticleItemSummaryLoaded,
  selectArticleItemSummaryLoading,
} from 'Selector/ArticleSector';
import { actions as UiAction } from 'Slice/UISlice';
import youtubeIconPlay from 'Components/common/assets/images/youtubeIconPlay.png';
import { translateSkipAreas } from 'Utils/Constants';
import { useArticleDetail } from 'Utils/hooks/useArticleDetail';
import {
  boxShadow, primaryColor, primaryFont, wMedium, maxWidth, retina, min,
  favouriteArticleColor, secondaryFont,
  wLight, minWidthApp, primaryHeadingColor,
  maxTablet, maxMobile, smMobile,
  greyColorSecondary,
  minExtraLargeScreen, minStandardScreen,
  creator,
  textBlack,
  wMBold,
} from '../../../Utils/utilsStyle';
import PeopleWidget from './PeopleWidget';
import LinkedInIcon from '../assets/svgIcons/LinkedInIcon';
import ShareArticleStrokeIcon from '../assets/svgIcons/ShareArticleStrokeIcon';
import VideoPopup from '../modal/VideoPopup';
import SingleFavoriteIcon from '../assets/svgIcons/SingleFavoriteIcon';
import SimilarArticleIcon from '../assets/svgIcons/SimilarArticleIcon';

const ArticleWrapper = styled.div<{ triggerColor: string, articleHeight: string, articleHeightSm: string }>`
  border-radius: 8px !important;
  padding: 0px !important;
  position: relative;
  z-index: 0;
  .share-actions-with-fav {
    display: flex;
    align-items: center;
    margin-top: 7px;
  }
  .footer-data-article {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-tips{
    .tips{
      &.date{
        text-overflow: unset;
        overflow: visible;
      }
    }
  }
  &.spotlightMiniTileView {
   &:before {
    content: '';
    width: 5px;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    background-color: ${(props) => props.triggerColor};
    z-index: 1;
    top: 0px;
    left: 3px;
    transform: translateX(-50%);
   }
   .profile-area {
    &:before {
      display: none;
    }
   }
   .article_summary{
    display: none;
   }
  }
  .newsVideoFrame{
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
  }
  .text-area {
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    .footer-data-article {
      .bottom-tips {
        max-width: calc(100% - 40px);
      }
    }
  }
  &.searchTile {
    height: fit-content;
    margin-bottom: 0px;
    .person-activity-text-detail {
      padding: 16px !important;
    }
    .heading {
      padding: 0px !important;
      min-height: auto !important;
    }
    .tagArticle {
      font-size: 10px;
      padding: 0px 10px;
      right: 10px;
      top: 13px;
    }
    .article_summary{
      display: none;
    }
    &.searchTileWithOutTag {
      .heading {
        width: 89%;
        @media all and (min-width:1281px) and (max-width:1480px){
          width: 85%;
        }
        @media all and (${retina}) {
        width: 87%;
        }
        @media all and (max-width: ${minWidthApp}){
          width: 70%;
        }
      }
    }
    .text-area {
      .footer-data-article {
      }
    }
  }
  &.socialActive {
    .profile-area{
      border-radius:50%;
      position:relative;
      svg{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0px;
        bottom: -6px;
      }
      &:before {
        display: none;
      }
      img {
        border-radius:50%;
      }
    }
  }
  &.heroView {
    display: block;
    box-shadow: ${boxShadow};
    .text-area {
      .popover-sharing {
        bottom: 24px;
        top: unset;
      }
    }
    .profile-area {
     min-width:100%;
     min-height: ${(props) => props.articleHeight}px;
     width:auto;
     border-radius: 8px 8px 0px 0px;
     position: relative;
      @media all and (${retina}) {
        min-height: ${(props) => props.articleHeightSm}px;
      }
      @media all and (max-width: ${minWidthApp}) {
        min-height: 160px; 
      }
      /* &.videoPlayIframe {
        min-height: ${(props) => props.articleHeight}px;
      } */
      &:before {
        display: none;
        height: 100%;
        width: 6px;
        left: 3px;
        border-radius: 12px 0px 0px 0px;
      }
      .lazy-load-image-background{
       width:100%;
       img {
        border-radius: 8px 8px 0px 0px;
        object-fit: cover;
       }
      }
    }
    .text-area {
      padding: 0px;
      margin: 0px;
      min-width: auto;
      position: relative;
      &:before {
        content: '';
        height: 100%;
        border-radius: 0px 0px 0px 12px;
        position: absolute;
        width: 6px;
        background-color: ${(props) => props.triggerColor};
        z-index: 1;
        bottom: 0px;
        left: 3px;
        transform: translateX(-50%);
        @media all and (max-width: ${min}){
          height: 100%;
          border-radius: 0px 0px 0px 12px;
        }
      }

      @media all and (${retina}) {
        padding: 0px;
        border-radius: 0px 0px 8px 8px;
      }

      .heading {
       font-size: 14px;
       line-height: 18px;
       min-height: 52px;
       @media all and (max-width: ${maxTablet}) {
        font-size: 11px;
        line-height: 15px;
        min-height: 55px;
       }
      }
      .favorite-icon {
       bottom: 12px;
       @media all and (${retina}) {
          bottom: 10px;
      }
      }
      .bottom-tips {
        .tips {
         font-size:12px;
         line-height:18px;
          @media all and (${retina}) {
            font-size: 10px;
          }
        }
      }
      .person-activity-text-detail {
        padding: 0px;
      }
    }
  }
  &.activityActive {
    .tagArticle {
      padding: 2px 10px;
      font-size: 14px;
    }
    .text-area {
      display: flex;
      align-items: center;
      border-radius: 0px 0px 8px 8px;
      padding: 0px;
      border-top: 1px solid #DADADA;
      > div {
        width: 100%;
      }
      .heading {
        padding: 12px 20px 5px 10px;
        font-size: 18px;
        line-height: 24px;
        /* min-height: 0px; */
        @media all and (max-width: ${maxWidth}),${retina} {
          font-size: 16px;
        }
        @media all and (max-width: ${maxTablet}) {
         font-size: 12px;
         line-height: 14px;
         padding: 12px 20px 0 10px;
        }
      }
      .article_summary{
        padding: 8px 20px 3px 10px;
        line-height: 20px;
        font-size: 15px;
        font-weight: ${wMedium};
        letter-spacing: -0.56px;
        font-family: ${primaryFont};
        color: #7E7E7E;
        @media all and (max-width: ${maxWidth}),${retina} {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .footer-data-article {
        // padding: 0px 20px 10px 10px;
      }
      a {
      display: flex;
      align-items: center;
      }
      .person-area-activity {
        position: relative;
        div {
          margin-bottom: 0px;
        }
        .social-platform-placeholder {
          position: absolute;
          right: 0px;
          bottom: 0px;
          svg {
              background-color: #fff;
          }
        }
      }
      .person-activity-text-detail {
         margin-left: 15px;
      }
    }
  }
  .lazy-load-image-background {
    width: 100%;
    height: 100%;
    &.blur {
    filter: blur(15px);
    }
    &.lazy-load-image-loaded {
      filter: none !important;
    }
  }
  .profile-area {
      height: 72px;
      width: 72px;
      @media all and (max-width: ${minWidthApp})
      {
        height: 60px;
        width: 60px;
        min-width: 60px;
      }
      border-radius: 10px;
      position: relative;
      z-index: 0;
      @media all and (max-width: ${min}){
        height: 51px !important;
        width: 51px !important;
        min-width: 51px;
        border-radius: 7px;
      }
      &:before {
        content: '';
        height: 7px;
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        width: 100%;
        background-color: ${(props) => props.triggerColor};
        z-index: 1;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        @media all and (max-width: ${min}){
          height: 4px;
          border-radius: 0px 0px 7px 7px;
        }
      }
    img {
     width: 100%;
     height: 100%;
     border-radius: 10px;
    }
  }
  .text-area {
    .heading {
      font-size: 12px;
      line-height: 14px;
      min-height: 42px;
      font-weight: ${wMedium};
      padding: 0px 115px 0px 0px;
      @media all and (max-width: ${maxTablet}) {
        font-size: 11px;
        line-height: 14px;
      }
      @media all and (max-width: ${maxMobile}) {
        min-height: 24px;
      }
    }
    .bottom-tips {
      .tips {
        font-size: 11px;
        line-height: 11px;
        @media all and (${retina}) {
          font-size: 10px;
        }
      }
    }
    .favorite-icon {
      position: absolute;
      bottom: 0px;
      right: 40px;
      svg {
        &:hover {
          cursor: pointer;
        }
      }
      @media all and (${retina}) {
        bottom: 15px;
        right: 40px;
      }
    }
  }
  &.topNewsStyle {
    .tagArticle {
      top: -40px;
    }
    .popover-sharing{
     top: 0px !important;
    }
  }
  &.topWithImgTile {
    box-shadow: none !important;
    background: transparent !important;
    &.notHeroView {
      &.article-item {
        padding: 0px !important;
      }
      .text-area {
        .popover-sharing {
          bottom: 2px;
          @media all and (${retina}) {
            bottom: 9px;
          }
        }
      }
    }
  }
  &.notHeroView {
    box-shadow: ${boxShadow};
    background-color: #fff;
    &.article-item {
     padding: 8px 11px 8px 16px !important;
     margin-bottom: 8px;
     .profile-area {
      &:before {
        height: 100%;
        width: 7px;
        bottom: 0px;
        left: 3px !important;
        top: 0px;
        border-radius: 12px 0px 0px 12px;
      }
     }
    }
    .text-area {
      min-width: calc(100% - 84px);
      @media all and (max-width: ${minWidthApp}) and (min-width: ${maxTablet}){
        min-width: calc(100% - 70px);
      }
      @media all and (max-width: ${maxMobile}) {
        min-width: calc(100% - 64px);
      }
      .popover-sharing {
       bottom: 10px;
       top: unset;
        @media all and (${retina}) {
          bottom: 15px;
        }
      }
    }
  }
  &.notImage {
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 100%;
      border-radius: 10px 0px 0px 10px;
      position: absolute;
      background-color: ${(props) => props.triggerColor};
      z-index: 1;
      top: 0px;
      left: 3px;
      transform: translateX(-50%);
    }
    .popover-sharing {
      top: unset;
      bottom: 20px !important;
    }

    .profile-area{
      display:none;
    }
    .text-area {
      border-radius: 8px;
      height: 100%;
      display: flex;
      align-items: center;
      .heading {
        padding: 55px 15px 3px 10px;
        @media all and (max-width: ${maxTablet}) {
          padding: 55px 15px 0px 10px;
        }
      }
      .favorite-icon {
        bottom: 8px;
        @media all and (${retina}) {
            bottom: 6px;
        }
      }
    }
  }
  &.spotlight-heroTile-style {
    &.heroView {
      .text-area {
        .popover-sharing {
          bottom: 10px;
          @media all and (${retina}) {
            bottom: 15px;
          }
        }
        .heading {
          padding: 8px 11px 0px 16px;
          @media all and (max-width: ${maxTablet}){
           padding: 8px 5px 0px 10px; 
          }
        }
        .article_summary{
          display: none;
        }
        .footer-data-article {
          padding: 0px 11px 8px 16px;
          @media all and (max-width: ${maxTablet}){
             padding: 0px 5px 8px 10px;
          }
        }
        .favorite-icon {
          bottom: 0px;
          @media all and (${retina}) {
            bottom: 17px;
            right: 40px;
          }
        }
      }
    }
  }
  .favorite-icon-custom {
    margin-right: 8px;
  }
  .favorite-icon-custom, .popover-sharing-custom {
    height: 15px;
  }
`;

const ArticleListWrapper = styled.div<{
  triggerColor: string, summaryPadding: string,
}>`
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  height: 117px;
  padding: 0px 10px 0px 10px;
  margin-bottom: 8px;
  box-shadow: ${boxShadow};
  z-index: 0;
  @media all and (max-width: ${maxTablet}){
    height: auto;
  }
  &:before {
    content: '';
    width: 5px;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    background-color: ${(props) => props.triggerColor};
    z-index: 1;
    top: 0px;
    left: 3px;
    transform: translateX(-50%);
   }
  .profile-area {
    height: 85px;
    width: 85px;
    min-width: 85px;
    margin-left: 5px;
    @media all and (max-width: ${maxTablet}){
      height: 51px;
      width: 51px;
      min-width: 51px;
    }
    .img-fluid {
      height: 100%;
      object-fit: cover;
    }
  }
  &.notImage {
    @media all and (max-width: ${maxTablet}) {
      padding: 0px;
    }
    .text-area {
      width: 100%;
    }
  }
  .text-area {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 17px 0px 17px 0px;
    justify-content: center;
    width: calc(100% - 100px);
    @media all and (max-width: ${maxTablet}){
      padding: 8px 0px;
      min-width: calc(100% - 60px);
    }

    .bottom-tips{
      .tips{
        &.date{
          text-overflow: unset;
          overflow: visible;
        }
      }
    }
    .title-summary-div {
      padding-right: 50px;
      &.tagArticleSummary {
        padding-right: 100px;
        @media all and (max-width: ${minWidthApp}) {
          padding-right: 140px;
        }
        @media all and (max-width: ${maxTablet}) {
          padding-right: ${(props) => (props.summaryPadding ? props.summaryPadding : '145px')};
        
        }
        @media all and (max-width: ${smMobile}) {
          padding-right: 150px;
        }
      }
    }
    .tagArticle {
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 12px;
      line-height: 16.43px;
      @media all and (max-width: ${maxMobile}) { 
        top: 10px;
      }
    }
    .heading {
      font-family: ${secondaryFont};
      font-weight: ${wMedium};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.45px;
      color: ${primaryHeadingColor};
      @media all and (max-width: ${maxTablet}){
        font-size: 13px;
        line-height: 16px;
      }
    }
    .article_summary {
      min-height: 14px;
      font-family: ${primaryFont};
      font-weight: ${wLight};
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.56px;
      color: #7E7E7E;
      margin-top: 0px;
    }
  }

  .footer-data-article {
    display: flex !important;
    justify-content: space-between;
    padding: 0px 10px 0px 0px;
    margin-top: 10px;
    height: 12px;
    .bottom-tips {
      display: flex;
      @media all and (max-width: ${maxTablet}) {
        max-width: 80%;
      }
      @media all and (max-width: ${smMobile}){
        width: calc(100% - 50px);
      }
    }
    .tips {
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 11px;
      line-height: 11px;
      @media all and (max-width: ${maxTablet}) {
        font-size: 8px;
      }
      &.domain {
        color: #DB3725;
        border-right: 1.5px solid #7E7E7E;
        padding: 0px 5px 0px 0px;
        @media all and (max-width: ${smMobile}) {
          max-width:60%;
        }
      }
      &.date {
        color: #7E7E7E;
        border-right: 1.5px solid #7E7E7E;
        padding: 0px 5px;
        @media all and (max-width: ${smMobile}) {
          max-width:20%
        }
      }
      &.industry {
        color: ${primaryColor};
        margin-left: 5px;
        @media all and (max-width: ${smMobile}) {
          max-width:20%;
        }
      }
    }
    .share-actions-with-fav {
      @media all and (max-width: ${smMobile}){
        min-width: 45px;
      }
      .favorite-icon-custom {
        margin-right: 10px;
      }
    }
  }

  &.tileListView{
    height: 120px;
    margin-bottom: 8px;
    padding: 10px 12px 10px 17px;
    box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);
    @media all and (min-width: ${minExtraLargeScreen}) {
      height: 136px;
      margin-bottom: 8px;
      padding: 12px 16.54px 12px 21px;
    }
    @media all and (${retina}) {
      height: 113px;
      margin-bottom: 8px;
      padding: 9px 12px 9px 17px;
    }
    @media all and (max-width: ${minStandardScreen}) {
      height: 106px;
      margin-bottom: 8px;
      padding: 9px 8px 9px 14px;
    }
    @media all and (max-width: ${maxTablet}) {
      height: 100px;
      margin-bottom: 6px;
      padding: 6px 8px 6px 10px;
    }
    @media all and (max-width: ${maxMobile}){
      margin-bottom: 8px;
      padding: 6px 5px 6px 11px;
    }
    &::before{
      transform: unset;
      left: 0;
      width: 7px;
      @media all and (min-width: ${minExtraLargeScreen}) {
        width: 9px;
      }
      @media all and (${retina}) {
        width: 7px;
      }
      @media all and (max-width: ${minStandardScreen}) {
        width: 6px;
      }
      @media all and (max-width: ${maxTablet}) {
        width: 4px;
      }
      @media all and (max-width: ${maxMobile}){
        width: 5px;
      }
    }
    .profile-area,
    .videoFrameArea{
      width: 100px;
      min-width: 100px;
      height: 100px;
      margin: 0;
      box-shadow: ${boxShadow};
      border-radius: 6px;
      overflow: hidden;
      @media all and (min-width: ${minExtraLargeScreen}) {
        width: 112px;
        min-width: 112px;
        height: 112px;
      }
      @media all and (${retina}) {
        width: 100px;
        min-width: 100px;
        height: 95px;
      }
      @media all and (max-width: ${minStandardScreen}) {
        min-width: 88px;
        width: 88px;
        height: 88px;
      }
      @media all and (max-width: ${maxMobile}){
        width: 86px;
        min-width: 86px;
        height: 88px;
      }
    }
    .profile-area{
      & > span{
        height: 100%;
        width: 100%;
      }
    }
    .videoFrameArea {
      position: relative;
      .thumbnail-image {
        height: 100%;
        width: 100%;
        min-width: 100%;
        border-radius: 6px;
      }
      .layer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
      .youtube-icon {
        height: auto;
        width: 35px;
      }
    }
    .footer-data-article{
      margin-top: 0;
      height: max-content;
      .bottom-tips{
        display:none;
      }
      .share-actions-with-fav{
        position: absolute;
        right: 12px;
        bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          right: 16px;
          bottom: 16px;
          gap: 12px;
        }
        @media all and (${retina}) {
          right: 17px;
          bottom: 11px;
          gap: 8px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          right: 9px;
          bottom: 10px;
          gap: 7px;
        }
        @media all and (max-width: ${maxTablet}) {
          right: 7px;
          bottom: 8px;
          gap: 5px;
        }
        @media all and (max-width: ${maxMobile}){
          gap: 6px;
          min-width: unset;
          right: 11px;
          bottom: 10px;
        }
        .favorite-icon-custom,
        .article-share-icon{
          margin: 0;
          display: inline-flex;
        }
        .favorite-icon-custom{
          width: 16px;
          height: 15.206px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            width: 19.6px;
            height: 18.628px;
          }
          @media all and (${retina}) {
            width: 10.5px;
            height: 9.92px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            width: 11.574px;
            height: 11px;
          }
          svg{
            height: 100%;
            width: 100%;
          }
        }
        .article-share-icon{
          width: 12.511px;
          height: 15px;
          display: inline-flex;
          @media all and (min-width: ${minExtraLargeScreen}) {
            width: 15.301px;
            height: 18.346px;
          }
          @media all and (${retina}) {
            width: 9px;
            height: 11px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            width: 9.175px;
            height: 11px;
          }
          svg{
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .text-area{
      margin-left: 10px;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: unset;
      @media all and (min-width: ${minExtraLargeScreen}) {
        margin-left: 12px;
        padding: 0;
      }
      @media all and (${retina}) {
        margin-left: 10px;
        padding: 0;
      }
      @media all and (max-width: ${minStandardScreen}) {
        margin-left: 8px;
        padding: 0;
      }
      @media all and (max-width: ${maxTablet}){
        margin-left: 6px;
        padding: 0;
        min-width: unset;
      }
      @media all and (max-width: ${maxMobile}){
        margin-left: 6px;
        padding: 0;
      }
      .title-summary-div{
        // just to save overflow on icon
        padding-right: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .tagAndIndustryBox{
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        margin-bottom: 2px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          gap: 24px;
          margin-bottom: 2px;
        }
        @media all and (${retina}) {
          gap: 16px;
        }
        @media all and (max-width: ${minStandardScreen}){
          gap: 12px;
          margin-bottom: 5px;
        }
        @media all and (max-width: ${maxTablet}){
          gap: 10px;
          margin-bottom: 4px;
        }
        @media all and (max-width: ${maxMobile}){
          gap: 8px;
          margin-bottom: 6px;
        }
        .industryName{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: ${creator};
          font-family: ${primaryFont};
          font-size: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 14px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
            line-height: 16px;
          }
          @media all and (${retina}) {
            font-size: 10px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 10.889px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 7.995px;
            line-height: 8.706px;
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 12px;
          }
        }
        .tagArticle {
          border: none;
          color: #FFF;
          font-size: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 14px;
          letter-spacing: -0.361px;
          border-radius: 4px;
          padding: 4px 8px;
          position: unset;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          margin-left: auto;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
            line-height: 16px;
            padding: 5.114px 10.228px;
            border-radius: 6px;
            height: 24px;
          }
          @media all and (${retina}) {
            font-size: 10px;
            line-height: 12.444px;
            padding: 3.556px 7.111px;
            border-radius: 4px;
            height: 16px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 10.889px;
            padding: 3.111px 6px;
            border-radius: 3px;
            height: 14px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 7.995px;
            line-height: 8.706px;
            padding: 2px 4px;
            border-radius: 2px;
            height: 11px;
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 12px;
            padding: 3.556px 6px;
            border-radius: 2px;
            height: 14px;
          }
        }
      }
      .heading {
        color: ${textBlack};
        font-family: ${secondaryFont};
        font-weight: ${wMedium};
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.277px;
        margin-bottom: 4px;
        padding-right: 153px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.641px;
          font-weight: ${wMBold};
        }
        @media all and (${retina}) {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.277px;
        }
        @media all and (max-width: ${minStandardScreen}) {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.242px;
          padding-right: 125px;
        }
        @media all and (max-width: ${maxTablet}){
          letter-spacing: -0.206px;
          padding-right: 21px;
        }
        @media all and (max-width: ${maxMobile}){
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.242px;
          margin-bottom: 12px;
          padding-right: 0px;
        }
        &.text-truncate-one-line{
          @media all and (max-width: ${maxMobile}){
            -webkit-line-clamp: 3;
          }
        }
      }
      .article_summary{
        margin-bottom: 10px;
        margin-right: 0;
        font-weight: ${wLight};
        letter-spacing: -0.311px;
        color: ${primaryHeadingColor};
        font-family: ${primaryFont};
        font-size: 14px;
        line-height: 16px;
        @media all and (min-width: ${minExtraLargeScreen}) {
          font-size: 14px;
          line-height: normal;
        }
        @media all and (max-width: ${minStandardScreen}) {
          font-size: 10px;
          line-height: normal;
        }
        @media all and (max-width: ${minWidthApp}){
          margin-right: 0;
        }
        @media all and (max-width: ${maxTablet}){
          margin-bottom: 15px;
        }
        @media all and (max-width: ${maxMobile}){
          display: none !important;
        }
        &.text-truncate-one-line{
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-wrap: break-word;
        }
      }
      .sourcesBox{
        display: flex;
        align-items: center;
        width: calc(100% - 52px);
        margin-top: auto;
        .separatorLeftOnly,
        .separatorBothSide{
          padding-left: 13px;
          position: relative;
          @media all and (min-width: ${minExtraLargeScreen}) {
            padding-left: 17px;
          }
          @media all and (max-width: ${minStandardScreen}) {
            padding-left: 9px;
          }
          @media all and (max-width: ${maxTablet}){
            padding-left: 7px;
          }
          @media all and (max-width: ${maxMobile}){
            padding-left: 9px;
          }
          &::after,
          &::before{
            content: "";
            height: 10px;
            width: 1px;
            background-color: #CFCFCF;
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            @media all and (min-width: ${minExtraLargeScreen}) {
              left: 8px;
            }
            @media all and (max-width: ${minStandardScreen}){
              left: 4px;
              height: 10px;
            }
            @media all and (max-width: ${maxTablet}){
              left: 3px;
              height: 8px;
            }
            @media all and (max-width: ${maxMobile}){
              left: 4px;
              height: 9px;
            }
          }
          &::before{
            right: 6px;
            left: unset;
            @media all and (min-width: ${minExtraLargeScreen}) {
              right: 8px;
            }
            @media all and (max-width: ${minStandardScreen}){
              right: 4px;
            }
            @media all and (max-width: ${maxTablet}){
              right: 3px;
            }
            @media all and (max-width: ${maxMobile}){
              right: 4px;
            }
          }
        }
        .separatorBothSide{
          padding: 0 13px;
          @media all and (min-width: ${minExtraLargeScreen}) {
            padding: 0 17px;
          }
          @media all and (${retina}) {
          }
          @media all and (max-width: ${minStandardScreen}){
            padding: 0 9px;
          }
          @media all and (max-width: ${maxTablet}){
            padding: 0 7px;
          }
          @media all and (max-width: ${maxMobile}){
            padding: 0 9px;
          }
        }
        .separatorLeftOnly{
          &::before{
            display: none !important;
          }
        }
        .source{
          color: ${greyColorSecondary};
          text-align: center;
          font-family: ${primaryFont};
          font-size: 12px;
          font-style: normal;
          font-weight: ${wMedium};
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media all and (min-width: ${minExtraLargeScreen}) {
            font-size: 14px;
          }
          @media all and (${retina}) {
            font-size: 10px;
          }
          @media all and (max-width: ${minStandardScreen}){
            font-size: 10px;
            line-height: 16px;
          }
          @media all and (max-width: ${maxTablet}){
            font-size: 8px;
            line-height: 13.624px
          }
          @media all and (max-width: ${maxMobile}){
            font-size: 10px;
            line-height: 14px;
          }
        }
        .similarArticles,
        .date{
          overflow: visible;
        }
        .similarArticles{
          color: ${primaryColor};
        }
      }
    }
  }
  &.notImage{
    .text-area{
      margin-left: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  &.highlightsTabPopover {
  }
`;

const ArticleTag = styled.span <{ tagColor: string }>`
 font-size: 20px;
 padding: 0px 10px;
 color: #fff;
 border: 1px solid #fff;
 font-weight: ${wMedium};
 font-family: ${primaryFont};
 border-radius: 12px;
 background-color: ${(props) => props.tagColor};
 line-height: 18px;
 letter-spacing: -0.29px;
 position: absolute;
 right: 17px;
 top: 15px;
 z-index: 1;
`;

type Props = {
  hideCompanyName?: boolean,
  favoritePageFlag?: boolean,
  heroArticle?: boolean,
  socialActivity?: boolean,
  activityTile?: boolean,
  personPersona?: string,
  personImage?: string,
  articleHeight?: string;
  articleHeightSm?: string;
  articleWidth?: number | string;
  searchArticleTile?: boolean;
  article: Article
  topNews?: boolean,
  topWithImg?: boolean,
  spotlightMiniTile?: boolean;
  tileClassName?: string;
  spotlightArticle?: boolean;
  listView?: boolean;
  summaryPadding?: string;
  popoverCustomClass?: string;
  gridTile?: boolean;
  tileListView?: boolean;
  feedSalesTrigger?: boolean;
} & typeof defaultProps;

const defaultProps = {
  hideCompanyName: false,
  favoritePageFlag: false,
  heroArticle: false,
  socialActivity: false,
  activityTile: false,
  searchArticleTile: false,
  personPersona: '',
  personImage: '',
  articleHeight: '280',
  articleHeightSm: '280',
  articleWidth: '570',
  topNews: false,
  topWithImg: false,
  spotlightMiniTile: false,
  tileClassName: '',
  spotlightArticle: false,
  listView: false,
  summaryPadding: '145px',
  popoverCustomClass: '',
  gridTile: false,
  tileListView: false,
  feedSalesTrigger: false,
};

const ArticleTileGlobal = function ArticleTileGlobal(props: Props) {
  const dispatch = useDispatch();
  const startTime = getCurrentTime();
  const elementRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(false);
  const [videoPopUpVisible, setVideoPopUpVisible] = useState(false);
  const [elementRefHeight, setElementRefHeight] = useState<number>(0);
  const [elementRefWidth, setElementRefWidth] = useState<number>(0);

  useEffect(() => {
    if (elementRef && elementRef.current) {
      setElementRefHeight(elementRef.current.clientHeight);
      setElementRefWidth(elementRef.current.clientWidth);
    }
  });

  const certificationData = useSelector(selectData);
  const completed = useSelector(selectIsCertificationCompleted);
  const host = window.location.href;
  const articleSummary = useSelector(selectArticleItemSummary);
  const summaryLoading = useSelector(selectArticleItemSummaryLoading);
  const summaryLoaded = useSelector(selectArticleItemSummaryLoaded);
  const isMobile = IsMobile();
  const {
    hideCompanyName,
    favoritePageFlag,
    heroArticle,
    socialActivity,
    activityTile,
    personPersona, personImage,
    searchArticleTile,
    articleHeight, articleHeightSm,
    articleWidth,
    article, topNews, topWithImg, spotlightMiniTile,
    spotlightArticle,
    tileClassName, listView,
    summaryPadding, popoverCustomClass,
    gridTile, tileListView,
    feedSalesTrigger,
  } = props;
  const articleTag = (article?.alerts[0]?.alert !== '');
  const { toggleVisible } = useArticleDetail({
    visible,
    setVisible,
  });
  const certificationDrawer = useSelector(selectOpenDrawer);
  const similarArticlesCounts = article?.similarArticlesCount;
  const isVideoVisible = () => {
    setVideoPopUpVisible(!videoPopUpVisible);
  };

  // eslint-disable-next-line max-len
  const videoTileClickHandler = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    isVideoVisible();
  };

  let isImg = false;
  if (article.image === '') {
    isImg = true;
  }

  useEffect(() => {
    if (visible && (spotlightArticle || !completed)) {
      dispatch(certificationActions.certificationAction({
        type: 'spotlight_article_select',
        source: 'spotlight',
        data: certificationData,
      }));
    }
    if (visible && (spotlightArticle || spotlightMiniTile || searchArticleTile)) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject(spotlightArticle || spotlightMiniTile ? 'feedpage_spotlight_article_clicked' : 'searchPage_article_clicked', 0, {
          article_id: article.articleId,
          ...article.alerts[0]?.alertId && { alert_id: article.alerts[0]?.alertId },
          host,

        }),
      ));
    }
    if (visible && feedSalesTrigger) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('salesTrigger_article_clicked', 0, {
          article_id: article.articleId,
          ...article.alerts[0]?.alertId && { alert_id: article.alerts[0]?.alertId },
          host,

        }),
      ));
    }
  }, [visible]);

  useEffect(() => {
    if (certificationDrawer && visible) {
      setVisible(false);
    }
  }, [certificationDrawer]);

  const content = (
    <ContentWrapper className={`sharingPopoverContentWrapper ${popoverCustomClass}`}>
      <h6 className="heading-popover">QuickShare to:</h6>
      <SocialShareIcons
        spotlight={spotlightArticle}
        articleid={article.articleId}
        title={article.title}
        img={article.image}
        domain={article.domain}
        entity="article"
        url={article.url}
        alertId={(article?.alerts && article?.alerts.length > 0
          && article.alerts[0].alertId) || 0}
        summary={articleSummary}
        summaryLoading={summaryLoading}
        summaryLoaded={summaryLoaded}
      />
    </ContentWrapper>
  );

  const handleOpenVisible = () => {
    if (article) {
      toggleVisible(article);
    }
  };

  const addToFavorite = (articleId: number) => {
    const data = {
      articleId,
      favoriteFlag: article.isFavorite ? 0 : 1,
    };
    dispatch(favouriteArticleActions.addToFavorite(data));
  };

  useEffect(() => {
    if (visible && favoritePageFlag) {
      setVisible(false);
    }
  }, [article]);

  const handleOpenChange = (open: boolean) => {
    if (open) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('article_share_intent', 0, {
          host,
        }),
      ));
    }
  };

  const handleWebsiteClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    window.open(article.url);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('article_weblink_clicked', 0, {
        host,
      }),
    ));
  };

  return (
    <>
      {listView ? (
        <ArticleListWrapper
          className={classNames(`${tileClassName}`, {
            notImage: isImg && activityTile,
            tileListView,
          })}
          triggerColor={article?.alerts[0]?.color}
          summaryPadding={summaryPadding}
        >
          {
            article.videoUrl.length
              ? (
                <div
                  className="videoFrameArea"
                >
                  <LazyLoadImage
                    src={setImageSize(
                      article.image,
                      85,
                      85,
                      article.articleId,
                    )}
                    className="thumbnail-image"
                    alt="thumbnail"
                  />
                  <div
                    className="layer"
                    onClick={(event) => videoTileClickHandler(event)}
                    onKeyDown={(event) => videoTileClickHandler(event)}
                    tabIndex={0}
                    role="button"
                    aria-label="Play on youtube"
                  >
                    <img src={youtubeIconPlay} className="youtube-icon" alt="youtube-icon" />
                  </div>
                </div>
              )
              : (
                article.image
                && (
                  <div
                    className="profile-area"
                    onClick={handleOpenVisible}
                    onKeyPress={handleOpenVisible}
                    role="button"
                    aria-label="Open or close"
                    tabIndex={0}
                  >
                    <LazyLoadImage
                      src={setImageSize(
                        article.image,
                        85,
                        85,
                        article.articleId,
                      )}
                      className="img-fluid"
                      alt="" // its empty because of some reason
                      effect="blur"
                    />
                  </div>
                )
              )
          }
          <div className="text-area">
            {(
              (article.companyName)
              || (article?.alerts[0]?.alert))
              && (
                <div className="tagAndIndustryBox">
                  {article.companyName && !hideCompanyName && (
                    <div className="industryName">
                      {article.companyName}
                    </div>
                  )}
                  {heroArticle && article?.alerts.length > 0 && (
                    <ArticleTag tagColor={article?.alerts[0]?.color} className="tagArticle c-auto">
                      {article?.alerts[0]?.alert}
                    </ArticleTag>
                  )}
                </div>
              )}
            <div className={classNames('title-summary-div', { tagArticleSummary: articleTag })}>
              <span
                className="heading text-truncate-one-line"
                onClick={handleOpenVisible}
                onKeyPress={handleOpenVisible}
                role="button"
                aria-label="open or close"
                tabIndex={0}
              >
                {HTMLReactParser(article.title)}
              </span>
              <span
                className="article_summary text-truncate-one-line"
                onClick={handleOpenVisible}
                onKeyPress={handleOpenVisible}
                role="button"
                tabIndex={0}
              >
                {article.summary.replace(/<[^>]+>/g, '')}
              </span>
              <div className="sourcesBox">
                <div className="source date">
                  {article.dateTimeStamp}
                </div>
                <div
                  className={`source domain ${translateSkipAreas} ${similarArticlesCounts ? 'separatorBothSide' : 'separatorLeftOnly'}`}
                  onClick={handleWebsiteClick}
                  onKeyPress={handleWebsiteClick}
                  role="button"
                  aria-label={`view ${article.domain}`}
                  tabIndex={0}
                >
                  {article.domain}
                </div>
                {tileListView && similarArticlesCounts ? (
                  <div
                    className="source similarArticles"
                    onClick={() => {
                      dispatch(UiAction.isSimilarArticleScroll(true)); handleOpenVisible();
                      dispatch(flurryActions.callFlurryEvent(
                        valueToObject('similarArticle_count_clicked', startTime, {
                          article_id: article?.articleId,
                          article_title: article?.title,
                          alert_id: article?.alerts[0].alertId,
                          article_link: article?.url,
                          host,
                        }),
                      ));
                    }}
                    onKeyPress={() => {
                      dispatch(UiAction.isSimilarArticleScroll(true)); handleOpenVisible();
                      dispatch(flurryActions.callFlurryEvent(
                        valueToObject('similarArticle_count_clicked', startTime, {
                          article_id: article?.articleId,
                          article_title: article?.title,
                          alert_id: article?.alerts[0].alertId,
                          article_link: article?.url,
                          host,
                        }),
                      ));
                    }}
                    tabIndex={0}
                    role="button"
                  >
                    Similar:
                    {' '}
                    <span>{similarArticlesCounts}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="footer-data-article">
              {!isGuest() && (
                <div className="share-actions-with-fav">
                  <span
                    className="favorite-icon-custom"
                    onClick={(e) => {
                      e.stopPropagation();
                      addToFavorite(article.articleId);
                    }}
                    onKeyPress={(e) => {
                      e.stopPropagation();
                      addToFavorite(article.articleId);
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Faviourite"
                  >
                    {article?.isFavorite
                      ? (
                        <SingleFavoriteIcon
                          strokeColor={favouriteArticleColor}
                          fillColor={favouriteArticleColor}
                        />
                      )
                      : (
                        <SingleFavoriteIcon
                          strokeColor={greyColorSecondary}
                        />
                      )}
                  </span>
                  <Popover
                    placement="leftBottom"
                    showArrow={false}
                    content={content}
                    trigger="click"
                    onOpenChange={handleOpenChange}
                    getPopupContainer={(e: HTMLElement) => e}
                    className="popover-sharing-custom popoverSharingCustom popoverSharingListingView"
                    arrow={false}
                  >
                    <div
                      className={classNames('article-share-icon', { listviewShareIcon: listView })}
                      aria-hidden="true"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ShareArticleStrokeIcon color={greyColorSecondary} />
                    </div>
                  </Popover>
                </div>
              )}
            </div>
          </div>
          <VideoPopup
            videoLink={article.videoUrl}
            visible={videoPopUpVisible}
            setVisible={isVideoVisible}
          />
        </ArticleListWrapper>
      ) : (
        <ArticleWrapper
          articleHeight={articleHeight}
          articleHeightSm={articleHeightSm}
          triggerColor={article?.alerts[0]?.color}
          className={classNames(`article-item article-tile-global ${tileClassName}`, {
            heroView: heroArticle,
            socialActive: socialActivity,
            activityActive: activityTile,
            notImage: isImg && activityTile,
            topNewsStyle: isImg && topNews,
            searchTileWithOutTag: isImg && searchArticleTile,
            searchTile: searchArticleTile,
            notHeroView: !heroArticle,
            topWithImgTile: topWithImg,
            spotlightMiniTileView: spotlightMiniTile,
            gridTile,
          })}
        >
          <div
            ref={elementRef}
            className={classNames('profile-area', { videoPlayIframe: article.videoUrl.length })}
            onClick={handleOpenVisible}
            onKeyPress={handleOpenVisible}
            role="button"
            aria-label="Open or close"
            tabIndex={0}
          >
            {article.videoUrl.length
              ? (
                <div
                  className="newsVideoFrame"
                >
                  <LazyLoadImage
                    src={setImageSize(
                      article.image,
                      elementRefWidth || articleWidth,
                      elementRefHeight || articleHeight,
                      article.articleId,
                    )}
                    className="thumbnail-image"
                    alt="thumbnail"
                  />
                  <div
                    className="layer"
                    onClick={(event) => videoTileClickHandler(event)}
                    onKeyDown={(event) => videoTileClickHandler(event)}
                    tabIndex={0}
                    role="button"
                    aria-label="Play on youtube"
                  >
                    <img src={youtubeIconPlay} className="youtube-icon" alt="youtube-icon" />
                  </div>
                </div>
              )
              : (
                article.image
                && (
                  <div className="h-100">
                    <LazyLoadImage
                      src={setImageSize(
                        article.image,
                        elementRefWidth || articleWidth,
                        elementRefHeight || articleHeight,
                        article.articleId,
                      )}
                      className="img-fluid"
                      alt="article-image"
                      effect="blur"
                    />
                  </div>
                )
              )}
          </div>
          <div className="text-area">
            <div className="textAreaContentWrapper">
              {personImage
                && (
                  <div
                    className="person-area-activity"
                    onClick={handleOpenVisible}
                    onKeyPress={handleOpenVisible}
                    role="button"
                    tabIndex={0}
                    aria-label="View person information"
                  >
                    <PeopleWidget
                      diameter="92px"
                      persona={personPersona}
                      logo={personImage}
                    />
                    <span className="social-platform-placeholder">
                      <LinkedInIcon />
                    </span>
                  </div>
                )}
              <div className="person-activity-text-detail">
                {(
                  (article.companyName)
                  || (article?.alerts[0]?.alert))
                  && (
                    <div className="tagAndIndustryBox">
                      {article.companyName && !hideCompanyName && (
                        <div className="industryName">
                          {article.companyName}
                        </div>
                      )}
                      {heroArticle && article?.alerts.length > 0 && (
                        <ArticleTag tagColor={article?.alerts[0]?.color} className="tagArticle c-auto">
                          {article?.alerts[0]?.alert}
                        </ArticleTag>
                      )}
                    </div>
                  )}
                <span
                  className={classNames('heading text-truncate-three-line', {
                    headingSpaceFromTop: !((article.companyName) || (article?.alerts[0]?.alert)),
                  })}
                  onClick={handleOpenVisible}
                  onKeyPress={handleOpenVisible}
                  role="button"
                  aria-label="open or close"
                  tabIndex={0}
                >
                  {HTMLReactParser(article.title)}
                </span>
                <span
                  className="article_summary text-truncate-two-line"
                  onClick={handleOpenVisible}
                  onKeyPress={handleOpenVisible}
                  role="button"
                  tabIndex={0}
                >
                  {article.summary.replace(/<[^>]+>/g, '')}
                </span>

                <div className="footer-data-article">
                  {!isGuest() && (
                    <div className="share-actions-with-fav">
                      <div className="sourcesBox">
                        <div className="source date">
                          {article.dateTimeStamp}
                        </div>
                        <div
                          className={`source domain ${translateSkipAreas} ${similarArticlesCounts ? 'separatorBothSide' : 'separatorLeftOnly'}`}
                          onClick={handleWebsiteClick}
                          onKeyPress={handleWebsiteClick}
                          role="button"
                          aria-label={`view ${article.domain}`}
                          tabIndex={0}
                        >
                          {article.domain}
                        </div>
                        {gridTile && similarArticlesCounts ? (
                          <div
                            className="source similarArticles"
                            onClick={() => {
                              dispatch(UiAction.isSimilarArticleScroll(true)); handleOpenVisible();
                              dispatch(flurryActions.callFlurryEvent(
                                valueToObject('similarArticle_count_clicked', startTime, {
                                  article_id: article?.articleId,
                                  article_title: article?.title,
                                  alert_id: article?.alerts[0].alertId,
                                  article_link: article?.url,
                                  host,
                                }),
                              ));
                            }}
                            onKeyPress={() => {
                              dispatch(UiAction.isSimilarArticleScroll(true)); handleOpenVisible();
                              dispatch(flurryActions.callFlurryEvent(
                                valueToObject('similarArticle_count_clicked', startTime, {
                                  article_id: article?.articleId,
                                  article_title: article?.title,
                                  alert_id: article?.alerts[0].alertId,
                                  article_link: article?.url,
                                  host,
                                }),
                              ));
                            }}
                            tabIndex={0}
                            role="button"
                          >
                            {!isMobile ? (
                              <>
                                Similar:
                              </>
                            ) : <SimilarArticleIcon />}
                            {' '}
                            <span>{similarArticlesCounts}</span>
                          </div>
                        ) : null}
                      </div>
                      <div className="iconsWrapper">
                        <div
                          className="favorite-icon-custom"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToFavorite(article.articleId);
                          }}
                          onKeyPress={(e) => {
                            e.stopPropagation();
                            addToFavorite(article.articleId);
                          }}
                          tabIndex={0}
                          role="button"
                          aria-label="Faviourite"
                        >
                          {article?.isFavorite
                            ? (
                              <SingleFavoriteIcon
                                strokeColor={favouriteArticleColor}
                                fillColor={favouriteArticleColor}
                              />
                            )
                            : (
                              <SingleFavoriteIcon
                                strokeColor={primaryHeadingColor}
                              />
                            )}
                        </div>
                        <Popover
                          placement="leftBottom"
                          showArrow={false}
                          content={content}
                          onOpenChange={handleOpenChange}
                          trigger="click"
                          getPopupContainer={(e: HTMLElement) => e}
                          className="popover-sharing-custom popoverSharingCustom popoverSharingGridTile"
                          arrow={false}
                        >
                          <div
                            className={classNames('article-share-icon', { listviewShareIcon: listView })}
                            aria-hidden="true"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <ShareArticleStrokeIcon color={primaryHeadingColor} />
                          </div>
                        </Popover>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <VideoPopup
            videoLink={article.videoUrl}
            visible={videoPopUpVisible}
            setVisible={isVideoVisible}
          />
        </ArticleWrapper>
      )}
      { }
    </>
  );
};

ArticleTileGlobal.defaultProps = defaultProps;
export default ArticleTileGlobal;

import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MarketingPopupResponse } from 'Types/GlobalSearchContentTypes';
import { actions as compaginActions } from 'Slice/MarketingCompaignSlice';
import { midMobile, smMobile } from 'Utils/utilsStyle';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { selectActiveModule } from 'Selector/CertificationSelector';
import { valueToObject } from 'Utils/UtilityFunctions';
import SkeletonCustom from '../skeleton/SkeletonCustom';
import CrossCircle from '../assets/svgIcons/CrossCircle';

type Props = {
  loaderPopup: boolean,
  marketingPopup: MarketingPopupResponse,
} & typeof defaultProps;

const defaultProps = {
  loaderPopup: true,
};

const MarketingModal = styled(Modal)`
  cursor: pointer;
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    position: relative;
    height: 477px;
    padding: 0px;
    @media all and (max-width: ${midMobile}) {
      height: 350px;
    }
     @media all and (max-width: ${smMobile}) {
      height: 250px;
    }
    .img-markt-popup {
      height: 100%;
      width: 100%;
    }
  }
  .anchor-class-marketing {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px 0px 0px 0px;
      padding-top: 0px !important;
      height: 100%;
    }
  }
`;

const MarketingPopup = function MarketingPopup(props: Props) {
  const {
    loaderPopup,
    marketingPopup,
  } = props;
  const dispatch = useDispatch();
  const popupId = marketingPopup?.id;
  const image = marketingPopup?.imageUrl;
  const callUrl = marketingPopup?.callToAction;
  const [showPopup, setShowPopup] = useState(false);
  const flurryObject = useSelector(selectActiveModule);

  useEffect(() => {
    if (marketingPopup?.read === false) {
      setShowPopup(true);
    }
  }, []);

  const onClickCancel = () => {
    dispatch(compaginActions.updatePopup({
      id: popupId,
      callURl: '',
    }));
    setShowPopup(false);
  };

  const callToAction = () => {
    if (!callUrl.includes('certificationStart')) {
      window.open(`${callUrl}`, '_blank');
    } else {
      dispatch(certificationActions.setOpenGilroyDrawer(true));
      dispatch(certificationActions.setOpenDrawer(true));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('Certificate_AI_Gilroy_MarketingPopUp', 0, {
          ...flurryObject,
        }),
      ));
    }
    dispatch(compaginActions.updatePopup({
      id: popupId,
      callURl: callUrl,
    }));
    setShowPopup(false);
  };

  return (
    <MarketingModal
      centered
      width={750}
      closeIcon={<div className="cross-icon-x"><CrossCircle /></div>}
      getContainer=""
      wrapClassName="marketing-popups"
      visible={showPopup}
      onCancel={onClickCancel}
      closable
    >
      {loaderPopup ? (
        <SkeletonCustom
          width="100%"
          height="100%"
          margin="0px 0px 0px 0px"
          marginSm="0px 0px 0px 0px"
          logo
          logoSize="400px"
        />
      ) : (

        <span
          role="button"
          tabIndex={0}
          onKeyPress={() => callToAction()}
          onClick={() => callToAction()}
          className="anchor-class-marketing"
        >
          <img className="img-markt-popup" src={image} alt="img-marketing" />
        </span>
      )}
    </MarketingModal>
  );
};
export default MarketingPopup;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/DocumentHubSlice';

const selectDomain = (state: RootState) => state?.documentHub || initialState;

export const selectDocuments = createSelector(
  [selectDomain],
  (state) => state.documents,
);

export const selectUploadedDocuments = createSelector(
  [selectDomain],
  (state) => state.uploadedDocuments,
);

export const selectDocumentsLoading = createSelector(
  [selectDomain],
  (state) => state.documentsLoading,
);

export const selectDocumentsLoaded = createSelector(
  [selectDomain],
  (state) => state.documentsLoaded,
);

export const selectDocumentsEnded = createSelector(
  [selectDomain],
  (state) => state.documentsEnded,
);

export const selectSort = createSelector(
  [selectDomain],
  (state) => state.sort,
);

export const selectCompanyId = createSelector(
  [selectDomain],
  (state) => state.companyId,
);

export const selectCompanyName = createSelector(
  [selectDomain],
  (state) => state.companyName,
);

export const selectCompanyLogo = createSelector(
  [selectDomain],
  (state) => state.companyLogo,
);

export const selectChatGptAllowed = createSelector(
  [selectDomain],
  (state) => state.chatgptAllowed,
);

export const selectDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.drawerOpen,
);

export const selectUploading = createSelector(
  [selectDomain],
  (state) => state.uploading,
);

export const selectDownloading = createSelector(
  [selectDomain],
  (state) => state.downloading,
);

export const selectDeleting = createSelector(
  [selectDomain],
  (state) => state.deleteing,
);

export const selectDownloadUrl = createSelector(
  [selectDomain],
  (state) => state.downloadUrl,
);

export const selectDrawerState = createSelector(
  [selectDomain],
  (state) => state.drawerState,
);

export const selectSortType = createSelector(
  [selectDomain],
  (state) => state.sortType,
);

export const selectSearchTerm = createSelector(
  [selectDomain],
  (state) => state.searchTerm,
);

export const selectUploadDocuments = createSelector(
  [selectDomain],
  (state) => state.uploadDocuments,
);

export const selectNextUploadDocuments = createSelector(
  [selectDomain],
  (state) => state.nextUploadDocuments,
);

export const selectFailedDocuments = createSelector(
  [selectDomain],
  (state) => state.failedDocuments,
);

export const selectValidateLoading = createSelector(
  [selectDomain],
  (state) => state.validateLoading,
);

export const selectSuccessDocuments = createSelector(
  [selectDomain],
  (state) => state.successDocuments,
);

export const selectUploadDocumentCount = createSelector(
  [selectDomain],
  (state) => state.documentUploadCount,
);

export const selectBatchSize = createSelector(
  [selectDomain],
  (state) => state.batchSize,
);

export const selectBatchUploading = createSelector(
  [selectDomain],
  (state) => state.batchUploading,
);

export const selectUploadLink = createSelector(
  [selectDomain],
  (state) => state.uploadLink,
);

export const selectCancelPopup = createSelector(
  [selectDomain],
  (state) => state.cancelPopup,
);

export const selectUploadDocumentStatus = createSelector(
  [selectDomain],
  (state) => state.uploadDocumentStatus,
);

export const selectuploadingFileError = createSelector(
  [selectDomain],
  (state) => state.uploadingFileError,
);

export const selectUploadCancelled = createSelector(
  [selectDomain],
  (state) => state.uploadCancelled,
);
export const selectDownloadLoadingIds = createSelector(
  [selectDomain],
  (state) => state.downloadLoadingIds,
);
export const selectFileDownloadURL = createSelector(
  [selectDomain],
  (state) => state.fileDownloadURL,
);

export const selectFileViewURL = createSelector(
  [selectDomain],
  (state) => state.fileViewUrl,
);
export const selectDocumentCount = createSelector(
  [selectDomain],
  (state) => state.documentCount,
);

export const selectIsAdmin = createSelector(
  [selectDomain],
  (state) => state.isAdmin,
);

export const selectDeleteloading = createSelector(
  [selectDomain],
  (state) => state.deleteLoading,
);

export const selectSearchCountDelete = createSelector(
  [selectDomain],
  (state) => state.searchCountDelete,
);

export const selectSortFlurry = createSelector(
  [selectDomain],
  (state) => state.sortFlurryEvent,
);

export const selectFileTypeFlurry = createSelector(
  [selectDomain],
  (state) => state.fileTypeFlurryEvent,
);

export const selectFlurryFlag = createSelector(
  [selectDomain],
  (state) => state.flurryFlag,
);

export const selectUserDocumentCount = createSelector(
  [selectDomain],
  (state) => state.userDocumentCount,
);

export const selectPreviewDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.previewDrawerOpen,
);

export const selectIsSelectAll = createSelector(
  [selectDomain],
  (state) => state.isSelectAll,
);

export const selectIsSearchSelectAll = createSelector(
  [selectDomain],
  (state) => state.isSearchSelectAll,
);

export const selectSelectedDocuments = createSelector(
  [selectDomain],
  (state) => state.selectedDocuments,
);

export const selectUnselectedDocuments = createSelector(
  [selectDomain],
  (state) => state.unSelectedDocuments,
);

export const selectSearchList = createSelector(
  [selectDomain],
  (state) => state.searchList,
);

export const selectUploadedByMe = createSelector(
  [selectDomain],
  (state) => state.uploadedByMe,
);

export const selectSelectedDocumentsCount = createSelector(
  [selectDomain],
  (state) => state.selectedDocumentCount,
);

export const selectUnselectedDocumentsCount = createSelector(
  [selectDomain],
  (state) => state.unselectedDocumentCount,
);

export const selectShowSelected = createSelector(
  [selectDomain],
  (state) => state.showSelected,
);

export const selectSapSelectedDocOffset = createSelector(
  [selectDomain],
  (state) => state.sapSelectedDocOffset,
);
